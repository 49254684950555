import { BigNumber } from 'bignumber.js';
import {
  call, put, select, takeLatest,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { contractsConfig } from 'config';
import { isMainnet } from 'config/constants';
import { updateSuccessModal } from 'store/successModal/reducer';
import { poolsApi } from 'services';
import { BackendChains, Pools } from 'types/index';
import { getPoolDataForCreation } from 'pages/LaunchPool/LaunchPool.helper';
import { setNotification } from 'utils';
import { erc20Abi } from 'config/abi';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';
import { createNewPool } from '../actions';
import { approveSaga } from './approveSaga';
import { approveNftsSaga } from './approveNfts';

const POOLS_WITH_REWARD_TOKEN = [Pools.MiningPool, Pools.MainPool, Pools.TokenDrop];

export function* createNewPoolSaga({
  type,
  payload: { poolData, web3Provider, poolType },
}: ReturnType<typeof createNewPool>) {
  yield put(apiActions.request(type));

  const myAddress = yield* select(userSelector.getProp('address'));
  const chain = yield* select(userSelector.getProp('chain'));
  try {
    let rewardTokenDecimals = 18;
    let stakingTokenDecimals = 18;
    let stakingToken2Decimals = 18;
    let rewardTokenAddress = poolData?.rewardTokenAddress;
    let rewardTokenLogo = poolData?.rewardTokenLogo;

    // in this pool reward token is one of the staking tokens
    if (poolType === Pools.MainPool) {
      rewardTokenAddress = [poolData.stakingTokenAddress, poolData.stakingToken2Address][
        poolData.rewardTokenIndex
      ];
      rewardTokenLogo = [poolData.stakingTokenLogo, poolData.stakingToken2Logo][
        poolData.rewardTokenIndex
      ];
    }

    // get reward token decimals for pools with erc20 reward token
    if (POOLS_WITH_REWARD_TOKEN.includes(poolType)) {
      const rewardTokenContract = yield new web3Provider.eth.Contract(
        erc20Abi,
        rewardTokenAddress,
      );
      const decimals = yield call(rewardTokenContract.methods.decimals().call);
      rewardTokenDecimals = decimals;
    }

    // get staking tokens decimals
    if(poolType === Pools.NftPool || poolType === Pools.NftDrop) {
      const stakingTokenContract = yield new web3Provider.eth.Contract(
        erc20Abi,
        poolData.stakingTokenAddress,
      );
      stakingTokenDecimals = yield call(stakingTokenContract.methods.decimals().call);
    }

    if(poolType === Pools.NftDrop) {
      const stakingToken2Contract = yield new web3Provider.eth.Contract(
        erc20Abi,
        poolData.stakingToken2Address,
      );
      stakingToken2Decimals = yield call(stakingToken2Contract.methods.decimals().call);
    }

    const {
      poolDataForCreation, contractName, backendPoolName, createMethodName,
    } = getPoolDataForCreation(
      poolType, poolData, rewardTokenDecimals, stakingTokenDecimals, stakingToken2Decimals,
    );

    const { abi, address: contractAddress } =
      contractsConfig.contracts[contractName][isMainnet ? 'mainnet' : 'testnet'];

    const contract = yield new web3Provider.eth.Contract(abi, contractAddress[chain]);

    // if need to approve reward tokens before pool creation
    if ([Pools.MiningPool, Pools.TokenDrop, Pools.MainPool].includes(poolType)) {
      const startDate = Math.floor(Date.parse(poolData.startDate) / 1000);
      const endDate = Math.floor(Date.parse(poolData.endDate) / 1000);

      const approveAmount = new BigNumber(endDate)
        .minus(startDate)
        .multipliedBy(poolData.rewardsPerSecond)
        .toString();

      yield* call(approveSaga, {
        type: actionTypes.APPROVE,
        payload: {
          spender: contractAddress[chain], // fabric address
          web3Provider,
          amount: approveAmount,
          approveTokenAddress: rewardTokenAddress,
        },
      });
    }

    // if need to approve reward nfts before pool creation
    if ([Pools.NftDrop, Pools.NftPool].includes(poolType)) {
      yield* call(approveNftsSaga, {
        type: actionTypes.APPROVE_NFTS,
        payload: {
          operator: contractAddress[chain], // fabric address
          web3Provider,
          isApprove: true,
          collectionAddress: rewardTokenAddress,
        },
      });
    }

    const args = poolType === Pools.MctPool ? [poolDataForCreation] : poolDataForCreation;
    yield call(contract.methods[createMethodName](...args).estimateGas, {
      from: myAddress,
    });

    const createPoolReceipt = yield call(contract.methods[createMethodName](...args).send, {
      from: myAddress,
    });

    const { transactionHash } = createPoolReceipt;

    console.log(poolData);
    const formData = new FormData();
    formData.append('tx_hash', transactionHash);
    formData.append('network', BackendChains[chain]);
    formData.append('pool_type', backendPoolName);
    formData.append('description', poolData.description);
    formData.append('title', poolData.poolName);
    if (poolData?.stakingTokenLogo) {
      formData.append('token1_preview', poolData.stakingTokenLogo);
    }
    if (poolData?.stakingToken2Logo) {
      formData.append('token2_preview', poolData.stakingToken2Logo);
    }
    if (rewardTokenLogo) {
      formData.append('reward_preview', rewardTokenLogo);
    }
    if (poolData?.stakingTokenLink && !poolData?.stakingToken1Link) {
      formData.append('links', JSON.stringify([poolData.stakingTokenLink]));
    }
    if (poolData?.stakingTokenLink && poolData?.stakingToken1Link) {
      formData.append('links', JSON.stringify([poolData.stakingTokenLink, poolData.stakingToken1Link]));
    }

    yield* call(poolsApi.createPool, formData);

    let newStakingContractAddress = '';
    if (poolType === Pools.MctPool) {
      newStakingContractAddress = createPoolReceipt.events.RoleGranted[0].returnValues.account;
    } else {
      newStakingContractAddress = createPoolReceipt.events.NewContract.returnValues.instance;
    }

    yield put(
      updateSuccessModal({
        newStakingContractAddress,
        isSuccessModalOpen: true,
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    setNotification({ type: 'error', message: 'Something went wrong' });
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CREATE_NEW_POOL, createNewPoolSaga);
}
