/* eslint-disable max-len */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { updatePool } from 'store/stakes/actions';
import { poolsApi } from 'services';
import actionTypes from 'store/stakes/actionTypes';

export function* updatePoolSaga({
  type,
  payload: { id },
}: ReturnType<typeof updatePool>) {
  yield put(apiActions.request(type));
  try {
    yield call(poolsApi.updatePool, { id });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UPDATE_POOl, updatePoolSaga);
}
