import React from 'react';
import { BinanceSmartChainIcon, EthereumIcon } from 'theme/icons';
import { Chains, Pools } from 'types';

export const chains = [
  {
    value: Chains.bsc,
    label: 'BSC',
    icon: <BinanceSmartChainIcon />,
  },
  {
    value: Chains.ethereum,
    label: 'Ethereum',
    icon: <EthereumIcon />,
  },
];

export const pools = [
  {
    value: Pools.MctPool,
    label: 'MCT Pool',
  },
  {
    value: Pools.MiningPool,
    label: 'Mining Pool',
  },
  {
    value: Pools.NftDrop,
    label: 'NFT Drop',
  },
  {
    value: Pools.MainPool,
    label: 'Main Pool',
  },
  {
    value: Pools.TokenDrop,
    label: 'Token Drop',
  },
  {
    value: Pools.NftPool,
    label: 'NFT Pool',
  },
];
