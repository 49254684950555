import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
      ...flexHelper('center', 'center'),
      flexDirection: 'column',
    },

    title: {
      marginBottom: theme.spacing(2),
    },

    connectButton: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1.5),
      },
    },

    connectButtonImg: {
      height: 33,
      maxWidth: 172,
    },
  });
});
