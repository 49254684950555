import React, { VFC } from 'react';
import { ExternalLinkBox, Modal } from 'components';
import { Box } from '@material-ui/core';
import { getLinkToScaner } from 'utils';
import { Chains } from 'types';
import { useStyles } from './NftModal.styles';

export interface INftModalProps {
  nftImg: string;
  chain: Chains;
  hash: string;
  params: string;
  onClose: () => void;
  open: boolean;
}

export const NftModal: VFC<INftModalProps> = ({
  nftImg,
  chain,
  hash,
  params,
  onClose,
  open,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
      paperClassName={classes.nftModalWrapper}
      customTitle={(
        <Box className={classes.nftContent}>
          <Box className={classes.nftImgWrapper}>
            <img src={nftImg} alt="currentNft" className={classes.nftImg} />
          </Box>
          <ExternalLinkBox
            className={classes.nftPreviewLink}
            variant="light"
            link={getLinkToScaner({
              chain,
              hash,
              params: `?a=${params}`,
              type: 'token',
            })}
          />
        </Box>
      )}
      customTitleClassName={classes.contentWrapper}
    />
  );
};
