/* eslint-disable max-len */
import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { contractsConfig, ContractsNames } from 'config';
import { adminRoleBytes, isMainnet } from 'config/constants';
import { updateIsAdmin } from 'store/user/reducer';
import { accountsApi } from 'services';
import userSelector from '../selectors';
import { stake } from '../../stakes/actions';
import actionTypes from '../actionTypes';

export function* checkIsAdminSaga({ type, payload: { web3Provider } }: ReturnType<typeof stake>) {
  yield put(apiActions.request(type));
  const { abi: factoryAbi, address: factroyAddress } =
    contractsConfig.contracts[ContractsNames.factory][isMainnet ? 'mainnet' : 'testnet'];

  const myAddress = yield select(userSelector.getProp('address'));
  const chain = yield select(userSelector.getProp('chain'));
  try {
    const factroyContract = yield new web3Provider.eth.Contract(factoryAbi, factroyAddress[chain]);

    const isAdmin = yield call(factroyContract.methods.hasRole(adminRoleBytes, myAddress).call);

    if (isAdmin && !localStorage.microtuber_token) {
      const metamaskMessage = yield call(accountsApi.getMsg);
      const signedMsg = yield call(
        web3Provider.eth.personal.sign,
        metamaskMessage.data,
        myAddress,
        '',
      );
      const login = yield call(accountsApi.login, {
        address: myAddress,
        msg: metamaskMessage.data,
        signedMsg,
      });
      localStorage.microtuber_token = login.data.key;
    }

    yield put(updateIsAdmin(isAdmin));

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CHECK_IS_ADMIN, checkIsAdminSaga);
}
