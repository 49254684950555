import React, { VFC } from 'react';

import {
  Box, IconButton, Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { outerPages, socialLinks } from 'config/constants';
import { useStyles } from './SocialLinks.styles';

export interface SocialLinksProps {
  className?: string;
}

export const SocialLinks: VFC<SocialLinksProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={clsx(classes.outerPages)}>
        {outerPages.map(({ link, name }, index) => (
          <React.Fragment key={`link_${name}`}>
            <a href={link} target="_blank" rel="noreferrer noopener" className={classes.outerPage}>
              <Typography variant="body1" className="mintLight">{name}</Typography>
            </a>
            <Typography variant="body1" className={`mintLight ${index === outerPages.length - 1 ? classes.outerPagesLast : ''}`}>/</Typography>
          </React.Fragment>
        ))}
      </Box>
      <Box className={clsx(classes.socialLinks)}>
        {socialLinks.map(({ link, name, SocialIcon }) => (
          <React.Fragment key={`link_${name}`}>
            <IconButton
              href={link}
              target="_blank"
              rel="noreferrer noopener"
              aria-label={name}
              className={classes.socialLink}
            >
              <SocialIcon />
            </IconButton>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
