import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  VFC,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { Modal, ModalProps } from 'components/Modal';
import { EditIcon } from 'theme/icons';
import { useShallowSelector } from 'hooks';
import descriptionModalSelector from 'store/descriptionModal/selectors';
import userSelector from 'store/user/selectors';
import { editPoolDescription } from 'store/stakes/actions';
import { useStyles } from './DescriptionModal.styles';

export type DescriptionModalProps = ModalProps;

export const DescriptionModal: VFC<DescriptionModalProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    description,
    poolType,
    txHash,
    network,
    title,
    address,
  } = useShallowSelector(
    descriptionModalSelector.getDescriptionModal,
  );
  const isAdmin = useShallowSelector(userSelector.getProp('isAdmin'));

  const onSave = (newDescription: string) => {
    dispatch(
      editPoolDescription({
        poolType,
        txHash,
        description: newDescription,
        network,
        title,
        address,
      }),
    );
  };

  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(description);

  const handleDescriptionChange = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      setValue(event.target.value ?? event.currentTarget.value ?? '');
    },
    [],
  );

  const handleEditBtnClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveBtnClick = () => {
    onSave(value);
  };

  const handleClose = () => {
    setIsEditing(false);
    onClose();
  };

  useEffect(() => {
    setValue(description);
  }, [description]);

  return (
    <Modal open={open} onClose={handleClose} className={clsx(classes.root)}>
      <Box className={classes.body}>
        <Box className={classes.description}>
          {isEditing ? (
            <>
              <TextField
                type="input"
                variant="standard"
                multiline
                fullWidth
                onChange={handleDescriptionChange}
                value={value}
                className={classes.descriptionTextArea}
              />
              <Box className={classes.descriptionHelpers}>
                <Button
                  variant="text"
                  disabled={value.length > 300}
                  onClick={handleSaveBtnClick}
                  className={classes.saveBtn}
                >
                  <strong>SAVE</strong>
                </Button>
                <Typography variant="body2">
                  {value.length}/{300}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography variant="body2">{description}</Typography>
          )}
        </Box>
        {isAdmin && (
          <IconButton
            color="secondary"
            onClick={handleEditBtnClick}
            className={isEditing && classes.activeEdit}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </Modal>
  );
};
