import { Chains, IConnectWallet, IContracts } from 'types';
import {
  erc20Abi,
  factoryAbi,
  mctPoolFabricAbi,
  stakingAbi,
  stakingErc1155FactoryAbi,
  stakingErc20FactoryAbi,
} from './abi';
import { isMainnet, PROJECT_ID } from './constants';

export const chains = {
  'Binance-Smart-Chain': {
    name: 'Binance-Smart-Chain',
    chainId: isMainnet ? 56 : 97,
    nativeCurrency: {
      name: 'tBNB',
      symbol: 'tBNB',
      decimals: 18,
    },
    blockExplorerUrl: isMainnet ? 'https://bscscan.com/' : 'https://testnet.bscscan.com/',
    rpc: isMainnet
      ? 'https://bsc-dataseed.binance.org/'
      : 'https://data-seed-prebsc-1-s3.binance.org:8545/',
    provider: {
      MetaMask: { name: 'MetaMask' },
      Onto: { name: 'Onto' },
      WalletConnect: {
        name: 'WalletConnect',
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: {
              [isMainnet ? 56 : 97]: isMainnet
                ? 'https://bsc-dataseed.binance.org/'
                : 'https://data-seed-prebsc-1-s3.binance.org:8545/',
            },
            chainId: isMainnet ? 56 : 97,
            chains: [isMainnet ? 56 : 97],
            projectId: PROJECT_ID,
            showQrModal: true,
          },
        },
      },
    },
  },
  Ethereum: {
    name: 'Ethereum',
    chainId: isMainnet ? 1 : 42,
    rpc: isMainnet
      ? 'https://mainnet.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6'
      : 'https://kovan.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6',
    provider: {
      MetaMask: { name: 'MetaMask' },
      Onto: { name: 'Onto' },
      WalletConnect: {
        name: 'WalletConnect',
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: {
              [isMainnet ? 1 : 42]: isMainnet
                ? 'https://mainnet.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6'
                : 'https://kovan.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6',
            },
            chainId: isMainnet ? 1 : 42,
            chains: [isMainnet ? 1 : 42],
            projectId: PROJECT_ID,
            showQrModal: true,
          },
        },
      },
    },
  },
};

export const connectWallet = (newChainName: string): IConnectWallet => {
  const chain = chains[newChainName];
  return {
    network: {
      chainName: chain.name,
      chainID: chain.chainId,
      nativeCurrency: chain.nativeCurrency,
      rpc: chain.rpc,
      blockExplorerUrl: chain.blockExplorerUrl,
    },
    provider: chain.provider,
    settings: { providerType: true },
  };
};

export enum ContractsNames {
  token = 'token',
  factory = 'factory',
  staking = 'staking',
  mctPoolFactory = 'mctPool',
  stakingErc20Factory = 'stakingErc20Factory',
  stakingErc1155Factory = 'stakingErc1155Factory',
}

export type IContractsNames = keyof typeof ContractsNames;

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts: {
    [ContractsNames.token]: {
      mainnet: {
        address: {
          [Chains.bsc]: '0x8038B1F3EB4f70436569618530Ac96b439D67bAe',
          [Chains.ethereum]: '0x6876eba317272fe221c67405c5e8eb3b24535547',
        },
        abi: erc20Abi,
      },
      testnet: {
        address: {
          [Chains.bsc]: '0xa0e3da8f8a8E14FD80cc4086df3ba4917d46Ce97',
          [Chains.ethereum]: '0x71af92d084b0913D8abcf5d08b17C0593Eec7269',
        },
        abi: erc20Abi,
      },
    },
    // single point of failure
    [ContractsNames.factory]: {
      mainnet: {
        address: {
          [Chains.bsc]: '0x39F0a631Ef2966550D7EE93FDEf2F1682e8ada45',
          [Chains.ethereum]: '0x39F0a631Ef2966550D7EE93FDEf2F1682e8ada45',
        },
        abi: factoryAbi,
      },
      testnet: {
        address: {
          [Chains.bsc]: '0x244c340B4bf030272dDaCd1d485355f3Bc7d6AA6',
          [Chains.ethereum]: '0x4c66325a29cc81a0aEbD88102FB0b146a52be052',
        },
        abi: factoryAbi,
      },
    },
    [ContractsNames.mctPoolFactory]: {
      mainnet: {
        address: {
          [Chains.bsc]: '0x39F0a631Ef2966550D7EE93FDEf2F1682e8ada45',
          [Chains.ethereum]: '0x39F0a631Ef2966550D7EE93FDEf2F1682e8ada45',
        },
        abi: mctPoolFabricAbi,
      },
      testnet: {
        address: {
          [Chains.bsc]: '0x244c340B4bf030272dDaCd1d485355f3Bc7d6AA6',
          [Chains.ethereum]: '0x4c66325a29cc81a0aEbD88102FB0b146a52be052',
        },
        abi: mctPoolFabricAbi,
      },
    },
    [ContractsNames.stakingErc20Factory]: {
      mainnet: {
        address: {
          [Chains.bsc]: '0x10F2685227788dEFab8B8Ea53fd8e74e25287118',
          [Chains.ethereum]: '0xdE464584e3Fa5f930232f1e8561452e59580b2c1',
        },
        abi: stakingErc20FactoryAbi,
      },
      testnet: {
        address: {
          [Chains.bsc]: '0x9c4676b6580218Bc780851CB7eB42F3d4287ba42',
          [Chains.ethereum]: '0x453F99A32BF4dC333E11CD89f3388b7B53A4FdC5',
        },
        abi: stakingErc20FactoryAbi,
      },
    },
    [ContractsNames.stakingErc1155Factory]: {
      mainnet: {
        address: {
          [Chains.bsc]: '0x66ee20C1b0456a06425Ec012EFB5aA35d5e36a14',
          [Chains.ethereum]: '0xBb8a30f6ae5063610e4d44788c2d96bd59C07145',
        },
        abi: stakingErc1155FactoryAbi,
      },
      testnet: {
        address: {
          [Chains.bsc]: '0x9241768c1c6a39dAF3a1583deC8A06BcaF658ca6',
          [Chains.ethereum]: '0xAD1d6cb9D58F0373e514E81B1eC124dfF8C0E560',
        },
        abi: stakingErc1155FactoryAbi,
      },
    },
    [ContractsNames.staking]: {
      mainnet: {
        abi: stakingAbi,
      },
      testnet: {
        abi: stakingAbi,
      },
    },
  },
};
