import React, { VFC, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  Box,
  Typography,
  IconButton,
  Button,
  Divider,
} from '@material-ui/core';
import clsx from 'clsx';
import { ArrowLeftIcon } from 'theme/icons';
import BigNumber from 'bignumber.js';

import { DescriptionHelper, Copyable } from 'components';
import { SECONDS_IN_DAY } from 'appConstants';
import { getStatusIcon } from 'utils/getStatusIcon';
import { IStakes } from 'types';
import { formattedDate } from 'utils';
import { useStyles } from './TokenDropCardMobile.styles';

export interface TokenDropCardMobileProps {
  poolData?: IStakes;
  className?: string;
  onStakeClick?: (stakeId: number) => void;
}

export const TokenDropCardMobile: VFC<TokenDropCardMobileProps> = ({
  className,
  poolData: {
    name,
    poolAddress,
    startDate,
    endDate,
    status,
    apr,
    stakingToken0Staked,
    stakingToken0Symbol,
    stakingToken0Logo,
    stakingToken1Staked,
    stakingToken1Symbol,
    stakingToken1Logo,
    rewardsInThePool,
    rewardTokenSymbol,
    rewardTokenLogo,
    earlyUnstakePenalty,
    minimumStakingTime,
    description,
    txHash,
    poolType,
    network,
    id,
    stakingToken0Link,
    stakingToken1Link,
  },
  onStakeClick,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const toogleIsDetails = () => setIsDetailsOpen(!isDetailsOpen);

  const classes = useStyles({ isDetailsOpen });
  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.padding}>
        <Box className={classes.marginBottom}>
          <Typography variant="body1" className={clsx('mint', classes.label)}>
            Pool name
          </Typography>
          <Box className={clsx(classes.flex)}>
            <Typography variant="body1" className={classes.marginRight}>
              {name}
            </Typography>
            <DescriptionHelper
              description={description}
              address={poolAddress}
              txHash={txHash}
              poolType={poolType}
              network={network}
            />
          </Box>
        </Box>
        <Box className={classes.row}>
          <Box>
            <Typography variant="body1" className={clsx('mint', classes.label)}>
              APR
            </Typography>
            <Typography variant="body1">{apr}%</Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              className={clsx('x', 'mint', classes.label)}
            >
              Status
            </Typography>
            <Box className={clsx(classes.flex)}>
              {getStatusIcon(status.label)}
              <Typography className={clsx('capitalize', classes.marginLeft)}>
                {status.label}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.row}>
          <Box>
            <Typography
              variant="body1"
              className={clsx('x', 'mint', classes.label)}
            >
              Total staked
            </Typography>
            <Box className={clsx(classes.flex)}>
              <img
                src={stakingToken0Logo}
                alt="token"
                className={clsx(classes.marginRight, classes.tokenLogo)}
              />
              <Typography className={clsx('capitalize')}>
                {new BigNumber(stakingToken0Staked).toFixed(2)}{' '}
                {stakingToken0Symbol}
              </Typography>
            </Box>
            {isDetailsOpen && stakingToken0Link && (
              <Button variant="contained" href={stakingToken0Link} target="_blank" className={classes.buyStakingToken}>Buy now</Button>
            )}
          </Box>
          <Box>
            <Typography
              variant="body1"
              className={clsx('x', 'mint', classes.label)}
            >
              Total staked
            </Typography>
            <Box className={clsx(classes.flex)}>
              <img
                src={stakingToken1Logo}
                alt="token"
                className={clsx(classes.marginRight, classes.tokenLogo)}
              />
              <Typography className={clsx('capitalize')}>
                {new BigNumber(stakingToken1Staked).toFixed(2)}{' '}
                {stakingToken1Symbol}
              </Typography>
            </Box>
            {isDetailsOpen && stakingToken1Link && (
              <Button variant="contained" href={stakingToken1Link} target="_blank" className={classes.buyStakingToken}>Buy now</Button>
            )}
          </Box>
        </Box>
      </Box>
      <Accordion className={classes.detailsContainer} expanded={isDetailsOpen}>
        <AccordionDetails>
          <Box className={classes.detailsInner}>
            <Box className={classes.padding}>
              <Box className={classes.marginBottom}>
                <Typography
                  variant="body1"
                  className={clsx('mint', classes.label)}
                >
                  Rewards in the pool
                </Typography>
                <Box className={clsx(classes.flex)}>
                  <img
                    src={rewardTokenLogo}
                    alt="token"
                    className={clsx(classes.marginRight, classes.tokenLogo)}
                  />
                  <Typography className={clsx('capitalize')}>
                    {new BigNumber(rewardsInThePool).toFixed(2)}{' '}
                    {rewardTokenSymbol}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.row}>
                <Box>
                  <Typography
                    variant="body1"
                    className={clsx('mint', classes.label)}
                  >
                    Start date
                  </Typography>
                  <Typography variant="body1">
                    {formattedDate(new Date(+startDate * 1000))}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    className={clsx('mint', classes.label)}
                  >
                    End date
                  </Typography>
                  <Typography variant="body1">
                    {formattedDate(new Date(+endDate * 1000))}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.row}>
                <Box>
                  <Typography
                    variant="body1"
                    className={clsx('mint', classes.label)}
                  >
                    Minimum staking time
                  </Typography>
                  <Typography variant="body1">
                    {+minimumStakingTime / SECONDS_IN_DAY} days
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    className={clsx('x', 'mint', classes.label)}
                  >
                    Early unstake penalty
                  </Typography>
                  <Typography variant="body1">
                    {earlyUnstakePenalty}%
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Divider className={classes.marginBottom} />
            <Box className={clsx(classes.padding, classes.marginBottom)}>
              <Typography
                variant="body1"
                className={clsx(classes.label, 'x', 'mint')}
              >
                Pool address
              </Typography>
              <Copyable
                onlyIconActive
                transparent
                valueToCopy={poolAddress}
                zeroPadding
              >
                <Typography noWrap>{poolAddress}</Typography>
              </Copyable>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box className={clsx(classes.flex, classes.padding)}>
        <IconButton color="primary" onClick={toogleIsDetails}>
          <ArrowLeftIcon
            className={clsx(classes.buttonIcon, {
              [classes.buttonIconActive]: isDetailsOpen,
            })}
          />
        </IconButton>
        {isDetailsOpen && (
          <Button
            onClick={() => onStakeClick(id)}
            size="small"
            className={classes.stakeButton}
            fullWidth
          >
            Stake
          </Button>
        )}
      </Box>
    </Box>
  );
};
