import React, { VFC, useState, useMemo } from 'react';

import {
  Accordion,
  AccordionDetails,
  Box,
  Typography,
  IconButton,
  Button,
  Divider,
} from '@material-ui/core';
import clsx from 'clsx';
import { ArrowLeftIcon } from 'theme/icons';
import BigNumber from 'bignumber.js';

import { DescriptionHelper, Copyable, ExternalLinkBox } from 'components';
import { getStatusIcon } from 'utils/getStatusIcon';
import { Chains, IStakes, Status } from 'types';
import { formattedDate, getLinkToScaner } from 'utils';
import { SECONDS_IN_DAY } from 'appConstants';
import { useStyles } from './NftDropCardMobile.styles';
import { NftModal } from '../../../NftModal/NftModal';

export interface NftDropCardMobileProps {
  poolData?: IStakes;
  className?: string;
  chain?: Chains;
  onStakeClick?: (stakeId: number) => void;
}

export const NftDropCardMobile: VFC<NftDropCardMobileProps> = ({
  className,
  chain,
  onStakeClick,
  poolData: {
    name,
    poolAddress,
    startDate,
    endDate,
    status,
    stakingToken0Staked,
    stakingToken0Symbol,
    stakingToken0Logo,
    stakingToken0AmountToStake,
    stakingToken1Staked,
    stakingToken1Symbol,
    stakingToken1Logo,
    stakingToken1AmountToStake,
    rewardTokenLogo,
    rewardTokenAddress,
    rewardTokenId,
    stakingTime,
    description,
    poolType,
    txHash,
    id,
    network,
    stakingToken0Link,
    stakingToken1Link,
  },
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const toogleIsDetails = () => setIsDetailsOpen(!isDetailsOpen);

  const [currentNftData, setCurrentNftData] = useState({ img: '', hash: '', params: '' });
  const [isNftModalOpen, setIsNftModalOpen] = useState(false);

  const isStakeButtonDisabled = useMemo(
    () => (status.label === Status.completed &&
        stakingToken0Staked === '0') ||
      status.label === Status.upcoming,
    [status.label, stakingToken0Staked],
  );

  const handleOpenNftModal = (img: string, hash: string, params: string) => {
    setCurrentNftData({
      img, hash, params,
    });
    setIsNftModalOpen(true);
  };

  const classes = useStyles({ isDetailsOpen });
  return (
    <>
      {
        isNftModalOpen && (
          <NftModal
            nftImg={currentNftData.img}
            hash={currentNftData.hash}
            chain={chain}
            params={currentNftData.params}
            onClose={() => setIsNftModalOpen(false)}
            open={isNftModalOpen}
          />
        )
      }
      <Box className={clsx(classes.root, className)}>
        <Box className={classes.padding}>
          <Box className={classes.row}>
            <Box>
              <Typography variant="body1" className={clsx('mint', classes.label)}>
                Pool name
              </Typography>
              <Box className={clsx(classes.flex)}>
                <Typography variant="body1" className={clsx(classes.marginRight, classes.poolName)}>
                  {name}
                </Typography>
                <DescriptionHelper
                  description={description}
                  address={poolAddress}
                  txHash={txHash}
                  poolType={poolType}
                  network={network}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                variant="body1"
                className={clsx('x', 'mint', classes.label)}
              >
                Status
              </Typography>
              <Box className={clsx(classes.flex)}>
                {getStatusIcon(status.label)}
                <Typography className={clsx('capitalize', classes.marginLeft)}>
                  {status.label}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box>
              <Typography
                variant="body1"
                className={clsx('x', 'mint', classes.label)}
              >
                Total staked
              </Typography>
              <Box className={clsx(classes.flex)}>
                <img
                  src={stakingToken0Logo}
                  alt="token"
                  className={clsx(classes.marginRight, classes.tokenLogo)}
                />
                <Typography className={clsx('capitalize')}>
                  {new BigNumber(stakingToken0Staked).toFixed(2)}{' '}
                  {stakingToken0Symbol}
                </Typography>
              </Box>
              {isDetailsOpen && stakingToken0Link && (
                <Button variant="contained" href={stakingToken0Link} target="_blank" className={classes.buyStakingToken}>Buy now</Button>
              )}
            </Box>
            <Box>
              <Typography
                variant="body1"
                className={clsx('x', 'mint', classes.label)}
              >
                Total staked
              </Typography>
              <Box className={clsx(classes.flex)}>
                <img
                  src={stakingToken1Logo}
                  alt="token"
                  className={clsx(classes.marginRight, classes.tokenLogo)}
                />
                <Typography className={clsx('capitalize')}>
                  {new BigNumber(stakingToken1Staked).toFixed(2)}{' '}
                  {stakingToken1Symbol}
                </Typography>
              </Box>
              {isDetailsOpen && stakingToken1Link && (
                <Button variant="contained" href={stakingToken1Link} target="_blank" className={classes.buyStakingToken}>Buy now</Button>
              )}
            </Box>
          </Box>
        </Box>
        <Accordion className={classes.detailsContainer} expanded={isDetailsOpen}>
          <AccordionDetails>
            <Box className={classes.detailsInner}>
              <Box className={classes.padding}>
                <Box className={classes.marginBottom}>
                  <Typography
                    variant="body1"
                    className={clsx('mint', classes.label)}
                  >
                    Reward NFT
                  </Typography>
                  <Box>
                    <Box
                      className={classes.nftPreviewContainer}
                      onClick={() => handleOpenNftModal(
                        rewardTokenLogo, rewardTokenAddress, rewardTokenId,
                      )}
                    >
                      <img
                        src={rewardTokenLogo}
                        alt="token"
                        className={clsx(classes.marginRight, classes.nftPreview)}
                      />
                    </Box>
                    <ExternalLinkBox
                      className={classes.nftPreviewLink}
                      variant="dark"
                      link={getLinkToScaner({
                        chain,
                        hash: rewardTokenAddress,
                        params: `?a=${rewardTokenId}`,
                        type: 'token',
                      })}
                    />
                  </Box>
                </Box>
                <Box className={classes.row}>
                  <Box>
                    <Typography
                      variant="body1"
                      className={clsx('mint', classes.label)}
                    >
                      Start date
                    </Typography>
                    <Typography variant="body1">
                      {formattedDate(new Date(+startDate * 1000))}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      className={clsx('mint', classes.label)}
                    >
                      End date
                    </Typography>
                    <Typography variant="body1">
                      {formattedDate(new Date(+endDate * 1000))}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.row}>
                  <Box>
                    <Typography
                      variant="body1"
                      className={clsx('mint', classes.label)}
                    >
                      Staking time
                    </Typography>
                    <Typography variant="body1">{new BigNumber(stakingTime).dividedBy(SECONDS_IN_DAY).toFixed()} days</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      className={clsx('x', 'mint', classes.label)}
                    >
                      Amount to stake
                    </Typography>
                    <Typography variant="body1">
                      {`
                    ${stakingToken0AmountToStake}
                    ${stakingToken0Symbol}
                    +
                    ${stakingToken1AmountToStake}
                    ${stakingToken1Symbol}
                  `}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Divider className={classes.marginBottom} />
              <Box className={clsx(classes.padding, classes.marginBottom)}>
                <Typography
                  variant="body1"
                  className={clsx(classes.label, 'x', 'mint')}
                >
                  Pool address
                </Typography>
                <Copyable
                  onlyIconActive
                  transparent
                  valueToCopy={poolAddress}
                  zeroPadding
                >
                  <Typography noWrap>{poolAddress}</Typography>
                </Copyable>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box className={clsx(classes.flex, classes.padding)}>
          <IconButton color="primary" onClick={toogleIsDetails}>
            <ArrowLeftIcon
              className={clsx(classes.buttonIcon, {
                [classes.buttonIconActive]: isDetailsOpen,
              })}
            />
          </IconButton>
          {isDetailsOpen && (
            <Button onClick={() => onStakeClick(id)} size="small" className={classes.stakeButton} fullWidth disabled={isStakeButtonDisabled}>
              Stake
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};
