import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  Hidden,
  Button,
  Box,
  TextField,
  Typography,
  MenuItem,
  Switch,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { getNftPoolsData } from 'store/stakes/actions';
import { useWalletConnectorContext } from 'services';
import { usePoolsSorting, useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import stakesSelector from 'store/stakes/selectors';
import { Pools } from 'types';
import { useLocation } from 'react-router-dom';
import { getTokensBalance } from 'store/user/actions';
import { useStyles } from '../Pools.styles';
import { CustomTable } from '../CustomTable';
import { ROWS_PER_PAGE } from '../Pools.helper';
import { NftPoolCardMobile } from './components/NftPoolCardMobile';
import { NftPoolStakeModal } from '../StakeModals';
import { mobileSortingMenu } from './NftPool.helper';
import { MobileInfo } from '../MobileInfo';

export const NftPool = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { web3WithoutMetamask, walletService } = useWalletConnectorContext();
  const { chain, address } = useShallowSelector(userSelector.getUser);
  const nftPoolStakes = useShallowSelector(
    stakesSelector.getProp('nftPoolStakes'),
  );

  const { pathname } = useLocation();

  const {
    handleSort,
    handleMobileSort,
    handleMyPoolsFilter,
    handleChangePage,
    tableData,
    selectedMobileSorting,
    orderBy,
    order,
    isOnlyMyPools,
    page,
  } = usePoolsSorting(nftPoolStakes, mobileSortingMenu[0].value);

  const [activeStakeId, setActiveStakeId] = useState(null);
  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false);

  const isMoreStakesToShow = (page + 1) * ROWS_PER_PAGE < tableData.length;

  const handleStakeClick = (stakeId: number) => {
    setActiveStakeId(stakeId);
    const stakeData = nftPoolStakes.find((stake) => stake.id === stakeId);
    dispatch(
      getTokensBalance({
        web3Provider: walletService.Web3(),
        tokensAddresses: [stakeData.stakingToken0Address],
      }),
    );
    setIsStakeModalOpen(true);
  };

  const activeStakeData = useMemo(
    () => nftPoolStakes.find((stakeData) => stakeData.id === activeStakeId),
    [activeStakeId, nftPoolStakes],
  );

  useEffect(() => {
    dispatch(getNftPoolsData({ web3Provider: web3WithoutMetamask(chain) }));
  }, [dispatch, web3WithoutMetamask, chain, address]);

  return (
    <>
      {isStakeModalOpen && (
        <NftPoolStakeModal
          selectedStakeData={activeStakeData}
          open={isStakeModalOpen}
          onClose={() => setIsStakeModalOpen(false)}
          chain={chain}
        />
      )}
      <Hidden smDown implementation="css">
        <CustomTable
          className={classes.stakesTable}
          poolType={pathname.replace('/', '') as Pools}
          orderBy={orderBy}
          order={order}
          handleSort={handleSort}
          tableData={tableData}
          page={page}
          handleChangePage={handleChangePage}
          onStakeClick={handleStakeClick}
        />
      </Hidden>
      <Hidden mdUp implementation="css">
        <Box className={classes.stakesMobileContainer}>
          <Box className={classes.mobileSorting}>
            <TextField
              value={selectedMobileSorting}
              onChange={handleMobileSort}
              select
              className={clsx(classes.mobileSortingSelect, 'noLabel')}
              variant="filled"
            >
              {mobileSortingMenu.map(({ value, label }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <Box className={classes.myPoolsSwitch}>
              <Typography>My pools</Typography>
              <Box>
                <Switch checked={isOnlyMyPools} onChange={handleMyPoolsFilter} />
              </Box>
            </Box>
          </Box>
          <MobileInfo poolType={'NFTPool' as Pools} />
          {tableData
            .slice(0, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
            .map((poolData) => (
              <NftPoolCardMobile
                key={poolData.poolAddress}
                onStakeClick={handleStakeClick}
                chain={chain}
                poolData={poolData}
              />
            ))}
        </Box>
        {isMoreStakesToShow && (
          <Box className={classes.center}>
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              onClick={(e) => handleChangePage(e, page + 1)}
            >
              Load more
            </Button>
          </Box>
        )}
      </Hidden>
    </>
  );
};
