import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { COLOR_MINT_800 } from 'theme/colors';

export const getMuiDialogProps = (theme: Theme): Overrides['MuiDialog'] => ({
  root: {
  },
  paper: {
    backgroundColor: COLOR_MINT_800,
    padding: theme.spacing(4, 4, 5, 4),
    borderRadius: '20px',
  },
});

export const getMuiDialogDefaultProps = (): ComponentsProps['MuiDialog'] => ({});
