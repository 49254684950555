import {
  useState, ChangeEvent, useEffect, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { IStakes, TNullable } from 'types';
import { sortPools } from 'utils/sortPools';
import stakesSelector from 'store/stakes/selectors';
import { useShallowSelector } from 'hooks';
import { updateIsOnlyMyPools } from 'store/stakes/reducer';
import {
  MOBILE_SORTING_DIVIDER,
  Order,
} from '../containers/Pools/Pools.helper';

export const usePoolsSorting = (
  poolsData: IStakes[],
  mobileSortingDefault: string,
) => {
  const dispatch = useDispatch();
  const isOnlyMyPools = useShallowSelector(
    stakesSelector.getProp('isOnlyMyPools'),
  );

  const [tableData, setTableData] = useState(poolsData);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<TNullable<Order>>(null);
  const [orderBy, setOrderBy] = useState<TNullable<string>>(null);
  const [selectedMobileSorting, setSelectedMobileSorting] =
    useState(mobileSortingDefault);

  const handleSort = useCallback(
    (property) => {
      const isAsc = orderBy === property && order === 'asc';
      const newOrder = isAsc ? 'desc' : 'asc';
      setOrder(newOrder);
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleMobileSort = (event: ChangeEvent<HTMLInputElement>) => {
    const [newOrderBy, newOrder] = event.target.value.split(
      MOBILE_SORTING_DIVIDER,
    );
    setOrder(newOrder as Order);
    setOrderBy(newOrderBy);
    setSelectedMobileSorting(event.target.value);
  };

  const handleMyPoolsFilter = useCallback(() => {
    dispatch(updateIsOnlyMyPools(!isOnlyMyPools));
  }, [dispatch, isOnlyMyPools]);

  const handleChangePage = useCallback((_, newPage: number) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    setPage(0);
    let sortedPools = sortPools(orderBy, order, poolsData);
    if (isOnlyMyPools) {
      sortedPools = sortedPools.filter(
        (
          { userStakedToken0Amount, isUserDepositedToPool },
        ) => isUserDepositedToPool || +userStakedToken0Amount > 0,
      );
    }
    setTableData(sortedPools);
  }, [isOnlyMyPools, poolsData, order, orderBy]);

  return {
    handleSort,
    handleMobileSort,
    handleMyPoolsFilter,
    handleChangePage,
    tableData,
    selectedMobileSorting,
    orderBy,
    order,
    isOnlyMyPools,
    page,
  };
};
