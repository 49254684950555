import React, { VFC } from 'react';

import clsx from 'clsx';
import { Box, Button, Typography } from '@material-ui/core';
import { CompleteIconBig } from 'theme/icons';
import { Copyable } from 'components/Copyable';
import { Modal, ModalProps } from 'components/Modal';
import { useStyles } from './SuccessModal.styles';

interface SuccessModalProps extends ModalProps {
  onClose: () => void;
  address?: string;
}

export const SuccessModal: VFC<SuccessModalProps> = ({
  open, address, title, onClose,
}) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose} className={clsx(classes.root)}>
      <Box className={classes.icon}>
        <Box className={classes.bigIcon}>
          <CompleteIconBig />
        </Box>
        <Typography className={classes.desc} variant="body1">{title}</Typography>
        {!!address && (
        <Copyable className={classes.copyable} onlyIconActive withBorder valueToCopy={address}>
          <Typography noWrap className={classes.address}>{address}</Typography>
        </Copyable>
        )}
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          OK
        </Button>
      </Box>
    </Modal>
  );
};
