import React, { ReactElement, VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ExternalLinkIcon } from 'theme/icons';
import { useStyles } from './ExternalLinkBox.styles';

export type ExternalLinkBoxVariants = 'dark' | 'light';

export interface ExternalLinkBoxProps {
  className?: string;
  variant: ExternalLinkBoxVariants;
  link: string;
  icon?: ReactElement;
}

export const ExternalLinkBox: VFC<ExternalLinkBoxProps> = ({
  className, variant, link, icon,
}) => {
  const classes = useStyles({ variant });
  return (
    <Box className={clsx(classes.root, className)}>
      <a href={link} target="_blank" rel="noreferrer noopener" className={classes.linkContainer}>
        <Typography noWrap variant="body1" className={classes.link}>{link}</Typography>
        <Box className={classes.icon}>
          {icon || <ExternalLinkIcon />}
        </Box>
      </a>

    </Box>
  );
};
