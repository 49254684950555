import React from 'react';
import {
  CompleteIconFilled,
// ErrorIcon, InfoIcon, ActiveIcon, WarningIcon,
} from 'theme/icons';

export const toastifyHelper = {
  success: <CompleteIconFilled />,
  // warning: <WarningIcon />,
  // info: <InfoIcon />,
  // error: <ErrorIcon />,
};
