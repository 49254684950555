import { fork } from 'redux-saga/effects';

import getTokenBalance from './getTokenBalance';
import checkIsAdmin from './checkIsAdmin';
import getTokensBalance from './getTokensBalance';

export default function* userSagas() {
  yield fork(getTokenBalance);
  yield fork(checkIsAdmin);
  yield fork(getTokensBalance);
}
