export const COLOR_ERROR = '#FF511B';

export const COLOR_BG_DARK_7 = 'rgba(27, 33, 33, 0.7)';
export const COLOR_BG_DARK_5 = 'rgba(27, 33, 33, 0.5)';
export const COLOR_BG_DARK_8 = 'rgba(27, 33, 33, 0.8)';
export const COLOR_BG_DARK_9 = '#1E2825';

export const COLOR_MINT_100 = '#DDF3EC';
export const COLOR_MINT_200 = '#CFFFF1';
export const COLOR_MINT_300 = '#A4F8DE';
export const COLOR_MINT_400 = '#84D0B9';
export const COLOR_MINT_500 = '#446B5F';
export const COLOR_MINT_600 = '#324F46';
export const COLOR_MINT_700 = '#283732';
export const COLOR_MINT_800 = '#1B2121';
export const COLOR_MINT_900 = '#283732';

export const COLOR_DARK = '#081717';

export const COLOR_MINT_400_BOX_SHADOW = '0px 6px 12px rgba(139, 219, 195, 0.14)';
