import React, { VFC } from 'react';

import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

import { useDispatch } from 'react-redux';
import { HelpIcon } from 'theme/icons';
import { updateDescriptionModal } from 'store/descriptionModal/reducer';
import { BackendChains, BackendPools } from 'types';
import { useStyles } from './DescriptionHelper.styles';

export interface DescriptionHelperProps {
  className?: string;
  description: string;
  address: string;
  txHash: string;
  poolType: BackendPools;
  network: BackendChains,
}

export const DescriptionHelper: VFC<DescriptionHelperProps> = ({
  className,
  description,
  address,
  txHash,
  poolType,
  network,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(updateDescriptionModal({
      description,
      address,
      txHash,
      poolType,
      network,
      isDescriptionModalOpen: true,
    }));
  };

  const handleClick = () => {
    handleOpenModal();
  };

  return (
    <IconButton className={clsx(classes.root, className)} onClick={handleClick}>
      <HelpIcon />
    </IconButton>
  );
};
