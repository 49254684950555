import { ReactElement } from 'react';

import { DialogProps } from '@material-ui/core';

export * from './base';
export * from './walletConnect';
export * from './backend';

export enum BackendChains {
  'Ethereum' = 'ethereum',
  'Binance-Smart-Chain' = 'binance',
}

// eslint-disable-next-line no-shadow
export enum BackendPools { /* BACKEND TYPES FOR POOLS */
  'Staking' = 'Staking',
  'Staking20Into20' = 'Staking20Into20',
  'Staking20Plus20Into20' = 'Staking20Plus20Into20',
  'Staking20Plus20Into1155' = 'Staking20Plus20Into1155',
  'Staking20Plus1155Into1155' = 'Staking20Plus1155Into1155',
  'StakingMain' = 'staking_main',
}

// eslint-disable-next-line no-shadow
export enum Pools {
  'MctPool' = 'MCTPool',
  'MiningPool' = 'MiningPool',
  'NftDrop' = 'NFTDrop',
  'MainPool' = 'MainPool',
  'TokenDrop' = 'TokenDrop',
  'NftPool' = 'NFTPool',
}

export * from './store';

export type NativeModalProps = DialogProps;

export enum Status {
  active = 'active',
  upcoming = 'upcoming',
  completed = 'completed',
}

export interface PoolStatus {
  label: Status,
  value: number,
}

export enum RoundingModes {
  up,
  down,
}

export type Token = {
  address: string;
  symbol: string;
  decimals: number;
  image?: string | ReactElement;
};

export * from './pools';
