import { formattedDate } from 'utils';
import * as Yup from 'yup';
import web3 from 'web3';
import { startDateValidation, TOMORROW } from './helpers';
import { IConfigArray } from '../LaunchPool.types';

export const nftDropFormInitValues = {
  poolName: '',
  stakingTokenAddress: '',
  stakingTokenLogo: '',
  stakingTokenAmount: '',
  stakingToken2Address: '',
  stakingToken2Logo: '',
  stakingToken2Amount: '',
  rewardTokenAddress: '', // 1155 collection address
  rewardTokenId: '',
  rewardTokenLogo: '',
  rewardTokenAmount: '',
  stakingPeriod: '',
  entranceWindow: '',
  startDate: formattedDate(TOMORROW),
  description: '',
  stakingTokenLink: '',
  stakingToken1Link: '',
};

export const validationSchema = Yup.object().shape({
  poolName: Yup.string().min(0).max(20).required(),
  stakingTokenAddress: Yup.string().test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  stakingToken2Address: Yup.string()
    .notOneOf([Yup.ref('stakingTokenAddress')])
    .test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  rewardTokenAddress: Yup.string().test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  stakingTokenLogo: Yup.string().required(),
  stakingToken2Logo: Yup.string().required(),
  rewardTokenLogo: Yup.string().required(),
  stakingTokenAmount: Yup.number().positive().integer().required(),
  stakingToken2Amount: Yup.number().positive().integer().required(),
  rewardTokenId: Yup.number().integer().required(),
  rewardTokenAmount: Yup.number().positive().integer().required(),
  stakingPeriod: Yup.number().positive().integer().required(),
  entranceWindow: Yup.number().integer().min(1).lessThan(Yup.ref('stakingPeriod'))
    .required(),
  startDate: startDateValidation,
  description: Yup.string().min(0).max(300).required(),
  stakingTokenLink: Yup.string().url(),
  stakingToken1Link: Yup.string().url(),
});

export const nftDropConfig: IConfigArray = [
  {
    fieldType: 'field',
    className: 'poolName',
    id: 'poolName',
    elementType: 'text',
    label: 'Pool name',
  },
  {
    fieldType: 'container',
    className: 'tokens',
    containerClassName: 'tokensContainer',
    content: [
      {
        fieldType: 'container',
        className: 'tokensFirst',
        content: [
          {
            fieldType: 'field',
            title: 'Staking token1 address',
            className: 'stakingTokenAddress',
            id: 'stakingTokenAddress',
            elementType: 'text',
            label: 'Address',
          },
          {
            fieldType: 'field',
            className: 'stakingTokenLogo',
            id: 'stakingTokenLogo',
            elementType: 'uploader',
            label: 'Upload token logo',
            customProps: {
              size: 'small',
            },
          },
          {
            fieldType: 'field',
            className: 'stakingTokenAmount',
            id: 'stakingTokenAmount',
            elementType: 'text',
            label: 'Amount',
          },
        ],
      },
      {
        fieldType: 'container',
        className: 'tokensSecond',
        content: [
          {
            title: 'Staking token2 address',
            fieldType: 'field',
            className: 'stakingToken2Address',
            id: 'stakingToken2Address',
            elementType: 'text',
            label: 'Address',
          },
          {
            fieldType: 'field',
            className: 'stakingToken2Logo',
            id: 'stakingToken2Logo',
            elementType: 'uploader',
            label: 'Upload token logo',
            customProps: {
              size: 'small',
            },
          },
          {
            fieldType: 'field',
            className: 'stakingToken2Amount',
            id: 'stakingToken2Amount',
            elementType: 'text',
            label: 'Amount',
          },
        ],
      },
    ],
  },
  {
    fieldType: 'container',
    className: 'nftInfo',
    containerClassName: 'nftInfoContainer',
    content: [
      {
        fieldType: 'field',
        title: 'NFT Address',
        className: 'nftAddress',
        id: 'rewardTokenAddress',
        elementType: 'text',
        label: 'Address',
      },
      {
        fieldType: 'field',
        title: 'NFT Id',
        className: 'rewardTokenId',
        id: 'rewardTokenId',
        elementType: 'text',
        label: 'ID',
      },
      {
        fieldType: 'field',
        className: 'nftPreview',
        id: 'rewardTokenLogo',
        elementType: 'uploader',
        label: 'Upload NFT preview',
        customProps: {
          size: 'large',
        },
      },
      {
        fieldType: 'field',
        title: 'NFTs to deposit as rewards to the pool',
        className: 'nftDropRewardTokenAmount',
        id: 'rewardTokenAmount',
        elementType: 'text',
        label: 'Amount',
      },
    ],
  },
  {
    fieldType: 'container',
    className: 'stakingTime',
    containerClassName: 'stakingTimeContainer',
    content: [
      {
        fieldType: 'field',
        title: 'Staking period',
        className: 'stakingPeriod',
        id: 'stakingPeriod',
        elementType: 'number',
        label: 'Days',
      },
      {
        fieldType: 'field',
        title: 'Entrance window',
        className: 'entranceWindow',
        id: 'entranceWindow',
        elementType: 'text',
        label: 'Days',
      },
    ],
  },
  {
    fieldType: 'field',
    className: 'nftDropStartDate',
    id: 'startDate',
    elementType: 'date',
    label: 'Start date',
  },
  {
    fieldType: 'field',
    className: 'description',
    id: 'description',
    elementType: 'text',
    label: 'Description',
    customProps: {
      multiline: true,
      placeholder: 'Please add the description up to 300 characters.',
    },
  },
  {
    fieldType: 'field',
    className: 'stakingTokenLink',
    id: 'stakingTokenLink',
    elementType: 'text',
    label: 'Link to buy staking token 1',
  },
  {
    fieldType: 'field',
    className: 'stakingToken1Link',
    id: 'stakingToken1Link',
    elementType: 'text',
    label: 'Link to buy staking token 2',
  },
];

export const nftDropFormConfig = {
  initialValues: nftDropFormInitValues,
  validationSchema,
  fields: nftDropConfig,
  formClassName: 'nftDropForm',
};
