import { formattedDate } from 'utils';
import * as Yup from 'yup';
import web3 from 'web3';
import { IConfigArray } from '../LaunchPool.types';
import {
  DAY_AFTER_TOMORROW,
  earlyUnstakePenaltyValidation,
  endDateValidation,
  minimumStakingPeriodValidation,
  startDateValidation,
  TOMORROW,
} from './helpers';

export const tokenDropFormInitValues = {
  poolName: '',
  stakingTokenAddress: '',
  stakingTokenLogo: '',
  stakingToken2Address: '',
  stakingToken2Logo: '',
  rewardTokenAddress: '',
  rewardTokenLogo: '',
  rewardsPerSecond: '',
  minimumStakingPeriod: '',
  startDate: formattedDate(TOMORROW),
  endDate: formattedDate(DAY_AFTER_TOMORROW),
  earlyUnstakePenalty: '',
  description: '',
  stakingTokenRate: '',
  stakingToken2Rate: '',
  stakingTokenLink: '',
  stakingToken1Link: '',
};

export const validationSchema = Yup.object().shape({
  poolName: Yup.string().min(0).max(20).required(),
  stakingTokenAddress: Yup.string().test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  stakingToken2Address: Yup.string()
    .notOneOf([Yup.ref('stakingTokenAddress')])
    .test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  rewardTokenAddress: Yup.string().test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  stakingTokenLogo: Yup.string().required(),
  stakingToken2Logo: Yup.string().required(),
  rewardTokenLogo: Yup.string().required(),
  rewardsPerSecond: Yup.number().required(),
  minimumStakingPeriod: minimumStakingPeriodValidation,
  earlyUnstakePenalty: earlyUnstakePenaltyValidation,
  startDate: startDateValidation,
  endDate: endDateValidation,
  description: Yup.string().min(0).max(300).required(),
  stakingTokenRate: Yup.number().positive().required(),
  stakingToken2Rate: Yup.number().positive().required(),
  stakingTokenLink: Yup.string().url(),
  stakingToken1Link: Yup.string().url(),
});

export const tokenDropConfig: IConfigArray = [
  {
    fieldType: 'field',
    className: 'poolName',
    id: 'poolName',
    elementType: 'text',
    label: 'Pool name',
  },
  {
    fieldType: 'container',
    containerClassName: 'tokenDropTokensContainer',
    className: 'tokenDropTokens',
    content: [
      {
        fieldType: 'field',
        title: 'Staking token1 address',
        className: 'stakingTokenAddress',
        id: 'stakingTokenAddress',
        elementType: 'text',
        label: 'Address',
      },
      {
        fieldType: 'field',
        className: 'stakingTokenLogo',
        id: 'stakingTokenLogo',
        elementType: 'uploader',
        label: 'Upload token logo',
        customProps: {
          size: 'small',
        },
      },
      {
        title: 'Staking token2 address',
        fieldType: 'field',
        className: 'stakingToken2Address',
        id: 'stakingToken2Address',
        elementType: 'text',
        label: 'Address',
      },
      {
        fieldType: 'field',
        className: 'stakingToken2Logo',
        id: 'stakingToken2Logo',
        elementType: 'uploader',
        label: 'Upload token logo',
        customProps: {
          size: 'small',
        },
      },
      {
        fieldType: 'container',
        containerClassName: 'stakingRateContainer',
        className: 'stakingRate',
        subtitle: 'For example 1:5',
        content: [
          {
            title: 'Staking rate',
            fieldType: 'field',
            className: 'stakingTokenRate',
            id: 'stakingTokenRate',
            elementType: 'text',
            label: 'Token 1',
          },
          {
            fieldType: 'field',
            className: 'stakingToken2Rate',
            id: 'stakingToken2Rate',
            elementType: 'text',
            label: 'Token 2',
          },
        ],
      },
      {
        title: 'Reward token address',
        fieldType: 'field',
        className: 'rewardTokenAddress',
        id: 'rewardTokenAddress',
        elementType: 'text',
        label: 'Address',
      },
      {
        fieldType: 'field',
        className: 'rewardTokenLogo',
        id: 'rewardTokenLogo',
        elementType: 'uploader',
        label: 'Upload token logo',
        customProps: {
          size: 'small',
        },
      },
    ],
  },
  {
    fieldType: 'field',
    className: 'rewardsPerSecond',
    id: 'rewardsPerSecond',
    elementType: 'text',
    label: 'Amount',
    title: 'The rewards to be distributed per second',
  },
  {
    fieldType: 'field',
    className: 'minimumStakingPeriod',
    id: 'minimumStakingPeriod',
    elementType: 'number',
    label: 'Days',
    title: 'Minimum staking period',
  },
  {
    fieldType: 'container',
    containerClassName: 'datesContainer',
    className: 'dates',
    content: [
      {
        fieldType: 'field',
        className: 'startDate',
        id: 'startDate',
        elementType: 'date',
        label: 'Start date',
      },
      {
        fieldType: 'field',
        className: 'endDate',
        id: 'endDate',
        elementType: 'date',
        label: 'End date',
      },
    ],
  },
  {
    fieldType: 'field',
    className: 'earlyUnstakePenalty',
    id: 'earlyUnstakePenalty',
    elementType: 'number',
    label: 'Percent',
    title: 'Early unstake penalty',
    customProps: {
      helperText: 'Please insert the number of %',
    },
  },
  {
    fieldType: 'field',
    className: 'description',
    id: 'description',
    elementType: 'text',
    label: 'Description',
    customProps: {
      multiline: true,
      placeholder: 'Please add the description up to 300 characters.',
    },
  },
  {
    fieldType: 'field',
    className: 'stakingTokenLink',
    id: 'stakingTokenLink',
    elementType: 'text',
    label: 'Link to buy staking token 1',
  },
  {
    fieldType: 'field',
    className: 'stakingToken1Link',
    id: 'stakingToken1Link',
    elementType: 'text',
    label: 'Link to buy staking token 2',
  },
];

export const tokenDropFormConfig = {
  initialValues: tokenDropFormInitValues,
  validationSchema,
  fields: tokenDropConfig,
  formClassName: 'tokenDropForm',
};
