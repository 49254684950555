import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_MINT_700,
  COLOR_MINT_400,
  COLOR_MINT_600,
  COLOR_MINT_800, COLOR_MINT_300,
} from 'theme/colors';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles<Theme, { isDetailsOpen: boolean }>((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      minWidth: 0,
      borderRadius: theme.spacing(2.5),
      border: `1px solid ${COLOR_MINT_600}`,
      background: COLOR_MINT_800,
      boxSizing: 'border-box',
      padding: theme.spacing(2),
      [formatMedia.BREAKPOINT_TABLET]: {
        padding: theme.spacing(3),
      },
    },
    infoItem: {
      paddingBottom: theme.spacing(4),
      '& > *': {
        wordBreak: 'break-word',
      },
    },
    infoItemTitle: {
      paddingBottom: theme.spacing(1),
    },
    detailsContainer: {
      transition: '200ms',
      height: ({ isDetailsOpen }) => (isDetailsOpen ? 'fit-content' : 0),
      overflow: 'hidden',
    },
    textWithIcon: {
      ...flexHelper('flex-start'),
      '& > *': {
        '&:first-child': {
          marginRight: theme.spacing(1),
        },
      },
    },
    openDetailsBtn: {
      background: COLOR_MINT_700,
      color: COLOR_MINT_400,
      borderColor: COLOR_MINT_600,
      '& > *': {
        '& > *': {
          '& > *': {
            transition: '200ms',
            transform: ({ isDetailsOpen }) => (isDetailsOpen ? 'rotate(90deg)' : 'rotate(-90deg)'),
          },
        },
      },
    },
    actionButton: {
      borderRadius: 50,
    },
    buyStakingToken: {
      marginTop: theme.spacing(1),
      fontSize: '14px',
      linHeight: '143%',
      borderRadius: '154px',
      padding: '6px 21px',
      background: `${COLOR_MINT_300} !important`,
      color: `${COLOR_MINT_700} !important`,
      whiteSpace: 'nowrap',
      textTransform: 'none',
    },
  });
});
