import axios from 'core/axios';
import { ILogin } from 'types';

const urlBlock = 'accounts/';

export default {
  login: ({ address, msg, signedMsg }: ILogin) => axios.post(`${urlBlock}metamask_login/`, {
    address,
    msg,
    signed_msg: signedMsg,
  }),
  getMsg: ():Promise<{ data:string }> => axios.get(`${urlBlock}get_metamask_message/`),
};
