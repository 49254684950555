import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_BG_DARK_9,
  COLOR_MINT_200,
  COLOR_MINT_300,
  COLOR_MINT_400,
  COLOR_MINT_600,
  COLOR_MINT_700,
  COLOR_MINT_900,
} from 'theme/colors';

import { getFormatMedia } from 'theme/utils';
import { flexHelper, maxRowsOverflowHelper } from 'utils';

export const useStyles = makeStyles<Theme, { isRowOpen: boolean }>((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    borderedRow: {
      border: `1px solid ${COLOR_MINT_900}`,
      borderTopLeftRadius: 50,
      borderTopRightRadius: 50,
      marginBottom: ({ isRowOpen }) => (isRowOpen ? theme.spacing(0) : theme.spacing(2.5)),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    root: {
      borderTop: `1px solid ${COLOR_MINT_900}`,
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    withIcon: {
      ...flexHelper('flex-start'),
      '& > *': {
        '&:first-child': {
          marginRight: theme.spacing(1),
        },
      },
    },
    innerTable: {
      transition: '300ms',
      padding: ({ isRowOpen }) => (isRowOpen ? theme.spacing(0, 0, 2, 0) : 0),
    },
    collapseWrapper: {
      background: COLOR_BG_DARK_9,
      border: `1px solid ${COLOR_MINT_600}`,
      borderRadius: theme.spacing(2.5),
      padding: theme.spacing(2),
    },
    headerRow: {
      marginBottom: theme.spacing(1.5),
    },
    footerRow: {
      margin: theme.spacing(1, 0),
      borderTop: `1px solid ${COLOR_MINT_600}`,
      padding: theme.spacing(3, 1),
    },
    addressContainer: {
      width: '100%',
      ...flexHelper(),
      justifyContent: 'space-between',
    },
    paddingCell: {
      padding: theme.spacing(1, 1.5),
    },
    rowOpenBtn: {
      width: 110,
      background: ({ isRowOpen }) => (isRowOpen ? COLOR_MINT_700 : 'unset'),
      color: ({ isRowOpen }) => (isRowOpen ? COLOR_MINT_400 : 'unset'),
      borderColor: ({ isRowOpen }) => (isRowOpen ? COLOR_MINT_600 : 'transparent'),
      '& > *': {
        '& > *': {
          '& > *': {
            transition: '200ms',
            transform: ({ isRowOpen }) => (isRowOpen ? 'rotate(90deg)' : 'rotate(-90deg)'),
            '& > *': {
              fill: ({ isRowOpen }) => (isRowOpen ? COLOR_MINT_400 : COLOR_MINT_200),
            },
          },
        },
      },
    },
    actionButton: {
      width: 110,
      borderRadius: 50,
    },
    textWithIcon: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        '&:first-child': {
          marginRight: theme.spacing(1),
        },
      },
    },
    tokenName: {
      ...maxRowsOverflowHelper(),
    },
    subtitle: {
      marginBottom: theme.spacing(1.5),
    },
    tokenLogo: {
      width: 24,
      height: 24,
      borderRadius: '50%',
      objectFit: 'cover',
    },
    rewardTokenLogo: {
      width: 128,
      height: 128,
      borderRadius: '14px',
      objectFit: 'cover',
    },
    nftPreview: {
      display: 'flex',
      alignItems: 'flex-start',
      '&:first-child': {
        marginBottom: theme.spacing(3),
      },
    },
    nftBtn: {
      margin: 0,
      padding: 0,
    },
    nftPreviewRight: {
      marginLeft: theme.spacing(3),
    },
    nftPreviewLink: {
      marginTop: theme.spacing(1.5),
      maxWidth: '70%',
    },
    buyStakingToken: {
      position: 'absolute',
      left: '32px',
      top: '24px',
      fontSize: '14px',
      linHeight: '143%',
      borderRadius: '154px',
      padding: theme.spacing(0, 1),
      background: `${COLOR_MINT_300} !important`,
      color: `${COLOR_MINT_700} !important`,
      whiteSpace: 'nowrap',
      textTransform: 'none',
    },
    nftBuy: {
      position: 'relative',
      padding: '6px 21px',
      top: 0,
      left: 0,
      marginTop: theme.spacing(1),
    },
  });
});
