import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { CSSProperties } from '@material-ui/styles/withStyles';
import {
  COLOR_MINT_200,
  COLOR_MINT_400, COLOR_MINT_400_BOX_SHADOW,
  COLOR_MINT_700, COLOR_MINT_600, COLOR_MINT_100, COLOR_MINT_300, COLOR_BG_DARK_7,
} from 'theme/colors';

export const getMuiButton = (theme: Theme): Overrides['MuiButton'] => {
  const outlinedCommon: CSSProperties = {
    color: COLOR_MINT_200,
    borderRadius: '14px',
    border: `1px solid ${COLOR_MINT_400}`,
    backgroundColor: COLOR_BG_DARK_7,
    transition: '200ms',
    textTransform: 'capitalize',
    ...theme.typography.body2,

    '& $endIcon': {
      '& > *': {
        '& > *': {
          fill: COLOR_MINT_600,
        },
      },
    },

    '&$sizeLarge': {
      padding: theme.spacing(2, 4),
      height: '56px',
      ...theme.typography.button,
    },

    '&$sizeMedium': {
      padding: theme.spacing(1.5, 4),
      ...theme.typography.button,
    },

    '&$sizeSmall': {
      padding: theme.spacing(1.25, 4),
    },

    '&:hover': {
      color: COLOR_MINT_700,
      background: COLOR_MINT_300,
      boxShadow: COLOR_MINT_400_BOX_SHADOW,
      '& $endIcon': {
        '& > *': {
          '& > *': {
            fill: COLOR_MINT_400,
          },
        },
      },
    },
    '&:active': {
      color: COLOR_MINT_700,
      background: COLOR_MINT_400,
      boxShadow: COLOR_MINT_400_BOX_SHADOW,
    },
    '&$disabled': {
      opacity: 0.5,
    },
  };

  return {
    outlined: {
      ...outlinedCommon,
      ...theme.typography.body2,
    },
    outlinedPrimary: {
      ...outlinedCommon,
      ...theme.typography.body2,
      color: COLOR_MINT_100,
      borderColor: COLOR_MINT_600,
      '&:hover': {
        color: COLOR_MINT_300,
        borderColor: COLOR_MINT_300,
      },
      '&:active': {
        color: COLOR_MINT_700,
        background: COLOR_MINT_300,
        borderColor: COLOR_MINT_300,
        '& $endIcon': {
          '& > *': {
            '& > *': {
              fill: COLOR_MINT_600,
            },
          },
        },
      },
    },
    outlinedSecondary: {
      ...outlinedCommon,
      backgroundColor: COLOR_MINT_300,
      color: `${COLOR_MINT_700} !important`,
      '&:hover': {
        backgroundColor: COLOR_MINT_400,
        borderColor: COLOR_MINT_400,
      },
    },
    text: {
      background: 'none',
      transition: '0',
      boxSizing: 'border-box',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: '50px',
      '&:hover': {
        color: COLOR_MINT_400,
        background: COLOR_MINT_700,
        borderColor: COLOR_MINT_600,
      },
      '&:active': {
        color: COLOR_MINT_700,
        background: COLOR_MINT_400,
      },
    },

    contained: {
      ...outlinedCommon,
      backgroundColor: `${COLOR_MINT_700} !important`,
      borderWidth: '0 !important',
      boxShadow: 'none',
      color: `${COLOR_MINT_200} !important`,
      '&:active, &:hover': {
        color: COLOR_MINT_700,
        background: 'initial',
        boxShadow: 'none',
      },
    },
    startIcon: {
      marginRight: theme.spacing(1.5),
      marginLeft: 0,
    },
    endIcon: {
      marginLeft: theme.spacing(1.5),
      marginRight: 0,
    },
  };
};

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'outlined',
});
