import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';

export const getMuiTooltip = (): Overrides['MuiTooltip'] => ({
  tooltip: {
    fontSize: 16,
  },
});

export const getMuiTooltipDefaultProps = (): ComponentsProps['MuiTooltip'] => ({
  placement: 'top',
});
