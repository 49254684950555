import { erc20Abi } from 'config/abi';
import {
  select, put, call, takeEvery,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { getTokenAmount } from 'utils';
import BigNumber from 'bignumber.js';
import { approve } from '../actions';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';
import { MAX_UINT } from '../../../appConstants';

export function* approveSaga({
  type,
  payload: {
    web3Provider,
    spender,
    amount,
    approveTokenAddress,
    isMax = true,
  },
}: ReturnType<typeof approve>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));

  try {
    const tokenContract = yield (new web3Provider.eth.Contract(erc20Abi, approveTokenAddress));
    const allowance = yield call(tokenContract.methods.allowance(myAddress, spender).call);
    const tokenDecimals = yield call(tokenContract.methods.decimals().call);

    const amountWithDecimals = getTokenAmount(amount, tokenDecimals);
    if(new BigNumber(allowance).isLessThan(amountWithDecimals)) {
      yield call(tokenContract.methods.approve(
        spender,
        isMax ? MAX_UINT : amountWithDecimals,
      ).send, {
        from: myAddress,
      });
    }

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
    throw new Error('User denied approve');
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.APPROVE, approveSaga);
}
