import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { COLOR_MINT_100, COLOR_MINT_200, COLOR_MINT_400 } from 'theme/colors';

// import { COLOR_AKZ, COLOR_BLACK, COLOR_BLACK_4 } from 'theme/colors';

export enum FontWeights {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
}

export const getTypographyOptions = (): TypographyOptions => {
  const boldStyles = {
    '& strong': {
      fontWeight: FontWeights.Medium,
    },
  };

  return {
    fontFamily: 'Inter, sans-serif',

    allVariants: {
      color: COLOR_MINT_100,
      fontWeight: FontWeights.Regular,
      textAlign: 'left',
      fontStyle: 'normal',
    },

    h1: {
      fontSize: '80px',
      fontWeight: FontWeights.Medium,
      lineHeight: '80px',
      letterSpacing: '-0.005em',
      textTransform: 'capitalize',
    },
    h2: {
      fontSize: '32px',
      fontWeight: FontWeights.SemiBold,
      lineHeight: '24px',
      letterSpacing: '0px',
      textTransform: 'capitalize',
      minHeight: '30px',

      '&.acidGreen': {
        // background: COLOR_AKZ,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    },
    h3: {
      fontSize: '20px',
      fontWeight: FontWeights.SemiBold,
      lineHeight: '30px',
      letterSpacing: '0px',
      textTransform: 'capitalize',
    },
    h4: {
      fontSize: '26px',
      fontWeight: FontWeights.Medium,
      lineHeight: '32px',
      letterSpacing: '0.01em',
      textTransform: 'capitalize',
    },
    h5: {
      fontSize: '20px',
      fontWeight: FontWeights.Medium,
      lineHeight: '28px',
      letterSpacing: '0.01em',
      textTransform: 'capitalize',
    },
    h6: {
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0.01em',
      '&.mint': {
        color: `${COLOR_MINT_400} !important`,
      },
    },
    button: {
      fontSize: '14px',
      lineHeight: '24px',
      textTransform: 'capitalize',
      fontWeight: FontWeights.Medium,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      ...boldStyles,

      '&.capitalize': {
        textTransform: 'capitalize',
      },

      '&.colorSeconadry': {
        // color: COLOR_BLACK_4,
      },

      '&.mintLight': {
        color: `${COLOR_MINT_200} !important`,
      },
      '&.mint': {
        color: `${COLOR_MINT_400} !important`,
      },

      '&.l': {
        fontSize: '18px',
        lineHeight: '30px',
        letterSpacing: '0px',
        textTransform: 'none',
      },

      '&.s': {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0px',
      },

      '&.xs': {
        fontSize: '12px',
        fontWeight: FontWeights.Medium,
        lineHeight: '20px',
        letterSpacing: '0px',
      },

      '&.articleLargeLink': {
        fontSize: '20px',
        lineHeight: '32px',
        letterSpacing: '0.01em',
        textDecoration: 'underline',
        textDecorationThickness: '1.5px',
        textUnderlineOffset: '2px',
      },

      '&.articleSmall': {
        fontSize: '16px',
        lineHeight: '26px',
        letterSpacing: '0.02em',
      },

      '&.articleSmallBold': {
        fontSize: '16px',
        fontWeight: FontWeights.Medium,
        lineHeight: '26px',
        letterSpacing: '0.02em',
      },

      '&.articleSmallLink': {
        fontSize: '16px',
        lineHeight: '26px',
        letterSpacing: '0.02em',
        textDecoration: 'underline',
        textUnderlineOffset: '1px',
      },
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '-0.5px',
    },
    caption: {
      '&.category': {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
      },
      '&.backToTop': {
        fontSize: '12px',
        lineHeight: '12px',
        letterSpacing: '0.02em',
        // color: COLOR_BLACK,
      },
    },
  };
};
