/* eslint-disable react/no-array-index-key */
import React, {
  useCallback, useMemo, useState, VFC,
} from 'react';

import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';

import { getStatusIcon } from 'utils/getStatusIcon';
import { ArrowLeftIcon } from 'theme/icons';
import { Copyable } from 'components/Copyable';
import {
  Pools, IStakes, Status,
} from 'types';
import {
  formattedDate,
  getLinkToScaner,
  getTokenAmountDisplay,
  formatLongAmount,
} from 'utils';
import { DescriptionHelper, ExternalLinkBox } from 'components';
import BigNumber from 'bignumber.js';
import userSelector from 'store/user/selectors';
import { useShallowSelector } from 'hooks';
import { SECONDS_IN_DAY } from 'appConstants';
import { useStyles } from './TableRow.styles';
import { InnerTableHeadType } from '../../Pools.helper';
import { NftModal } from '../../NftModal/NftModal';

export interface TableProps {
  rowData: IStakes;
  innerTableHeadData: InnerTableHeadType[];
  className?: string;
  onStakeClick: (stakeId: number) => void;
  poolType: Pools;
}

export const CustomTableRow: VFC<TableProps> = ({
  rowData,
  innerTableHeadData,
  className,
  onStakeClick,
  poolType,
}) => {
  const [isRowOpen, setRowOpen] = useState(false);
  const chain = useShallowSelector(userSelector.getProp('chain'));
  const [currentNftData, setCurrentNftData] = useState({ img: '', hash: '', params: '' });
  const [isNftModalOpen, setIsNftModalOpen] = useState(false);

  const classes = useStyles({ isRowOpen });

  const toggleRowOpen = useCallback(() => {
    setRowOpen(!isRowOpen);
  }, [isRowOpen]);

  const isStakeButtonDisabled = useMemo(
    () => (rowData.status.label === Status.completed &&
        rowData.stakingToken0Staked === '0') ||
      rowData.status.label === Status.upcoming,
    [rowData.status.label, rowData.stakingToken0Staked],
  );

  const stakeButtonName = useMemo(
    () => (rowData.status.label === Status.completed ? 'Unstake' : 'Stake'),
    [rowData.status.label],
  );

  const lastIndex = useMemo(
    () => innerTableHeadData.length - 1,
    [innerTableHeadData.length],
  );

  const handleOpenNftModal = (img: string, hash: string, params: string) => {
    setCurrentNftData({
      img, hash, params,
    });
    setIsNftModalOpen(true);
  };

  return (
    <>
      {/* ROW WITH NAME APR, ETC */}
      {
        isNftModalOpen && (
          <NftModal
            nftImg={currentNftData.img}
            hash={currentNftData.hash}
            chain={chain}
            params={currentNftData.params}
            onClose={() => setIsNftModalOpen(false)}
            open={isNftModalOpen}
          />
        )
      }
      <TableRow className={clsx(classes.root, className)}>
        <TableCell align="left">
          <Box className={classes.withIcon}>
            <Tooltip title={rowData.name}>
              <Typography variant="body1">{formatLongAmount(rowData.name, 10)}</Typography>
            </Tooltip>
            <DescriptionHelper
              description={rowData.description}
              address={rowData.poolAddress}
              txHash={rowData.txHash}
              poolType={rowData.poolType}
              network={rowData.network}
            />
          </Box>
        </TableCell>
        {![Pools.NftDrop, Pools.NftPool].includes(poolType) && (
          <TableCell align="left">
            <Tooltip title={`${rowData.apr}%`}>
              <Typography variant="body1">
                {formatLongAmount(rowData.apr)}%
              </Typography>
            </Tooltip>
          </TableCell>
        )}
        <TableCell align="left">
          <Box className={classes.textWithIcon}>
            <img
              src={rowData.stakingToken0Logo}
              className={classes.tokenLogo}
              alt="token"
            />
            <Tooltip title={`${rowData.stakingToken0Staked} ${rowData.stakingToken0Symbol}`}>
              <Typography variant="body1" className={classes.tokenName}>
                {formatLongAmount(rowData.stakingToken0Staked, 7)}&nbsp;
                {rowData.stakingToken0Symbol}
              </Typography>
            </Tooltip>
            {isRowOpen && rowData.stakingToken0Link && (
              <Button href={rowData.stakingToken0Link} target="_blank" variant="contained" className={classes.buyStakingToken}>Buy now</Button>
            )}
          </Box>
        </TableCell>
        {[Pools.MainPool, Pools.TokenDrop, Pools.NftDrop].includes(
          poolType,
        ) && (
          <TableCell align="left">
            <Box className={classes.textWithIcon}>
              <img
                src={rowData.stakingToken1Logo}
                className={classes.tokenLogo}
                alt="token"
              />
              <Tooltip title={`${rowData.stakingToken1Staked} ${rowData.stakingToken1Symbol}`}>
                <Typography variant="body1" className={classes.tokenName}>
                  {formatLongAmount(rowData.stakingToken1Staked, 7)}&nbsp;
                  {rowData.stakingToken1Symbol}
                </Typography>
              </Tooltip>
              {Pools.MctPool !== poolType && isRowOpen && rowData.stakingToken1Link && (
                <Button href={rowData.stakingToken1Link} target="_blank" variant="contained" className={classes.buyStakingToken}>Buy now</Button>
              )}
            </Box>
          </TableCell>
        )}
        <TableCell align="left">
          <Box className={classes.textWithIcon}>
            {getStatusIcon(rowData.status.label)}
            <Typography variant="body1" className="capitalize">
              {rowData.status.label}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="right">
          <Button
            size="large"
            onClick={toggleRowOpen}
            endIcon={<ArrowLeftIcon />}
            className={classes.rowOpenBtn}
            variant="text"
          >
            Details
          </Button>
        </TableCell>
      </TableRow>
      {/* ROW SHOWN AFTER DETAILS IS OPEN */}
      <TableRow>
        <TableCell className={classes.innerTable} colSpan={12}>
          <Collapse collapsedHeight={0} in={isRowOpen}>
            <Box className={classes.collapseWrapper}>
              {/* THIS ROW BELONGS TO POOLS EXCEPT MCT, IT HAS FULL WIDTH AND IS ON TOP OF TABLE */}
              {innerTableHeadData[0].width === '100%' && (
                <Box className={clsx(classes.headerRow, classes.paddingCell)}>
                  {poolType === Pools.NftPool && (
                    <Box className={classes.nftPreview}>
                      <Button onClick={() => handleOpenNftModal(rowData.stakingToken1Logo, rowData.stakingToken1Address, rowData.stakingToken1Id)} variant="text" className={classes.nftBtn}>
                        <img
                          src={rowData.stakingToken1Logo}
                          className={classes.rewardTokenLogo}
                          alt="token"
                        />
                      </Button>
                      <Box className={classes.nftPreviewRight}>
                        <Typography variant="body1" className="s mint">
                          Staking NFT
                        </Typography>
                        <ExternalLinkBox
                          className={classes.nftPreviewLink}
                          variant="dark"
                          link={getLinkToScaner({
                            chain,
                            hash: rowData.stakingToken1Address,
                            params: `?a=${rowData.stakingToken1Id}`,
                            type: 'token',
                          })}
                        />
                        {rowData.stakingToken1Link && (
                          <Button href={rowData.stakingToken1Link} target="_blank" variant="contained" className={clsx(classes.buyStakingToken, classes.nftBuy)}>Buy now</Button>
                        )}
                      </Box>
                    </Box>
                  )}
                  {[Pools.NftPool, Pools.NftDrop].includes(poolType) ? (
                    <Box className={classes.nftPreview}>
                      <Button onClick={() => handleOpenNftModal(rowData.rewardTokenLogo, rowData.rewardTokenAddress, rowData.rewardTokenId)} variant="text" className={classes.nftBtn}>
                        <img
                          src={rowData.rewardTokenLogo}
                          className={classes.rewardTokenLogo}
                          alt="token"
                        />
                      </Button>
                      <Box className={classes.nftPreviewRight}>
                        <Typography variant="body1" className="s mint">
                          Reward NFT
                        </Typography>
                        <ExternalLinkBox
                          className={classes.nftPreviewLink}
                          variant="dark"
                          link={getLinkToScaner({
                            chain,
                            hash: rowData.rewardTokenAddress,
                            params: `?a=${rowData.rewardTokenId}`,
                            type: 'token',
                          })}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        className={clsx('s mint', classes.subtitle)}
                      >
                        Rewards in the pool
                      </Typography>
                      <Typography
                        className={classes.textWithIcon}
                        variant="body1"
                      >
                        <img
                          src={rowData.rewardTokenLogo}
                          className={classes.tokenLogo}
                          alt="token"
                        />
                        {new BigNumber(rowData.rewardsInThePool).toFixed(2)}&nbsp;
                        {rowData.rewardTokenSymbol}
                      </Typography>
                    </>
                  )}
                </Box>
              )}
              {/* THIS ROW CONTAINS TABLE CELLS, LIKE START DATE, END DATE, ETC */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {innerTableHeadData.map(({ id, label, width }, index) => {
                      if (
                        width !== '100%' ||
                        (index > 0 && index < innerTableHeadData.length - 1)
                      ) {
                        return (
                          <TableCell width={width} align="left" key={id}>
                            <Typography variant="body1" className="s mint">
                              {label}
                            </Typography>
                          </TableCell>
                        );
                      }
                      return null;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {innerTableHeadData.map(
                      ({
                        id, fieldType, fieldName, width,
                      }, index) => {
                        if (
                          width !== '100%' ||
                          (index > 0 && index < innerTableHeadData.length - 1)
                        ) {
                          return (
                            <TableCell width={width} align="left" key={id}>
                              {fieldType === 'date' && (
                                <Typography variant="body1">
                                  {formattedDate(
                                    new Date(+rowData[fieldName] * 1000),
                                  )}
                                </Typography>
                              )}
                              {fieldType === 'time' && (
                                <Typography variant="body1">00:00</Typography>
                              )}
                              {fieldType === 'numberWithDecimals' && (
                                <Typography variant="body1">
                                  {getTokenAmountDisplay(rowData[fieldName])}
                                </Typography>
                              )}
                              {fieldType === 'number' && (
                                <Typography variant="body1">
                                  {rowData[fieldName]}
                                </Typography>
                              )}
                              {fieldType === 'duration' && (
                                <Typography variant="body1">
                                  {+rowData[fieldName] / SECONDS_IN_DAY} days
                                </Typography>
                              )}
                              {fieldType === 'percent' && (
                                <Typography variant="body1">
                                  {rowData[fieldName]}%
                                </Typography>
                              )}
                              {fieldType === 'tokensSum' && (
                                <Typography variant="body1">
                                  {`
                                    ${rowData.stakingToken0AmountToStake}
                                    ${rowData.stakingToken0Symbol}
                                    +
                                    ${rowData.stakingToken1AmountToStake}
                                    ${rowData.stakingToken1Symbol}
                                  `}
                                </Typography>
                              )}
                              {fieldType === 'tokenToStake' && (
                                <Typography variant="body1">
                                  {`
                                  ${rowData.stakingToken0AmountToStake}
                                  ${rowData.stakingToken0Symbol}
                                `}
                                </Typography>
                              )}
                              {fieldType === 'copyableAddress' && (
                                <Copyable
                                  onlyIconActive
                                  transparent
                                  valueToCopy={rowData.poolAddress}
                                  zeroPadding
                                >
                                  <Typography noWrap>
                                    {`${rowData[fieldName]?.slice(0, 10)}...`}
                                  </Typography>
                                </Copyable>
                              )}
                            </TableCell>
                          );
                        }
                        return null;
                      },
                    )}
                    {poolType === Pools.MctPool && (
                      <TableCell width="10%" align="right">
                        <Button
                          onClick={() => onStakeClick(rowData.id)}
                          className={classes.actionButton}
                          size="small"
                          disabled={isStakeButtonDisabled}
                        >
                          {stakeButtonName}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
              {/* THIS ROW BELONGS TO POOLS EXCEPT MCT */}
              {/* POSITIONED AT THE BOTTOM, HAS FULL WIDTH, COPY ADDRESS AND STAKE BUTTON */}
              {innerTableHeadData[lastIndex].width === '100%' && (
                <Box className={classes.footerRow}>
                  <Typography variant="body1" className="s mint">
                    {innerTableHeadData[lastIndex].label}
                  </Typography>
                  <Box className={classes.addressContainer}>
                    <Copyable
                      onlyIconActive
                      transparent
                      valueToCopy={rowData.poolAddress}
                      zeroPadding
                    >
                      <Typography variant="body1">
                        {rowData.poolAddress}
                      </Typography>
                    </Copyable>
                    <Button
                      onClick={() => onStakeClick(rowData.id)}
                      className={classes.actionButton}
                      size="small"
                      disabled={isStakeButtonDisabled}
                    >
                      {stakeButtonName}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
