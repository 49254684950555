import { getTokenAmountDisplay } from 'utils';
import {
  select, put, call, takeLatest, all,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { getStatus } from 'containers/Pools/Pools.helper';
import { BackendPools } from 'types';
import { poolsApi } from 'services';
import userSelector from 'store/user/selectors';
import { mctPoolAbi } from 'config/abi';
import { mctLogo } from 'assets/images';
import { contractsConfig, ContractsNames } from 'config';
import { isMainnet } from 'config/constants';
import { sortPools } from 'utils/sortPools';
import actionTypes from '../actionTypes';
import { updateStakes } from '../reducer';
import { getMctPoolsData } from '../actions';

const MCT_DECIMALS = 18;

export function* getMctPoolsDataSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getMctPoolsData>) {
  yield put(apiActions.request(type));

  const { chain, address } = yield* select(userSelector.getUser);

  try {
    const { data: mctPools } = yield call(poolsApi.getPools, {
      network: chain,
      poolType: BackendPools.Staking,
    });

    const formattedMctPools = mctPools.map((stake) => ({
      name: stake.title,
      apr: stake.APR,
      poolAddress: stake.address,
      network: stake.network,
      description: stake.description,
      stakingToken0Staked: stake.total_stake_token[0],
      stakingToken0Logo: mctLogo,
      stakingToken0Address: contractsConfig.contracts[ContractsNames.token][isMainnet ? 'mainnet' : 'testnet'].address[chain],
      stakingToken0Symbol: 'MCT',
      status: getStatus(+stake.open_time, +stake.close_time),
      startDate: stake.open_time,
      endDate: stake.close_time,
      earlyUnstakePenalty: stake.penalty,
      maxGoalOfStaking: getTokenAmountDisplay(
        stake.max_goal_of_staking,
        MCT_DECIMALS,
      ),
      maxStakeAmount: getTokenAmountDisplay(
        stake.max_stake_amount,
        MCT_DECIMALS,
      ),
      poolType: stake.pool_type,
      txHash: stake.tx_hash,
      id: stake.id,
      stakingToken0Link:
        stake.stake_token_urls.length > 0 && stake.stake_token_urls[0] && stake.stake_token_urls[0],
    }));

    let stakesInfo = [];
    if (address) {
      // stakingTokens user staked
      const stakesInfoPromises = formattedMctPools.map(
        ({ poolAddress }) => new web3Provider.eth.Contract(
          mctPoolAbi, poolAddress,
        ).methods
          .userStakesReward(address)
          .call(),
      );
      stakesInfo = yield all(stakesInfoPromises);
    }

    const mctPoolsWithDataFromBlockchain = formattedMctPools.map(
      (pool, index) => ({
        ...pool,
        userStakedToken0Amount: getTokenAmountDisplay(
          stakesInfo[index]?.[0].totalStakedAmount || 0,
          MCT_DECIMALS,
        ),
        userRewardAmount: getTokenAmountDisplay(
          stakesInfo[index]?.[1] || 0,
          MCT_DECIMALS,
        ),
      }),
    );

    const sortedPools = sortPools('status', 'desc', mctPoolsWithDataFromBlockchain);

    const mctTotalUserRewards = mctPoolsWithDataFromBlockchain.reduce(
      (totalRewards, currentReward) => +totalRewards + +currentReward.userRewardAmount,
      0,
    );

    yield put(
      updateStakes({
        mctPoolStakes: sortedPools,
        mctTotalUserRewards,
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_MCT_POOLS, getMctPoolsDataSaga);
}
