import { Box, Typography } from '@material-ui/core';
import React, {
  VFC,
  ImgHTMLAttributes,
  ReactElement,
} from 'react';
import clsx from 'clsx';

import { useStyles } from './TokenBox.styles';

type Size = 'm' | 'l';

type TokenBoxProps = {
  className?: string;
  classes?: {
    img?: string;
    text?: string;
  };
  text: string;
  size?: Size;
  src?: string | ReactElement;
} & Pick<ImgHTMLAttributes<HTMLImageElement>, 'alt'>;

export const TokenBox: VFC<TokenBoxProps> = ({
  className, classes: otherClasses = {}, src, alt, text, size = 'm',
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      {
        src && (
          typeof src === 'string' ? (
            <Box className={clsx(classes.imgBox, size)}>
              <img className={clsx(classes.img, size, otherClasses.img)} src={src} alt={alt} />
            </Box>
          ) : src
        )
      }

      <Typography className={clsx(classes.text, size, otherClasses.text)}>
        {text}
      </Typography>
    </Box>
  );
};
