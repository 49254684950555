/* eslint-disable @typescript-eslint/no-unused-vars */
import { Theme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import {
  COLOR_MINT_300, COLOR_MINT_600,
} from 'theme/colors';

export const getMuiRadio = (theme: Theme): Overrides['MuiRadio'] => ({
  root: {
    color: COLOR_MINT_600,
    background: 'transparent',
    '&:active, &.Mui-checked, &.MuiRadio-colorSecondary.Mui-checked': {
      color: COLOR_MINT_300,
      background: 'transparent',
    },
    '&:hover': {
      background: 'transparent',
      color: COLOR_MINT_300,
    },
  },
});

export const getMuiRadioDefaultProps = (): ComponentsProps['MuiRadio'] => ({ });
