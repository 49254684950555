import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { COLOR_BG_DARK_7, COLOR_MINT_600 } from '../colors';

export const getMuiPaper = (): Overrides['MuiPaper'] => ({
  root: {
    left: 0,
  },
});

export const getMuiMenu = (theme: Theme): Overrides['MuiMenu'] => ({
  paper: {
    marginTop: theme.spacing(1),
    background: COLOR_BG_DARK_7,
    // backdropFilter: 'blur(30px)',
    border: `1px solid ${COLOR_MINT_600}`,
    borderRadius: 12,
    overflow: 'hidden',
  },
});

export const getMuiMenuItem = (): Overrides['MuiMenuItem'] => ({
  root: {
    '&$selected': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: COLOR_MINT_600,
      },
    },
  },
});

export const getMuiListItem = (): Overrides['MuiListItem'] => ({
  button: {
    '&:hover': {
      backgroundColor: COLOR_MINT_600,
    },
  },
});

export const getMuiSelect = (): Overrides['MuiSelect'] => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      background: 'none !important',
    },
  },
});
