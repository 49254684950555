import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const MediumIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="33" height="34" viewBox="0 0 33 34" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.1377 33.2005C25.0504 33.2005 32.2755 25.9754 32.2755 17.0627C32.2755 8.1501 25.0504 0.924988 16.1377 0.924988C7.22511 0.924988 0 8.1501 0 17.0627C0 25.9754 7.22511 33.2005 16.1377 33.2005ZM12.1171 21.6735C14.9908 21.6735 17.3193 19.3508 17.3193 16.4864C17.3183 15.8042 17.1831 15.1289 16.9211 14.499C16.6592 13.8691 16.2757 13.2969 15.7927 12.8153C15.3096 12.3336 14.7363 11.9518 14.1057 11.6917C13.475 11.4315 12.7993 11.2982 12.1171 11.2993C9.24462 11.2993 6.91617 13.6208 6.91617 16.4864C6.91708 17.1685 7.05233 17.8437 7.31419 18.4735C7.57606 19.1034 7.95941 19.6754 8.44237 20.1571C8.92532 20.6388 9.49842 21.0206 10.1289 21.2808C10.7595 21.541 11.435 21.6744 12.1171 21.6735ZM20.4246 21.3692C21.8609 21.3692 23.0251 19.1837 23.0251 16.4864C23.0251 13.7902 21.8609 11.6036 20.4246 11.6036C18.9884 11.6036 17.8241 13.7891 17.8241 16.4864C17.8241 19.1825 18.9884 21.3692 20.4246 21.3692ZM24.4441 20.8609C24.9501 20.8609 25.3593 18.9024 25.3593 16.4864C25.3593 14.0703 24.9501 12.1119 24.4452 12.1119C23.9392 12.1119 23.53 14.0703 23.53 16.4864C23.53 18.9013 23.9392 20.8609 24.4441 20.8609Z" fill="#A4F8DE" />
  </BaseSVGIcon>

);
