import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';
import {
  COLOR_MINT_600, COLOR_MINT_300, COLOR_MINT_200, COLOR_MINT_100,
} from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    body: {
      ...flexHelper('space-between', 'flex-start'),
      gap: theme.spacing(1),
    },
    description: {
      width: '280px',
      overflowWrap: 'break-word',
    },
    descriptionTextArea: {
      '& textarea': {
        padding: '0 !important',
        borderRadius: 0,
        fontSize: '13px',
        lineHeight: '15px',
        color: COLOR_MINT_100,
      },
      '&>*': {
        '&:before': {
          display: 'none',
        },
      },
    },
    descriptionHelpers: {
      ...flexHelper('space-between'),
      marginTop: theme.spacing(1),
    },
    saveBtn: {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: '0 !important',
      '&>*': {
        color: COLOR_MINT_200,
        ...flexHelper('flex-start'),
      },
      '&:hover': {
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        '&>*': {
          color: `${COLOR_MINT_300} !important`,
          textDecoration: 'underline !important',
        },
      },
    },
    activeEdit: {
      background: COLOR_MINT_600,
      '& path': {
        fill: COLOR_MINT_300,
      },
      '&:hover': {
        opacity: 0.7,
      },
    },
  });
});
