import React from 'react';
import { Loader } from 'components';
import { RequestStatus } from 'types';
import uiSelector from 'store/ui/selectors';
import { useShallowSelector } from 'hooks';
import stakesActionTypes from 'store/stakes/actionTypes';

const GlobalLoader = () => {
  const {
    [stakesActionTypes.GET_MCT_POOLS]: getMctPoolsReqStatus,
    [stakesActionTypes.GET_MINING_POOLS_DATA]: getMiningPoolsReqStatus,
    [stakesActionTypes.GET_NFT_DROPS_DATA]: getNftDropsReqStatus,
    [stakesActionTypes.GET_MAIN_POOLS_DATA]: getMainPoolsReqStatus,
    [stakesActionTypes.GET_TOKEN_DROPS_DATA]: getTokenDropsReqStatus,
    [stakesActionTypes.GET_NFT_POOLS_DATA]: getNftPoolReqStatus,
    [stakesActionTypes.STAKE]: stakeReqStatus,
    [stakesActionTypes.UNSTAKE]: unstakeReqStatus,
    [stakesActionTypes.STAKE_TO_NFT_POOL]: stakeToNftPoolReqStatus,
    [stakesActionTypes.STAKE_TO_NFT_DROP]: stakeToNftDropReqStatus,
    [stakesActionTypes.STAKE_TO_MINING_POOL]: stakeToMiningPoolReqStatus,
    [stakesActionTypes.STAKE_TO_ERC20_PLUS_ERC20]: stakeToErc20PlusErc20PoolReqStatus,
    [stakesActionTypes.UNSTAKE_FROM_NFT_POOL]: unstakeFromNftPoolReqStatus,
    [stakesActionTypes.UNSTAKE_FROM_NFT_DROP]: unstakeFromNftDropReqStatus,
    [stakesActionTypes.UNSTAKE_FROM_MINING_POOl]: unstakeFromMiningPoolReqStatus,
    [stakesActionTypes.UNSTAKE_FROM_ERC20_PLUS_ERC20]:
      unstakeFromErc20PlusErc20PoolReqStatus,
  } = useShallowSelector(uiSelector.getUI);

  const shouldShowLoader = [
    stakeReqStatus,
    unstakeReqStatus,
    getMctPoolsReqStatus,
    getMiningPoolsReqStatus,
    getNftDropsReqStatus,
    getMainPoolsReqStatus,
    getTokenDropsReqStatus,
    getNftPoolReqStatus,
    stakeToNftPoolReqStatus,
    unstakeFromNftPoolReqStatus,
    stakeToNftDropReqStatus,
    unstakeFromNftDropReqStatus,
    stakeToMiningPoolReqStatus,
    unstakeFromMiningPoolReqStatus,
    stakeToErc20PlusErc20PoolReqStatus,
    unstakeFromErc20PlusErc20PoolReqStatus,
  ].includes(RequestStatus.REQUEST);
  return shouldShowLoader && <Loader />;
};

export default GlobalLoader;
