/* eslint-disable max-len */
import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { contractsConfig, ContractsNames } from 'config';
import { DELAY_BEFORE_UPDATE, isMainnet } from 'config/constants';
import setNotification from 'utils/setNotification';
import userSelector from 'store/user/selectors';
import { unstake } from 'store/stakes/actions';
import actionTypes from 'store/stakes/actionTypes';
import { delay } from 'typed-redux-saga';
import { getMctPoolsDataSaga } from './getMctPools';

function* unstakeSaga({
  type, payload: {
    web3Provider,
    stakingContractAddress,
  },
}: ReturnType<typeof unstake>) {
  yield put(apiActions.request(type));
  const {
    abi: stakingAbi,
  } = contractsConfig.contracts[ContractsNames.staking][isMainnet ? 'mainnet' : 'testnet'];

  const myAddress = yield select(userSelector.getProp('address'));

  try {
    const stakingContract = yield (new web3Provider.eth.Contract(stakingAbi, stakingContractAddress));

    yield call(stakingContract.methods.unstake().send, {
      from: myAddress,
    });

    yield delay(DELAY_BEFORE_UPDATE);

    yield call(getMctPoolsDataSaga, {
      type: actionTypes.GET_MCT_POOLS,
      payload: {
        web3Provider,
      },
    });

    setNotification({ type: 'success', message: 'Successfully unstaked' });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UNSTAKE, unstakeSaga);
}
