/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BackendChains, BackendPools, DescriptionModalState } from 'types';

const initialState: DescriptionModalState = {
  address: '',
  description: '',
  title: '',
  txHash: '',
  poolType: BackendPools.Staking,
  network: BackendChains['Binance-Smart-Chain'],
  isDescriptionModalOpen: false,
};

export const descriptionModalReducer = createSlice({
  name: 'descriptionModal',
  initialState,
  reducers: {
    updateDescriptionModal: (
      state,
      action: PayloadAction<Partial<DescriptionModalState>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
    openDescriptionModal: (state) => ({
      ...state,
      isDescriptionModalOpen: true,
    }),
    closeDescriptionModal: (state) => ({
      ...state,
      isDescriptionModalOpen: false,
    }),
  },
});

export const {
  updateDescriptionModal,
  openDescriptionModal,
  closeDescriptionModal,
} = descriptionModalReducer.actions;

export default descriptionModalReducer.reducer;
