import React from 'react';

import { COLOR_MINT_300 } from 'theme/colors';
import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const CompleteIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="18" height="18" fill={COLOR_MINT_300} viewBox="0 0 18 18" {...props}>
    <path d="M13.3078 8.49527C13.564 8.23899 13.564 7.82348 13.3078 7.5672C13.0515 7.31092 12.636 7.31092 12.3797 7.5672L8.90623 11.0406L7.62028 9.75469C7.36399 9.49841 6.94848 9.49841 6.6922 9.75469C6.43592 10.011 6.43592 10.4265 6.6922 10.6828L8.44219 12.4328C8.69848 12.689 9.11399 12.689 9.37027 12.4328L13.3078 8.49527Z" fill={COLOR_MINT_300} />
    <path fillRule="evenodd" clipRule="evenodd" d="M3 9.99998C3 6.134 6.134 3 9.99998 3C13.866 3 17 6.134 17 9.99998C17 13.866 13.866 17 9.99998 17C6.134 17 3 13.866 3 9.99998ZM4.3125 9.99998C4.3125 6.85887 6.85887 4.3125 9.99998 4.3125C13.1411 4.3125 15.6875 6.85887 15.6875 9.99998C15.6875 13.1411 13.1411 15.6875 9.99998 15.6875C6.85887 15.6875 4.3125 13.1411 4.3125 9.99998Z" fill={COLOR_MINT_300} />
  </BaseSVGIcon>
);
