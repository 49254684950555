import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const ArrowLeftIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1352 14.1665C10.9169 14.1665 10.6994 14.0815 10.536 13.9124L7.31686 10.579C7.00186 10.2515 7.00603 9.73238 7.3277 9.41072L10.661 6.07738C10.986 5.75155 11.5135 5.75155 11.8394 6.07738C12.1644 6.40322 12.1644 6.92988 11.8394 7.25572L9.0852 10.0099L11.7344 12.754C12.0544 13.0857 12.0452 13.6132 11.7144 13.9324C11.5519 14.089 11.3435 14.1665 11.1352 14.1665Z" fill="#A4F8DE" />
    <mask id="mask0_25_5327" maskUnits="userSpaceOnUse" x="7" y="5" width="6" height="10">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1352 14.1665C10.9169 14.1665 10.6994 14.0815 10.536 13.9124L7.31686 10.579C7.00186 10.2515 7.00603 9.73238 7.3277 9.41072L10.661 6.07738C10.986 5.75155 11.5135 5.75155 11.8394 6.07738C12.1644 6.40322 12.1644 6.92988 11.8394 7.25572L9.0852 10.0099L11.7344 12.754C12.0544 13.0857 12.0452 13.6132 11.7144 13.9324C11.5519 14.089 11.3435 14.1665 11.1352 14.1665Z" fill="white" />
    </mask>
    <g mask="url(#mask0_25_5327)" />
  </BaseSVGIcon>
);
