/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useMemo, useState, VFC,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField, Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { Modal } from 'components/Modal';
import { getStatusIcon } from 'utils/getStatusIcon';
import { IStakes, Status, TNullable } from 'types';
import { SECONDS_IN_DAY } from 'appConstants';

import { useWalletConnectorContext } from 'services';

import { stakeToMiningPool, unstakeFromMiningPool } from 'store/stakes/actions';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import BigNumber from 'bignumber.js';
import { formatLongAmount, getCurrentTimestampInSeconds } from 'utils';
import { PenaltyBox, TokenBox, useStakeUnstakeRadioGroup } from '../components';
import { useBaseStyles } from '../BaseStakeModal';
import { RadioStates } from '../BaseStakeModal/BaseStakeModal.types';

interface IMiningPoolStakeModal {
  selectedStakeData: IStakes;
  onClose: () => void;
  open: boolean;
}

export const MiningPoolStakeModal: VFC<IMiningPoolStakeModal> = ({
  onClose,
  open,
  selectedStakeData,
}) => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const {
    stakingToken0Symbol,
    stakingToken0Logo,
    status,
    apr,
    rewardTokenSymbol,
    rewardTokenLogo,
    minimumStakingTime,
    userStakedToken0Amount,
    earlyUnstakePenalty,
    pendingReward,
    stakingToken0Decimals,
    id,
    poolAddress,
    stakingToken0Address,
    endDate,
  } = selectedStakeData;

  const stakingToken0UserBalance = useShallowSelector(
    userSelector.getBalanceOfToken(stakingToken0Address),
  );

  const {
    StakeUnstakeRadioGroup, isStakeSelected, radioState, setRadioState,
  } =
    useStakeUnstakeRadioGroup();

  const stakeOrUnstakeText = isStakeSelected ? 'Stake' : 'Unstake + Claim';

  const isShowPenalty = useMemo(
    () => radioState === RadioStates.unstake && status.label === Status.active,
    [status, radioState],
  );

  const [stakeAmount, setStakeAmount] = useState<TNullable<string>>();
  const [unstakeAmount, setUnstakeAmount] = useState<TNullable<string>>();

  const handleStakeAmountChange = useCallback((event) => {
    const { value } = event.target;
    setStakeAmount(value);
  }, []);

  const handleUnstakeAmountChange = useCallback((event) => {
    const { value } = event.target;
    setUnstakeAmount(value);
  }, []);

  const handleStakeMaxClick = () => {
    setStakeAmount(stakingToken0UserBalance);
  };

  const handleUnstakeMaxClick = () => {
    setUnstakeAmount(userStakedToken0Amount);
  };

  const isStakeEnd = getCurrentTimestampInSeconds() > +endDate - +minimumStakingTime;

  const isUnstakeAvailable = new BigNumber(
    userStakedToken0Amount,
  ).isGreaterThan(0);

  const isStakeBtnAvailable =
    new BigNumber(stakeAmount).isLessThanOrEqualTo(stakingToken0UserBalance) &&
    !!stakeAmount && !isStakeEnd;
  const isUnstakeBtnAvailable =
    new BigNumber(unstakeAmount).isLessThanOrEqualTo(userStakedToken0Amount) &&
    !!unstakeAmount;

  const clearInputs = () => {
    setStakeAmount('');
    setUnstakeAmount('');
  };

  const handleCloseModal = useCallback(() => {
    setRadioState(RadioStates.stake);
    onClose();
    clearInputs();
  }, [setRadioState, onClose]);

  const handleStakeClick = () => {
    dispatch(
      stakeToMiningPool({
        web3Provider: walletService.Web3(),
        stakingToken0Address,
        stakingToken0AmountToStake: stakeAmount,
        stakingToken0Decimals,
        id,
        poolAddress,
      }),
    );
    clearInputs();
  };

  const handleUnstakeClick = () => {
    dispatch(
      unstakeFromMiningPool({
        web3Provider: walletService.Web3(),
        amountToUnstake: unstakeAmount,
        stakingToken0Decimals,
        stakingToken0Address,
        id,
        poolAddress,
      }),
    );
    clearInputs();
  };

  useEffect(() => {
    if (selectedStakeData?.status?.label === Status.completed) {
      setRadioState(RadioStates.unstake);
    }
  }, [selectedStakeData?.status?.label, setRadioState]);

  useEffect(() => {
    if (!isUnstakeAvailable) {
      setRadioState(RadioStates.stake);
    }
  }, [isUnstakeAvailable, setRadioState]);

  const baseStakeModalClasses = useBaseStyles();

  return (
    <Modal open={open} onClose={handleCloseModal} customTitle="Mining Pool">
      <FormControl component="fieldset">
        {isUnstakeAvailable &&
          selectedStakeData.status.label !== Status.completed && (
            <StakeUnstakeRadioGroup />
        )}

        {isStakeSelected ? (
          <TextField
            value={stakeAmount || ''}
            label="Amount"
            className={baseStakeModalClasses.textField}
            onChange={handleStakeAmountChange}
            InputProps={{
              endAdornment: (
                <>
                  <TokenBox
                    className={baseStakeModalClasses.textFieldToken}
                    src={stakingToken0Logo}
                    text={stakingToken0Symbol}
                  />
                  <Button
                    onClick={handleStakeMaxClick}
                    className={baseStakeModalClasses.maxBtn}
                    variant="contained"
                  >
                    MAX
                  </Button>
                </>
              ),
            }}
          />
        ) : (
          <TextField
            value={unstakeAmount || ''}
            label="Amount"
            className={baseStakeModalClasses.textField}
            onChange={handleUnstakeAmountChange}
            InputProps={{
              endAdornment: (
                <>
                  <TokenBox
                    className={baseStakeModalClasses.textFieldToken}
                    src={stakingToken0Logo}
                    text={stakingToken0Symbol}
                  />
                  <Button
                    onClick={handleUnstakeMaxClick}
                    className={baseStakeModalClasses.maxBtn}
                    variant="contained"
                  >
                    MAX
                  </Button>
                </>
              ),
            }}
          />
        )}

        <Grid container className={baseStakeModalClasses.info}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              Status
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              {getStatusIcon(status.label)}
              <Typography className={baseStakeModalClasses.status}>
                {status.label}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              Apr
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              <Tooltip title={`${apr}%`}>
                <Typography>{formatLongAmount(apr)}%</Typography>
              </Tooltip>
            </Box>
          </Grid>

          {isStakeSelected && (
            <>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Reward token
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <TokenBox
                    className={baseStakeModalClasses.textFieldToken}
                    src={rewardTokenLogo}
                    text={rewardTokenSymbol}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Minimum staking time
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {+minimumStakingTime / SECONDS_IN_DAY} days
                  </Typography>
                </Box>
              </Grid>
            </>
          )}

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              {`Your ${
                isStakeSelected
                  ? `${stakingToken0Symbol} balance`
                  : `${stakingToken0Symbol} staked`
              }`}
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              <Typography>
                {isStakeSelected
                  ? `${stakingToken0UserBalance} ${stakingToken0Symbol}`
                  : `${userStakedToken0Amount} ${stakingToken0Symbol}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              {`Your ${
                isStakeSelected
                  ? `${stakingToken0Symbol} staked`
                  : `${rewardTokenSymbol} earned`
              }`}
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              <Typography>
                {isStakeSelected
                  ? `${userStakedToken0Amount} ${stakingToken0Symbol}`
                  : `${pendingReward} ${rewardTokenSymbol}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {isShowPenalty && <PenaltyBox penalty={earlyUnstakePenalty} />}
        <Button
          className={baseStakeModalClasses.button}
          variant="outlined"
          color="secondary"
          onClick={isStakeSelected ? handleStakeClick : handleUnstakeClick}
          disabled={
            isStakeSelected ? !isStakeBtnAvailable : !isUnstakeBtnAvailable
          }
        >
          {stakeOrUnstakeText}
        </Button>
      </FormControl>
    </Modal>
  );
};
