import { Pools, Status } from 'types';

export const ROWS_PER_PAGE = 6;

export type Order = 'asc' | 'desc';

export const MOBILE_SORTING_DIVIDER = '*';

export const getStatus =
  (openTime: number, closeTime: number): { label: Status, value: number } => {
    const currentTime = Date.now();
    if (openTime * 1000 > currentTime) {
      return {
        label: Status.upcoming,
        value: 2,
      };
    }

    if (closeTime * 1000 < currentTime) {
      return {
        label: Status.completed,
        value: 1,
      };
    }

    return {
      label: Status.active,
      value: 3,
    };
  };

export type InnerTableHeadType = {
  id: string;
  label: string;
  width: string;
  fieldType: string;
  fieldName: string;
};

export const poolsTableHeadData = {
  [Pools.MctPool]: {
    tableHeadData: [
      {
        id: 'poolName',
        label: 'Pool Name',
        type: 'default',
      },
      {
        id: 'apr',
        label: 'APR',
        type: 'sortable',
      },
      {
        id: 'stakingToken0Staked',
        label: 'Total Staked',
        type: 'sortable',
      },
      {
        id: 'status',
        label: 'Status',
        type: 'sortable',
      },
      {
        type: 'empty',
      },
    ],
    innerTableHeadData: [
      {
        id: 'startDate',
        label: 'Start date',
        width: '12%',
        fieldType: 'date',
        fieldName: 'startDate',
      },
      {
        id: 'endDate',
        label: 'End date',
        width: '12%',
        fieldType: 'date',
        fieldName: 'endDate',
      },
      {
        id: 'utc',
        label: 'UTC',
        width: '10%',
        fieldType: 'time',
        fieldName: 'time',
      },
      {
        id: 'totalGoal',
        label: 'Total goal ',
        width: '18%',
        fieldType: 'number',
        fieldName: 'maxGoalOfStaking',
      },
      {
        id: 'maximumStake',
        label: 'Maximum stake',
        width: '18%',
        fieldType: 'number',
        fieldName: 'maxStakeAmount',
      },
      {
        id: 'poolAddress',
        label: 'Pool address ',
        width: '20%',
        fieldType: 'copyableAddress',
        fieldName: 'poolAddress',
      },
    ],
    info: {
      title: '',
      text: 'MCT tokens can be staked in a pool with fixed APR.',
    },
  },
  [Pools.MiningPool]: {
    tableHeadData: [
      {
        id: 'poolName',
        label: 'Pool Name',
        type: 'default',
      },
      {
        id: 'apr',
        label: 'APR',
        type: 'sortable',
      },
      {
        id: 'stakingToken0Staked',
        label: 'Total Staked',
        type: 'sortable',
      },
      {
        id: 'status',
        label: 'Status',
        type: 'sortable',
      },
      {
        type: 'empty',
      },
    ],
    innerTableHeadData: [
      {
        id: 'rewardsInPool',
        label: 'Rewards in the pool',
        width: '100%',
        fieldType: 'numberWithDecimals',
        fieldName: 'rewardsInThePool',
      },
      {
        id: 'startDate',
        label: 'Start date',
        width: '20%',
        fieldType: 'date',
        fieldName: 'startDate',
      },
      {
        id: 'endDate',
        label: 'End date',
        width: '20%',
        fieldType: 'date',
        fieldName: 'endDate',
      },
      {
        id: 'minimumStakingTime',
        label: 'Minimum staking time',
        width: '20%',
        fieldType: 'duration',
        fieldName: 'minimumStakingTime',
      },
      {
        id: 'earlyUnstakePenalty',
        label: 'Early unstake penalty',
        width: '20%',
        fieldType: 'percent',
        fieldName: 'earlyUnstakePenalty',
      },
      {
        id: 'poolAddress',
        label: 'Pool address ',
        width: '100%',
        fieldType: 'copyableAddress',
        fieldName: 'poolAddress',
      },
    ],
    info: {
      title: 'Stake Token 1 -> Receive Token 2',
      text: 'If you stake Token 1 you will receive Token 2 as staking reward. APR is not static and depends on the pool share, token amount, and staking period.',
    },
  },
  [Pools.NftDrop]: {
    tableHeadData: [
      {
        id: 'poolName',
        label: 'Pool Name',
        type: 'default',
      },
      {
        id: 'totalStakedToken0',
        label: 'Total Staked',
        type: 'sortable',
      },
      {
        id: 'totalStakedToken1',
        label: 'Total Staked',
        type: 'sortable',
      },
      {
        id: 'status',
        label: 'Status',
        type: 'sortable',
      },
      {
        type: 'empty',
      },
    ],
    innerTableHeadData: [
      {
        id: 'reward',
        label: 'Reward',
        width: '100%',
        fieldType: 'copyableLink',
        fieldName: 'rewardToken',
      },
      {
        id: 'startDate',
        label: 'Start date',
        width: '20%',
        fieldType: 'date',
        fieldName: 'startDate',
      },
      {
        id: 'endDate',
        label: 'End date',
        width: '20%',
        fieldType: 'date',
        fieldName: 'endDate',
      },
      {
        id: 'stakingTime',
        label: 'Staking time',
        width: '20%',
        fieldType: 'duration',
        fieldName: 'stakingTime',
      },
      {
        id: 'amountToStake',
        label: 'Amount to stake',
        width: '20%',
        fieldType: 'tokensSum',
        fieldName: 'amountToStake',
      },
      {
        id: 'poolAddress',
        label: 'Pool address ',
        width: '100%',
        fieldType: 'copyableAddress',
        fieldName: 'address',
      },
    ],
    info: {
      title: 'Stake Token 1 + Token 2 -> Receive NFT',
      text: 'If you stake a fixed amount of Token 1 along with Token 2, you will receive a NFT. The amount of NFTs in the reward pool are limited so participation will be First Come First Serve basis.',
    },
  },
  [Pools.MainPool]: {
    tableHeadData: [
      {
        id: 'poolName',
        label: 'Pool Name',
        type: 'default',
      },
      {
        id: 'apr',
        label: 'APR',
        type: 'sortable',
      },
      {
        id: 'stakingToken0Staked',
        label: 'Total Staked',
        type: 'sortable',
      },
      {
        id: 'stakingToken1Staked',
        label: 'Total Staked',
        type: 'sortable',
      },
      {
        id: 'status',
        label: 'Status',
        type: 'sortable',
      },
      {
        type: 'empty',
      },
    ],
    innerTableHeadData: [
      {
        id: 'rewardToken',
        label: 'Rewards in the pool',
        width: '100%',
        fieldType: 'token',
        fieldName: 'rewardsInThePool',
      },
      {
        id: 'startDate',
        label: 'Start date',
        width: '20%',
        fieldType: 'date',
        fieldName: 'startDate',
      },
      {
        id: 'endDate',
        label: 'End date',
        width: '20%',
        fieldType: 'date',
        fieldName: 'endDate',
      },
      {
        id: 'minimumStakingTime',
        label: 'Minimum staking time',
        width: '20%',
        fieldType: 'duration',
        fieldName: 'minimumStakingTime',
      },
      {
        id: 'earlyUnstakePenalty',
        label: 'Early unstake penalty',
        width: '20%',
        fieldType: 'percent',
        fieldName: 'earlyUnstakePenalty',
      },
      {
        id: 'poolAddress',
        label: 'Pool address ',
        width: '100%',
        fieldType: 'copyableAddress',
        fieldName: 'poolAddress',
      },
    ],
    info: {
      title: 'Stake Token 1 + Token 2 -> Receive Token 1 or 2',
      text: 'If you stake Token 1 along with Token 2 in a fixed ratio, you will receive either Token 1 or Token 2 as staking reward. APR is not static and depends on the pool share, token amount, and staking period.',
    },
  },
  [Pools.TokenDrop]: {
    tableHeadData: [
      {
        id: 'poolName',
        label: 'Pool Name',
        type: 'default',
      },
      {
        id: 'apr',
        label: 'APR',
        type: 'sortable',
      },
      {
        id: 'stakingToken0Staked',
        label: 'Total Staked',
        type: 'sortable',
      },
      {
        id: 'stakingToken1Staked',
        label: 'Total Staked',
        type: 'sortable',
      },
      {
        id: 'status',
        label: 'Status',
        type: 'sortable',
      },
      {
        type: 'empty',
      },
    ],
    innerTableHeadData: [
      {
        id: 'rewardsInThePool',
        label: 'Rewards in the pool',
        width: '100%',
        fieldType: 'numberWithDecimals',
        fieldName: 'rewardsInThePool',
      },
      {
        id: 'startDate',
        label: 'Start date',
        width: '20%',
        fieldType: 'date',
        fieldName: 'startDate',
      },
      {
        id: 'endDate',
        label: 'End date',
        width: '20%',
        fieldType: 'date',
        fieldName: 'endDate',
      },
      {
        id: 'minimumStakingTime',
        label: 'Minimum staking time',
        width: '20%',
        fieldType: 'duration',
        fieldName: 'minimumStakingTime',
      },
      {
        id: 'earlyUnstakePenalty',
        label: 'Early unstake penalty',
        width: '20%',
        fieldType: 'percent',
        fieldName: 'earlyUnstakePenalty',
      },
      {
        id: 'poolAddress',
        label: 'Pool address ',
        width: '100%',
        fieldType: 'copyableAddress',
        fieldName: 'address',
      },
    ],
    info: {
      title: 'Stake Token 1 + Token 2 -> Receive Token 3',
      text: 'If you stake Token 1 along with Token 2 in a fixed ratio, you will receive a New Token as staking reward. APR is not static and depends on the pool share, token amount, and staking period.',
    },
  },
  [Pools.NftPool]: {
    tableHeadData: [
      {
        id: 'poolName',
        label: 'Pool Name',
        type: 'default',
      },
      {
        id: 'stakingToken0Staked',
        label: 'Total Staked',
        type: 'sortable',
      },
      {
        id: 'status',
        label: 'Status',
        type: 'sortable',
      },
      {
        type: 'empty',
      },
    ],
    innerTableHeadData: [
      {
        id: 'rewardsInPool',
        label: 'Rewards in the pool',
        width: '100%',
        fieldType: 'token',
        fieldName: 'rewardsInPool',
      },
      {
        id: 'startDate',
        label: 'Start date',
        width: '100%',
        fieldType: 'date',
        fieldName: 'startDate',
      },
      {
        id: 'endDate',
        label: 'End date',
        width: '20%',
        fieldType: 'date',
        fieldName: 'endDate',
      },
      {
        id: 'stakingTime',
        label: 'Staking time',
        width: '20%',
        fieldType: 'duration',
        fieldName: 'stakingTime',
      },
      {
        id: 'amountToStake',
        label: 'Amount to stake',
        width: '20%',
        fieldType: 'tokenToStake',
        fieldName: 'amountToStake',
      },
      {
        id: 'poolAddress',
        label: 'Pool address ',
        width: '100%',
        fieldType: 'copyableAddress',
        fieldName: 'address',
      },
    ],
    info: {
      title: 'Stake Token 1 + NFT -> Receive NFT',
      text: 'If you stake a fixed amount of Token 1 along with the mentioned NFT, you will receive a new NFT as staking reward.',
    },
  },
};
