import { Pools, BackendPools } from 'types';
import { SECONDS_IN_DAY } from 'appConstants';
import { ContractsNames } from 'config';
import { getTokenAmount } from 'utils';
import { mainPoolFormConfig } from './forms/MainPool.config';
import { mctPoolFormConfig } from './forms/MctPool.config';
import { miningPoolFormConfig } from './forms/MiningPool.config';
import { nftDropFormConfig } from './forms/NftDrop.config';
import { nftPoolFormConfig } from './forms/NftPool.config';
import { tokenDropFormConfig } from './forms/TokenDrop.config';

export const formConfig = {
  [Pools.MiningPool]: miningPoolFormConfig,
  [Pools.MctPool]: mctPoolFormConfig,
  [Pools.TokenDrop]: tokenDropFormConfig,
  [Pools.NftDrop]: nftDropFormConfig,
  [Pools.MainPool]: mainPoolFormConfig,
  [Pools.NftPool]: nftPoolFormConfig,
};

export const getPoolDataForCreation = (
  poolType: Pools,
  poolData,
  rewardTokenDecimals: number,
  stakingTokenDecimals: number,
  stakingToken2Decimals: number,
) => {
  const startDate = Math.floor(Date.parse(poolData.startDate) / 1000);
  const endDate = Math.floor(Date.parse(poolData.endDate) / 1000);
  const contractNameByPoolType = {
    [Pools.MctPool]: ContractsNames.mctPoolFactory,
    [Pools.MiningPool]: ContractsNames.stakingErc20Factory,
    [Pools.TokenDrop]: ContractsNames.stakingErc20Factory,
    [Pools.MainPool]: ContractsNames.stakingErc20Factory,
    [Pools.NftDrop]: ContractsNames.stakingErc1155Factory,
    [Pools.NftPool]: ContractsNames.stakingErc1155Factory,
  };

  const backendPoolNameByPoolType = {
    [Pools.MctPool]: BackendPools.Staking,
    [Pools.MiningPool]: BackendPools.Staking20Into20,
    [Pools.TokenDrop]: BackendPools.Staking20Plus20Into20,
    [Pools.MainPool]: BackendPools.Staking20Plus20Into20,
    [Pools.NftDrop]: BackendPools.Staking20Plus20Into1155,
    [Pools.NftPool]: BackendPools.Staking20Plus1155Into1155,
  };

  const createMethodNameByPoolType = {
    [Pools.MctPool]: 'createStaking',
    [Pools.MiningPool]: 'createStaking20Into20',
    [Pools.TokenDrop]: 'createStaking20Plus20Into20',
    [Pools.MainPool]: 'createStaking20Plus20Into20',
    [Pools.NftDrop]: 'createStaking20Plus20Into1155',
    [Pools.NftPool]: 'createStaking20Plus1155Into1155',
  };

  let poolDataForCreation = [];
  const contractName = contractNameByPoolType[poolType];
  const backendPoolName = backendPoolNameByPoolType[poolType];
  const createMethodName = createMethodNameByPoolType[poolType];

  switch (poolType) {
    case Pools.MctPool:
      poolDataForCreation = [
        poolData.apr,
        getTokenAmount(poolData.maximumGoalOfStaking, 18),
        startDate,
        endDate,
        poolData.earlyUnstakePenalty,
        getTokenAmount(poolData.maximumStakeAmountPerWallet, 18),
        poolData.poolName,
      ];
      break;

    case Pools.MiningPool:
      poolDataForCreation = [
        poolData.stakingTokenAddress,
        poolData.rewardTokenAddress,
        startDate,
        endDate,
        getTokenAmount(poolData.rewardsPerSecond, rewardTokenDecimals),
        +poolData.minimumStakingPeriod * SECONDS_IN_DAY,
        poolData.earlyUnstakePenalty * 100,
      ];
      break;

    case Pools.TokenDrop:
      poolDataForCreation = [
        [poolData.stakingTokenAddress, poolData.stakingToken2Address],
        poolData.rewardTokenAddress,
        [poolData.stakingTokenRate, poolData.stakingToken2Rate],
        startDate,
        endDate,
        getTokenAmount(poolData.rewardsPerSecond, rewardTokenDecimals),
        +poolData.minimumStakingPeriod * SECONDS_IN_DAY,
        +poolData.earlyUnstakePenalty * 100,
      ];
      break;

    case Pools.MainPool:
      poolDataForCreation = [
        [poolData.stakingTokenAddress, poolData.stakingToken2Address],
        [poolData.stakingTokenAddress, poolData.stakingToken2Address][poolData.rewardTokenIndex],
        [poolData.stakingTokenRate, poolData.stakingToken2Rate],
        startDate,
        endDate,
        getTokenAmount(poolData.rewardsPerSecond, rewardTokenDecimals),
        +poolData.minimumStakingPeriod * SECONDS_IN_DAY,
        +poolData.earlyUnstakePenalty * 100,
      ];

      break;

    case Pools.NftDrop:
      poolDataForCreation = [
        [poolData.stakingTokenAddress, poolData.stakingToken2Address],
        [
          getTokenAmount(poolData.stakingTokenAmount, stakingTokenDecimals),
          getTokenAmount(poolData.stakingToken2Amount, stakingToken2Decimals),
        ],
        poolData.rewardTokenAddress,
        poolData.rewardTokenId,
        poolData.rewardTokenAmount, // amount of nfts, decimals not need
        startDate,
        [poolData.entranceWindow * SECONDS_IN_DAY, poolData.stakingPeriod * SECONDS_IN_DAY],
      ];
      break;

    case Pools.NftPool:
      poolDataForCreation = [
        poolData.stakingTokenAddress,
        getTokenAmount(poolData.stakingTokenAmount, stakingTokenDecimals),
        poolData.stakingToken2Address,
        poolData.stakingToken2Id,
        poolData.rewardTokenAddress,
        poolData.rewardTokenId,
        poolData.rewardTokenAmount, // amount of nfts, decimals not need
        startDate,
        [poolData.entranceWindow * SECONDS_IN_DAY, poolData.stakingPeriod * SECONDS_IN_DAY],
      ];
      break;

    default:
      poolDataForCreation = [];
  }

  return {
    poolDataForCreation,
    contractName,
    backendPoolName,
    createMethodName,
  };
};
