import React from 'react';
import { routes } from 'appConstants';
import { Route, Routes, Navigate } from 'react-router-dom';
import { LaunchPool } from 'pages/LaunchPool';
import { Home } from 'pages/Home';

const RoutesContainer = () => (
  <Routes>
    <Route path={routes['launch-pool'].root} element={<LaunchPool />} />
    <Route path={routes.pools.root} element={<Home />} />
    <Route path="/" element={<Navigate replace to={routes.pools.default} />} />
    <Route path="*" element={<Navigate to={routes.root} />} />
  </Routes>
);

export default RoutesContainer;
