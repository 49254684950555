import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const TelegramIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="32" height="33" viewBox="0 0 32 33" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 16.925C32 25.761 24.836 32.925 16 32.925C7.164 32.925 0 25.761 0 16.925C0 8.08899 7.164 0.924988 16 0.924988C24.836 0.924988 32 8.08899 32 16.925ZM16.5733 12.737C15.0173 13.3837 11.9067 14.7237 7.24267 16.7557C6.48533 17.057 6.088 17.3517 6.052 17.6397C5.99067 18.1277 6.60133 18.3197 7.43067 18.5797C7.544 18.6157 7.66133 18.6517 7.78133 18.6917C8.59867 18.957 9.69733 19.2677 10.268 19.2797C10.7867 19.2903 11.3653 19.077 12.004 18.6397C16.3613 15.697 18.6107 14.2103 18.752 14.1783C18.852 14.1557 18.9907 14.1263 19.084 14.2103C19.1773 14.293 19.168 14.4503 19.1587 14.493C19.0973 14.7503 16.7053 16.9757 15.4653 18.1277C15.0787 18.4863 14.8053 18.741 14.7493 18.7997C14.624 18.929 14.496 19.053 14.3733 19.1717C13.6133 19.9023 13.0453 20.4517 14.4053 21.3477C15.0587 21.7783 15.5813 22.1343 16.1027 22.489C16.672 22.877 17.24 23.2637 17.976 23.7463C18.1627 23.869 18.3413 23.9957 18.516 24.1197C19.1787 24.593 19.7747 25.017 20.5107 24.9503C20.9373 24.9103 21.38 24.509 21.604 23.3103C22.1333 20.4757 23.176 14.337 23.4173 11.8063C23.432 11.5962 23.4231 11.3851 23.3907 11.177C23.3713 11.0089 23.2894 10.8542 23.1613 10.7437C22.9707 10.5877 22.6747 10.5543 22.5413 10.557C21.94 10.5677 21.0173 10.889 16.5733 12.737Z"
      fill="#A4F8DE"
    />
  </BaseSVGIcon>
);
