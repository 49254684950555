import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  VFC,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField, Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import stakesSelector from 'store/stakes/selectors';
import uiSelector from 'store/ui/selectors';
import stakesActionTypes from 'store/stakes/actionTypes';

import { Modal } from 'components/Modal';
import { getStatusIcon } from 'utils/getStatusIcon';
import { Status, IStakes, RequestStatus } from 'types';
import {
  formatLongAmount, getCurrentTimestampInSeconds, transformIntervalFromSeconds, validateOnlyNumbers,
} from 'utils';

import { useWalletConnectorContext } from 'services';

import {
  stakeToErc20PlusErc20Pool,
  getMainPoolSecondTokenAmount,
  unstakeFromErc20PlusErc20,
} from 'store/stakes/actions';
import { debounce } from 'lodash';
import BigNumber from 'bignumber.js';
import { updateStakes } from 'store/stakes/reducer';
import { PenaltyBox, TokenBox, useStakeUnstakeRadioGroup } from '../components';
import { useBaseStyles } from '../BaseStakeModal';
import { RadioStates } from '../BaseStakeModal/BaseStakeModal.types';

export interface MainPoolStakeModalProps {
  selectedStakeData: IStakes;
  onClose: () => void;
  open: boolean;
}

export const MainPoolStakeModal: VFC<MainPoolStakeModalProps> = ({
  onClose,
  open,
  selectedStakeData,
}) => {
  const {
    stakingToken0Address,
    stakingToken1Address,
    stakingToken0Logo,
    stakingToken1Logo,
    stakingToken0Symbol,
    stakingToken1Symbol,
    userStakedToken0Amount,
    userStakedToken1Amount,
    status,
    apr,
    rewardTokenLogo,
    rewardTokenSymbol,
    minimumStakingTime,
    earlyUnstakePenalty,
    poolAddress,
    id,
    stakingToken0Decimals,
    stakingToken1Decimals,
    pendingReward,
    poolType,
    endDate,
  } = selectedStakeData;

  const stakingToken0UserBalance = useShallowSelector(
    userSelector.getBalanceOfToken(stakingToken0Address),
  );
  const stakingToken1UserBalance = useShallowSelector(
    userSelector.getBalanceOfToken(stakingToken1Address),
  );

  const mainPoolSecondTokenAmount = useShallowSelector(
    stakesSelector.getProp('mainPoolSecondTokenAmount'),
  );
  const getSecondTokenAmountRequestStatus = useShallowSelector(
    uiSelector.getProp(stakesActionTypes.GET_MAIN_POOL_SECOND_TOKEN_AMOUNT),
  );

  const dispatch = useDispatch();

  const { walletService } = useWalletConnectorContext();

  const {
    StakeUnstakeRadioGroup, isStakeSelected, radioState, setRadioState,
  } =
    useStakeUnstakeRadioGroup();
  const stakeOrUnstakeText = isStakeSelected ? 'Stake' : 'Unstake + Claim';

  const isShowPenalty = useMemo(
    () => radioState === RadioStates.unstake &&
      selectedStakeData?.status.label === Status.active,
    [selectedStakeData, radioState],
  );

  const [token0Amount, setToken0Amount] = useState('');

  const getSecondTokenAmount = (value: string) => {
    dispatch(
      getMainPoolSecondTokenAmount({
        web3Provider: walletService.Web3(),
        stakingToken0AmountToStake: value,
        stakingToken0Decimals,
        stakingToken1Decimals,
        poolAddress,
      }),
    );
  };

  const debouncedGetSecondTokenAmount = useRef(
    debounce(getSecondTokenAmount, 500),
  ).current;

  const handleToken0AmountChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { value } = event.target;
    if (validateOnlyNumbers(value, +stakingToken0Decimals)) {
      setToken0Amount(value);
      debouncedGetSecondTokenAmount(value);
    }
  };

  const handleMaxClick = () => {
    const amount =
      radioState === RadioStates.stake
        ? stakingToken0UserBalance
        : userStakedToken0Amount;
    setToken0Amount(amount);
    debouncedGetSecondTokenAmount(amount);
  };

  const isStakeEnd = getCurrentTimestampInSeconds() > +endDate - +minimumStakingTime;

  const isSecondTokenAmountLoading = getSecondTokenAmountRequestStatus === RequestStatus.REQUEST;

  const isStakeAmountExceeded =
    new BigNumber(token0Amount).isGreaterThan(stakingToken0UserBalance) ||
    new BigNumber(mainPoolSecondTokenAmount).isGreaterThan(stakingToken1UserBalance) ||
    token0Amount === '';

  const isUnstakeAmountExceeded = new BigNumber(token0Amount).isGreaterThan(
    userStakedToken0Amount,
  );

  const isUnstakeAvailable = new BigNumber(userStakedToken0Amount).isGreaterThan(0);
  const isStakeBtnAvailable = !isStakeAmountExceeded && !isSecondTokenAmountLoading && !isStakeEnd;
  const isUnstakeBtnAvailable = !isUnstakeAmountExceeded && isUnstakeAvailable;

  const handleCloseModal = useCallback(() => {
    setRadioState(RadioStates.stake);
    onClose();
    dispatch(updateStakes({ mainPoolSecondTokenAmount: '' }));
  }, [setRadioState, onClose, dispatch]);

  const handleStakeClick = () => {
    dispatch(
      stakeToErc20PlusErc20Pool({
        stakingToken0AmountToStake: token0Amount,
        stakingToken1AmountToStake: mainPoolSecondTokenAmount,
        stakingToken0Address,
        stakingToken1Address,
        poolAddress,
        stakingToken0Decimals,
        id,
        web3Provider: walletService.Web3(),
        poolType,
      }),
    );
  };

  const handleUnstakeClick = () => {
    dispatch(
      unstakeFromErc20PlusErc20({
        web3Provider: walletService.Web3(),
        stakingToken0Decimals,
        stakingToken0AmountToUnstake: token0Amount,
        poolAddress,
        id,
        stakingToken0Address,
        stakingToken1Address,
        poolType,
      }),
    );
  };

  useEffect(() => {
    if (selectedStakeData?.status?.label === Status.completed) {
      setRadioState(RadioStates.unstake);
    }
  }, [selectedStakeData?.status?.label, setRadioState]);

  const baseStakeModalClasses = useBaseStyles();

  return (
    <Modal open={open} onClose={handleCloseModal} customTitle="Main Pool">
      <FormControl component="fieldset">
        {isUnstakeAvailable &&
          selectedStakeData.status.label !== Status.completed && (
            <StakeUnstakeRadioGroup />
        )}
        <TextField
          className={clsx(baseStakeModalClasses.textField)}
          value={token0Amount}
          label={stakingToken0Symbol}
          onChange={handleToken0AmountChange}
          InputProps={{
            endAdornment: (
              <>
                <TokenBox
                  className={clsx(
                    baseStakeModalClasses.textFieldToken,
                    baseStakeModalClasses.textFieldTokenWithBg,
                  )}
                  src={stakingToken0Logo}
                  text={stakingToken0Symbol}
                />
                <Button
                  onClick={handleMaxClick}
                  className={baseStakeModalClasses.maxBtn}
                  variant="contained"
                >
                  MAX
                </Button>
              </>
            ),
          }}
        />
        <TextField
          className={clsx(baseStakeModalClasses.textField)}
          value={mainPoolSecondTokenAmount}
          label={stakingToken1Symbol}
          disabled
          InputProps={{
            endAdornment: (
              <TokenBox
                className={clsx(
                  baseStakeModalClasses.textFieldToken,
                  baseStakeModalClasses.textFieldTokenWithBg,
                  baseStakeModalClasses.textFieldTokenWithoutMax,
                )}
                src={stakingToken1Logo}
                text={stakingToken1Symbol}
              />
            ),
          }}
        />

        <Grid container className={baseStakeModalClasses.info}>
          {isStakeSelected ? (
            <>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Reward token
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <TokenBox
                    className={baseStakeModalClasses.textFieldToken}
                    src={rewardTokenLogo}
                    text={rewardTokenSymbol}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Apr
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Tooltip title={`${apr}%`}>
                    <Typography>{formatLongAmount(apr)}%</Typography>
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Status
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  {getStatusIcon(status.label)}
                  <Typography className={baseStakeModalClasses.status}>
                    {status.label}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Staking time
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {transformIntervalFromSeconds(minimumStakingTime, 'Day')}{' '}
                    days
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Your {stakingToken0Symbol} balance
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {`${stakingToken0UserBalance} ${stakingToken0Symbol}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Your {stakingToken1Symbol} balance
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {`${stakingToken1UserBalance} ${stakingToken1Symbol}`}
                  </Typography>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Status
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  {getStatusIcon(status.label)}
                  <Typography className={baseStakeModalClasses.status}>
                    {status.label}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Apr
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Tooltip title={`${apr}%`}>
                    <Typography>{formatLongAmount(apr)}%</Typography>
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  You staked
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {`
                      ${userStakedToken0Amount}
                      ${stakingToken0Symbol}
                       +
                      ${userStakedToken1Amount}
                      ${stakingToken1Symbol}
                    `}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Your {rewardTokenSymbol} earned
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>{`${pendingReward} ${rewardTokenSymbol}`}</Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        {isShowPenalty && <PenaltyBox penalty={earlyUnstakePenalty} />}
        <Button
          className={baseStakeModalClasses.button}
          variant="outlined"
          color="secondary"
          onClick={isStakeSelected ? handleStakeClick : handleUnstakeClick}
          disabled={
            isStakeSelected ? !isStakeBtnAvailable : !isUnstakeBtnAvailable
          }
        >
          {stakeOrUnstakeText}
        </Button>
      </FormControl>
    </Modal>
  );
};
