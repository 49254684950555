import React, {
  useCallback, useEffect, useMemo, useState, VFC,
} from 'react';
import { useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';

import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { Modal } from 'components/Modal';
import { getStatusIcon } from 'utils/getStatusIcon';
import userSelector from 'store/user/selectors';
import {
  Status, TNullable, IStakes,
} from 'types';
import {
  validateOnlyNumbers,
} from 'utils';

import {
  stake as stakeAction,
  unstake as unstakeAction,
} from 'store/stakes/actions';
import { useWalletConnectorContext } from 'services';
import { useShallowSelector } from 'hooks';

import { PenaltyBox, TokenBox, useStakeUnstakeRadioGroup } from '../components';
import { mctToken, useBaseStyles } from '../BaseStakeModal';
import { RadioStates } from '../BaseStakeModal/BaseStakeModal.types';
import { useStyles } from './MctStakeModal.styles';

export interface IMctStakeModalProps {
  selectedStakeData: IStakes;
  onClose: () => void;
  open: boolean;
}

export const MctStakeModal: VFC<IMctStakeModalProps> = ({
  onClose,
  open,
  selectedStakeData,
}) => {
  const dispatch = useDispatch();

  const { walletService } = useWalletConnectorContext();
  const stakingToken0UserBalance = useShallowSelector(
    userSelector.getBalanceOfToken(selectedStakeData.stakingToken0Address),
  );
  const {
    StakeUnstakeRadioGroup, isStakeSelected, radioState, setRadioState,
  } =
    useStakeUnstakeRadioGroup();
  const stakeOrUnstakeText = isStakeSelected ? 'Stake' : 'Unstake + Claim';

  const [stakeAmount, setStakeAmount] = useState<TNullable<string>>();

  const handleStakeAmountChange = useCallback((event) => {
    const { value } = event.target;
    if (validateOnlyNumbers(value, mctToken.decimals)) {
      setStakeAmount(value);
    }
  }, []);

  const handleMaxClick = useCallback(() => {
    const { maxStakeAmount, userStakedToken0Amount } = selectedStakeData;
    if (+stakingToken0UserBalance > +maxStakeAmount) {
      const amount = new BigNumber(maxStakeAmount)
        .minus(userStakedToken0Amount)
        .toFixed(0);
      setStakeAmount(amount);
      return;
    }

    setStakeAmount(stakingToken0UserBalance);
  }, [stakingToken0UserBalance, selectedStakeData]);

  const isShowPenalty = useMemo(
    () => radioState === RadioStates.unstake &&
      selectedStakeData?.status.label === Status.active,
    [selectedStakeData, radioState],
  );

  const isUnstakeAvailable = useMemo(
    () => !!+selectedStakeData?.userStakedToken0Amount,
    [selectedStakeData],
  );

  const isStakeAmountExceedingLimit = useMemo(() => {
    const maxStakeAmount =
      +selectedStakeData?.maxStakeAmount -
      +selectedStakeData?.userStakedToken0Amount;
    return maxStakeAmount < +stakeAmount;
  }, [stakeAmount, selectedStakeData]);

  const isStakeBtnAvailable = useMemo(
    () => (isUnstakeAvailable && radioState === RadioStates.unstake) ||
      (+stakeAmount &&
        !isStakeAmountExceedingLimit &&
        +stakingToken0UserBalance >= +stakeAmount),
    // eslint-disable-next-line max-len
    [isUnstakeAvailable, radioState, stakeAmount, isStakeAmountExceedingLimit, stakingToken0UserBalance],
  );

  const clearInputs = () => {
    setStakeAmount('');
  };

  const handleCloseModal = useCallback(() => {
    setRadioState(RadioStates.stake);
    onClose();
    clearInputs();
  }, [onClose, setRadioState]);

  const handleStakeClick = useCallback(() => {
    const web3Provider = walletService.Web3();
    dispatch(
      stakeAction({
        amount: stakeAmount,
        stakingContractAddress: selectedStakeData.poolAddress,
        web3Provider,
        stakingToken0Address: selectedStakeData.stakingToken0Address,
        id: selectedStakeData.id,
      }),
    );
    clearInputs();
    // eslint-disable-next-line max-len
  }, [walletService, dispatch, stakeAmount, selectedStakeData.poolAddress, selectedStakeData.stakingToken0Address, selectedStakeData.id]);

  const handleUnstakeClick = useCallback(() => {
    const web3Provider = walletService.Web3();
    dispatch(
      unstakeAction({
        stakingContractAddress: selectedStakeData.poolAddress,
        web3Provider,
      }),
    );
    clearInputs();
  }, [dispatch, selectedStakeData.poolAddress, walletService]);

  useEffect(() => {
    if (selectedStakeData?.status?.label === Status.completed) {
      setRadioState(RadioStates.unstake);
    }
  }, [selectedStakeData?.status?.label, setRadioState]);

  const baseStakeModalClasses = useBaseStyles();
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      customTitle={(
        <TokenBox
          className={classes.headTitle}
          src={mctToken.image}
          text={mctToken.symbol}
          size="l"
        />
      )}
    >
      <FormControl component="fieldset">
        {isUnstakeAvailable &&
          selectedStakeData.status.label !== Status.completed && (
            <StakeUnstakeRadioGroup />
        )}

        {isStakeSelected && (
          <TextField
            value={stakeAmount || ''}
            label={mctToken.symbol}
            className={baseStakeModalClasses.textField}
            onChange={handleStakeAmountChange}
            InputProps={{
              endAdornment: (
                <>
                  <TokenBox
                    className={baseStakeModalClasses.textFieldToken}
                    src={mctToken.image}
                    text={mctToken.symbol}
                  />
                  <Button
                    onClick={handleMaxClick}
                    className={baseStakeModalClasses.maxBtn}
                    variant="contained"
                  >
                    MAX
                  </Button>
                </>
              ),
            }}
            error={isStakeAmountExceedingLimit}
          />
        )}

        <Grid container className={baseStakeModalClasses.info}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              Status
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              {getStatusIcon(selectedStakeData?.status.label)}
              <Typography className={baseStakeModalClasses.status}>
                {selectedStakeData?.status.label}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              Apr
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              <Typography>{selectedStakeData?.apr}%</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              {`Your ${mctToken.symbol} ${
                isStakeSelected ? 'balance' : 'staked'
              }`}
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              <Typography>
                {isStakeSelected
                  ? `${stakingToken0UserBalance} ${mctToken.symbol}`
                  : `${selectedStakeData?.userStakedToken0Amount} ${mctToken.symbol}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              {`Your ${
                isStakeSelected ? `${mctToken.symbol} staked` : 'rewards earned'
              }`}
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              <Typography>
                {isStakeSelected
                  ? `${selectedStakeData?.userStakedToken0Amount} ${mctToken.symbol}`
                  : `${selectedStakeData?.userRewardAmount} ${mctToken.symbol}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {isShowPenalty && (
          <PenaltyBox penalty={selectedStakeData.earlyUnstakePenalty} />
        )}
        <Button
          className={baseStakeModalClasses.button}
          variant="outlined"
          color="secondary"
          onClick={isStakeSelected ? handleStakeClick : handleUnstakeClick}
          disabled={!isStakeBtnAvailable}
        >
          {stakeOrUnstakeText}
        </Button>
      </FormControl>
    </Modal>
  );
};
