import {
  select, put, takeLatest, call, delay,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { mainPoolAbi } from 'config/abi';
import { getTokenAmount, setNotification } from 'utils';
import { getTokensBalanceSaga } from 'store/user/sagas/getTokensBalance';
import { BackendPools } from 'types';
import { DELAY_BEFORE_UPDATE } from 'config/constants';
import { stakeToErc20PlusErc20Pool } from '../actions';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';
import { approveSaga } from './approveSaga';
import { updatePoolSaga } from './updatePool';
import userActionTypes from '../../user/actionTypes';
import { getMainPoolsDataSaga } from './getMainPools';
import { getTokenDropsDataSaga } from './getTokenDrops';

export function* stakeToErc20PlusErc20PoolSaga({
  type,
  payload: {
    web3Provider,
    stakingToken0Address,
    stakingToken1Address,
    stakingToken0AmountToStake,
    stakingToken1AmountToStake,
    stakingToken0Decimals,
    poolAddress,
    id,
    poolType,
  },
}: ReturnType<typeof stakeToErc20PlusErc20Pool>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const mainPoolContract = yield new web3Provider.eth.Contract(
    mainPoolAbi,
    poolAddress,
  );

  try {
    yield* call(approveSaga, {
      type: actionTypes.APPROVE,
      payload: {
        web3Provider,
        spender: poolAddress,
        approveTokenAddress: stakingToken0Address,
        amount: stakingToken0AmountToStake,
      },
    });

    yield* call(approveSaga, {
      type: actionTypes.APPROVE,
      payload: {
        web3Provider,
        spender: poolAddress,
        approveTokenAddress: stakingToken1Address,
        amount: stakingToken1AmountToStake,
      },
    });

    const amountToStakeWithDecimals = getTokenAmount(
      stakingToken0AmountToStake,
      +stakingToken0Decimals,
    );

    yield* call(
      mainPoolContract.methods.deposit(amountToStakeWithDecimals).send,
      { from: myAddress },
    );

    yield call(updatePoolSaga, {
      type: actionTypes.UPDATE_POOl,
      payload: { id },
    });

    yield delay(DELAY_BEFORE_UPDATE);

    if(poolType === BackendPools.StakingMain) {
      yield call(getMainPoolsDataSaga, {
        type: actionTypes.GET_MAIN_POOLS_DATA,
        payload: { web3Provider },
      });
    } else {
      yield call(getTokenDropsDataSaga, {
        type: actionTypes.GET_TOKEN_DROPS_DATA,
        payload: { web3Provider },
      });
    }

    yield call(getTokensBalanceSaga, {
      type: userActionTypes.GET_TOKENS_BALANCE,
      payload: {
        web3Provider,
        tokensAddresses: [
          stakingToken0Address,
          stakingToken1Address,
        ],
      },
    });

    setNotification({ type: 'success', message: 'Successfully staked' });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.STAKE_TO_ERC20_PLUS_ERC20, stakeToErc20PlusErc20PoolSaga);
}
