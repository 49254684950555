import { Box, Typography } from '@material-ui/core';
import React, {
  VFC,
} from 'react';
import clsx from 'clsx';

import { useBaseStyles } from '../../BaseStakeModal';

type PenaltyBoxProps = {
  className?: string;
  penalty: string;
};

export const PenaltyBox: VFC<PenaltyBoxProps> = ({
  penalty,
  className,
}) => {
  const baseStakeModalClasses = useBaseStyles();

  return (
    <Box className={clsx(baseStakeModalClasses.subInfo, className)}>
      <Typography
        align="center"
        variant="body1"
        className={baseStakeModalClasses.subInfoTitle}
      >
        Early unstake penalty is
      </Typography>
      <Typography align="center">{penalty}%</Typography>
    </Box>
  );
};
