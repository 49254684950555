import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';

import { useBaseStyles } from '../../BaseStakeModal';
import { RadioStates } from '../../BaseStakeModal/BaseStakeModal.types';

export const useStakeUnstakeRadioGroup = () => {
  const [radioState, setRadioState] = useState<RadioStates>(RadioStates.stake);
  const isStakeSelected = radioState === RadioStates.stake;

  const radioHandler = useCallback((event) => {
    setRadioState(event.target.value);
  }, []);

  const baseStakeModalClasses = useBaseStyles();
  const StakeUnstakeRadioGroup = useMemo(() => () => (
    <RadioGroup
      className={baseStakeModalClasses.radioGroup}
      row
      value={radioState}
      onChange={radioHandler}
    >
      <FormControlLabel
        className={baseStakeModalClasses.radio}
        value="stake"
        control={<Radio color="default" />}
        label="Stake"
      />
      <FormControlLabel
        className={baseStakeModalClasses.radio}
        value="unstake"
        control={<Radio color="default" />}
        label="Unstake"
      />
    </RadioGroup>
  ), [baseStakeModalClasses.radio, baseStakeModalClasses.radioGroup, radioHandler, radioState]);

  return {
    StakeUnstakeRadioGroup,
    isStakeSelected,
    radioState,
    setRadioState,
  };
};
