/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@mui/material';
import { ArrowLeftIcon } from 'theme/icons';
import { COLOR_MINT_200 } from 'theme/colors';

// TABLE CONTAINER
export const getMuiTableContainer = (theme: Theme): Overrides['MuiTableContainer'] => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
  },
});

// TABLE SORT LABEL
export const getMuiTableSortLabel = (theme: Theme): Overrides['MuiTableSortLabel'] => ({
  icon: {
    opacity: 1,
  },
  iconDirectionDesc: {
    transform: 'rotate(90deg) !important',
  },
  iconDirectionAsc: {
    transform: 'rotate(-90deg) !important',
  },
});

export const getMuiTableSortLabelDefaultProps = (): ComponentsProps['MuiTableSortLabel'] => ({
  IconComponent: ArrowLeftIcon as any,
});

// TABLE CELL
export const getMuiTableCell = (theme: Theme): Overrides['MuiTableCell'] => ({
  root: {
    border: 'none !important',
  },
  sizeSmall: {
    width: 'fit-content',
    padding: theme.spacing(0.75, 1.5, 0.75, 1.5),
  },
});

// TABLE PAGINATION
export const getMuiTablePagination = (theme: Theme): Overrides['MuiTablePagination'] => ({
  caption: {
    ...theme.typography.body1,
    color: COLOR_MINT_200,
  },
});
