import { Pools } from 'types';

export const routes = {
  root: '/',
  title: 'Home',
  'launch-pool': {
    root: '/launch-pool/:poolType',
    link: (poolType: Pools) => `/launch-pool/${poolType}`,
    title: 'Launch a pool',
  },
  pools: {
    root: '/*',
    link: (slug?: Pools) => `/${slug || ''}`,
    title: 'Pools',
    isDynamic: true,
    default: '/MCTPool',
  },
};
