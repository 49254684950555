import React, { FC } from 'react';
import clsx from 'clsx';
import { Box, IconButton, Typography } from '@material-ui/core';
import { CloseIcon } from 'theme/icons';
import { toast } from 'react-toastify';
import { useStyles } from './Toastify.styles';
import { toastifyHelper } from './Toastify.helper';

export type ToastifyProps = {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
  className?: string;
};

export const Toastify:FC<ToastifyProps> = ({
  type,
  message,
  className,
}) => {
  const classes = useStyles();
  const toastId = React.useRef(null);

  const onClose = () => {
    toast.dismiss(toastId.current);
  };
  return (
    <Box className={clsx(classes.toastify, classes[type], className)}>
      {toastifyHelper[type]}
      <Typography className={classes[`text${type}`]} variant="body1">{message}</Typography>
      <IconButton color="secondary" className={clsx(classes.closeBtnContainer, classes[`icon${type}`])} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
