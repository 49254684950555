import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    content: {
      marginTop: theme.spacing(3),
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 10,
      [theme.breakpoints.down(500)]: {
        gridTemplateColumns: '1fr',
      },
    },
  });
});
