import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { FontWeights } from 'theme/Typography';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      ...flexHelper(),
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    imgBox: {
      '&.m': {
        width: 24,
        height: 24,
      },
      '&.l': {
        width: 48,
        height: 48,
      },
    },
    img: {
      borderRadius: '50%',
      '&.m': {
        width: 24,
        height: 24,
      },
      '&.l': {
        width: 48,
        height: 48,
      },
    },
    text: {
      fontWeight: FontWeights.Medium,

      '&.m': {},
      '&.l': {
        fontSize: 24,
        lineHeight: '34px',
      },
    },
  });
});
