import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
  },
  nftModalWrapper: {
    background: 'none',
    padding: theme.spacing(2.9),
    margin: theme.spacing(2.9),
    // [theme.breakpoints.down('xs')]: {
    //   marginLeft: 42,
    // },
  },
  contentWrapper: {
    alignItems: 'flex-start',
  },
  nftContent: {
    width: 512,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 288,
      width: '100%',
    },
  },
  nftImgWrapper: {
    width: '100%',
    borderRadius: 20,
    overflow: 'hidden',
  },
  nftImg: {
    width: '100%',
    marginRight: 8,
  },
  nftPreviewLink: {
    marginTop: theme.spacing(1.5),
    width: '100%',
  },
}));
