import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { COLOR_MINT_100, COLOR_MINT_300 } from '../colors';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiTab = (theme: Theme): Overrides['MuiTab'] => ({
  root: {
    maxWidth: 'fit-content',
    minWidth: 80,
    padding: theme.spacing(0, 0, 1, 0),
    fontSize: 16,
    lineHeight: 3,
    color: COLOR_MINT_100,
    whiteSpace: 'nowrap',
    '&:not(:last-child)': {
      marginRight: theme.spacing(6),
    },
    '&:hover': {
      color: COLOR_MINT_300,
    },
    '&$selected': {
      color: COLOR_MINT_300,
    },
    '&:focus': {
      color: COLOR_MINT_300,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 80,
    },
  },
});

export const getMuiTabDefaultProps = (): ComponentsProps['MuiTab'] => ({

});
