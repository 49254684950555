import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { flexHelper } from 'utils';
import { COLOR_MINT_300 } from '../colors';

export const getMuiTabs = (theme: Theme): Overrides['MuiTabs'] => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  scrollButtons: {
    with: 'auto',
    paddingBottom: theme.spacing(1),
  },
  scrollButtonsDesktop: {
    [theme.breakpoints.up('xs')]: {
      ...flexHelper(),
    },
  },
  indicator: {
    backgroundColor: COLOR_MINT_300,
  },
});

export const getMuiTabsDefaultProps = (): ComponentsProps['MuiTabs'] => ({
  centered: false,
  variant: 'scrollable',
  scrollButtons: 'auto',
});
