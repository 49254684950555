import { AbiItem } from 'web3-utils';

import erc20 from './erc20.json';
import factory from './factory.json';
import vault from './vault.json';
import staking from './staking.json';
import erc1155 from './erc1155.json';
import mctPoolFabric from './mctPoolFactory.json';
import stakingErc20Factory from './stakingErc20Factory.json';
import stakingErc1155Factory from './stakingErc1155Factory.json';
import miningPool from './miningPool.json';
import nftDrop from './nftDrop.json';
import mainPool from './mainPool.json';
import nftPool from './nftPool.json';
import tokenDrop from './tokenDrop.json';
import mctPool from './mctPool.json';

export const erc20Abi = erc20 as AbiItem[];
export const factoryAbi = factory as AbiItem[];
export const vaultAbi = vault as AbiItem[];
export const stakingAbi = staking as AbiItem[];
export const erc1155Abi = erc1155 as AbiItem[];
export const mctPoolFabricAbi = mctPoolFabric as AbiItem[];
export const stakingErc20FactoryAbi = stakingErc20Factory as AbiItem[];
export const stakingErc1155FactoryAbi = stakingErc1155Factory as AbiItem[];
export const miningPoolAbi = miningPool as AbiItem[];
export const nftDropAbi = nftDrop as AbiItem[];
export const mainPoolAbi = mainPool as AbiItem[];
export const nftPoolAbi = nftPool as AbiItem[];
export const tokenDropAbi = tokenDrop as AbiItem[];
export const mctPoolAbi = mctPool as AbiItem[];
