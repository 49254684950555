/* eslint-disable max-len */
import {
  select, put, call, takeLatest, all,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { getStatus } from 'containers/Pools/Pools.helper';
import { BackendPools } from 'types';
import { poolsApi } from 'services';
import userSelector from 'store/user/selectors';
import { erc20Abi, nftPoolAbi } from 'config/abi';
import { tokenLogoPlaceholder } from 'assets/images';
import { sortPools } from 'utils/sortPools';
import actionTypes from '../actionTypes';
import { updateStakes } from '../reducer';
import { getNftPoolsData } from '../actions';

export function* getNfrPoolsDataSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getNftPoolsData>) {
  yield put(apiActions.request(type));

  const { chain, address } = yield* select(userSelector.getUser);

  try {
    const { data: nftPools } = yield call(poolsApi.getPools, {
      network: chain,
      poolType: BackendPools.Staking20Plus1155Into1155,
    });

    const formattedNftPools = nftPools.map((stake) => ({
      name: stake.title,
      poolAddress: stake.address,
      description: stake.description,
      status: getStatus(+stake.open_time, +stake.staking_duration + +stake.open_time),
      startDate: stake.open_time,
      endDate: +stake.open_time + +stake.staking_duration, // TODO - change after backend fix
      stakingTime: +stake.staking_duration,
      entranceWindow: +stake.close_time,
      stakingToken0Staked: stake.total_stake_token[0],
      stakingToken0Logo: stake.token1_preview || tokenLogoPlaceholder,
      stakingToken0Address: stake.stake_token[0],
      stakingToken0AmountToStake: stake.stake_token_amount[0],
      stakingToken1Logo: stake.token2_preview || tokenLogoPlaceholder,
      stakingToken1Address: stake.stake_token[1],
      stakingToken1Id: stake.stake_token_id,
      rewardTokenAddress: stake.reward_token,
      rewardTokenId: stake.reward_token_id,
      rewardTokenLogo: stake.reward_preview || tokenLogoPlaceholder,
      network: stake.network,
      id: stake.id,
      poolType: stake.pool_type,
      txHash: stake.tx_hash,
      stakingToken0Link: stake.stake_token_urls.length > 0 && stake.stake_token_urls[0],
      stakingToken1Link: stake.stake_token_urls.length > 0 && stake.stake_token_urls[1],
    }));

    // stakingToken0 symbols
    const stakingTokens0SymbolsPromises = formattedNftPools.map(
      ({ stakingToken0Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken0Address).methods
        .symbol()
        .call(),
    );
    const stakingTokens0Symbols = yield* all(stakingTokens0SymbolsPromises);

    let isUserDepositedToPools = [];
    if (address) {
      // is user deposited to current pool
      const isUserDepositedToPoolsPromises = formattedNftPools.map(
        ({ poolAddress }) => new web3Provider.eth.Contract(nftPoolAbi, poolAddress).methods
          .deposited(address)
          .call(),
      );
      isUserDepositedToPools = yield all(isUserDepositedToPoolsPromises);
    }

    const nftPoolsWithDataFromBlockchain = formattedNftPools.map(
      (pool, index) => ({
        ...pool,
        stakingToken0Symbol: stakingTokens0Symbols[index],
        isUserDepositedToPool: isUserDepositedToPools[index],
      }),
    );

    const sortedPools = sortPools('status', 'desc', nftPoolsWithDataFromBlockchain);

    yield put(
      updateStakes({
        nftPoolStakes: sortedPools,
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_NFT_POOLS_DATA, getNfrPoolsDataSaga);
}
