import {
  select, put, takeLatest, call,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { erc1155Abi } from 'config/abi';
import { approveNfts } from '../actions';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';

export function* approveNftsSaga({
  type,
  payload: {
    web3Provider, operator, collectionAddress, isApprove,
  },
}: ReturnType<typeof approveNfts>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));

  try {
    // @ts-ignore wrong abi type
    const tokenContract = yield new web3Provider.eth.Contract(erc1155Abi, collectionAddress);
    const isApproved = yield call(tokenContract.methods.isApprovedForAll(myAddress, operator).call);

    if(!isApproved) {
      yield call(tokenContract.methods.setApprovalForAll(operator, isApprove).send, {
        from: myAddress,
      });
    }

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
    throw new Error('User denied approve for all NFTs');
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.APPROVE_NFTS, approveNftsSaga);
}
