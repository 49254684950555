import type { State, UserState } from 'types';

export default {
  getUser: (state: State): UserState => state.user,
  // eslint-disable-next-line max-len
  getProp: <T extends keyof UserState>(propKey: T) => (state: State) => state.user[propKey],
  getBalanceOfToken: (tokenAddress: string) => (state: State) => {
    if(!(tokenAddress in state.user.balances)) return '0';
    return state.user.balances[tokenAddress];
  },
};
