import React, { VFC } from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { Box, Button, Typography } from '@material-ui/core';
import { useStyles } from './DisconnectModal.styles';

interface DisconnectModalProps extends ModalProps {
  disconnectWallet?: () => void;
  text?: string;
  buttonText?: string;
}

export const DisconnectModal: VFC<DisconnectModalProps> = (props) => {
  const {
    open,
    onClose,
    disconnectWallet,
    text = 'Disable your wallet?',
    buttonText = 'Disconnect',
  } = props;
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.root} {...props}>
      <Box className={classes.icon}>
        <Typography className={classes.desc} variant="body1">{text}</Typography>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={disconnectWallet || onClose}
        >
          {buttonText}
        </Button>
      </Box>
    </Modal>
  );
};
