import React, { useEffect, VFC } from 'react';
import {
  Link, Route, Routes, useLocation,
} from 'react-router-dom';
import {
  Box,
  FormControlLabel,
  Hidden,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { SocialLinks, TotalEarned, DescriptionModal } from 'components';
import { readablePoolName } from 'utils';
import { useShallowSelector } from 'hooks';
import stakesSelector from 'store/stakes/selectors';
import userSelector from 'store/user/selectors';
import descriptionModalSelector from 'store/descriptionModal/selectors';
import { useDispatch } from 'react-redux';
import { updateIsOnlyMyPools } from 'store/stakes/reducer';
import { Pools } from 'types';
import { closeDescriptionModal } from 'store/descriptionModal/reducer';
import { getPoolsCount } from 'store/stakes/actions';
import { useStyles } from './Home.styles';
import { pools } from './Home.helper';

export const Home: VFC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { isOnlyMyPools, mctTotalUserRewards, poolsCount } = useShallowSelector(
    stakesSelector.getStakes,
  );
  const descriptionModalState = useShallowSelector(
    descriptionModalSelector.getDescriptionModal,
  );
  const chain = useShallowSelector(
    userSelector.getProp('chain'),
  );

  const handleChangeMyPools = () => {
    dispatch(updateIsOnlyMyPools(!isOnlyMyPools));
  };
  const handleCloseDescriptionModal = () => {
    dispatch(closeDescriptionModal());
  };

  const isMctPools = pathname.slice(1) === Pools.MctPool;

  useEffect(() => {
    dispatch(getPoolsCount({ chain }));
  }, [chain, dispatch]);

  return (
    <Box className={classes.root}>
      <DescriptionModal
        open={descriptionModalState.isDescriptionModalOpen}
        onClose={handleCloseDescriptionModal}
      />
      <Box className={classes.tableWrapper}>
        <Box className={classes.tableTitleBlock}>
          <Box className={classes.spinnerContainer}>
            <Typography variant="h2">Pools</Typography>
          </Box>
          <Hidden smDown implementation="js">
            {isMctPools && <TotalEarned amount={mctTotalUserRewards} />}
            <FormControlLabel
              value="start"
              control={(
                <Switch
                  checked={isOnlyMyPools}
                  onChange={handleChangeMyPools}
                />
              )}
              label={<Typography variant="body1">My pools</Typography>}
              labelPlacement="start"
            />
          </Hidden>
        </Box>
        <Box className={classes.tabsWrapper}>
          <Tabs value={pathname}>
            {pools.map(({ title, poolType }) => (
              <Tab
                key={title}
                label={(
                  <Box className={classes.tabLabel}>
                    <Box component="span">{readablePoolName(title)}</Box>
                    <Box className={classes.tabBox}>{poolsCount[poolType]}</Box>
                  </Box>
                )}
                value={`/${title}`}
                component={Link}
                to={`/${title}`}
              />
            ))}
          </Tabs>
        </Box>
        <Routes>
          {pools.map(({ title, component }) => (
            <Route key={title} path={`/${title}`} element={component} />
          ))}
        </Routes>
      </Box>
      <SocialLinks />
    </Box>
  );
};
