import React, { VFC } from 'react';

import { Box, Button } from '@material-ui/core';
import { Modal, ModalProps } from 'components/Modal';

import { routes } from 'appConstants';
import { Pools } from 'types';
import { useNavigate } from 'react-router-dom';
import { readablePoolName } from 'utils';
import { useStyles } from './LaunchPoolModal.styles';

export type LaunchPoolModalProps = ModalProps;

export const LaunchPoolModal: VFC<LaunchPoolModalProps> = ({ open, onClose, ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleOpenLaunchPoolPage = (poolType: Pools) => {
    navigate(routes['launch-pool'].link(poolType));
    onClose();
  };

  return (
    <Modal
      customTitle="Choose a pool"
      open={open}
      onClose={onClose}
      className={classes.root}
      {...props}
    >
      <Box className={classes.content}>
        {Object.values(Pools).map((poolName) => (
          <Button
            key={poolName}
            color="primary"
            variant="outlined"
            size="large"
            onClick={() => handleOpenLaunchPoolPage(poolName)}
          >
            {readablePoolName(poolName)}
          </Button>
        ))}
      </Box>
    </Modal>
  );
};
