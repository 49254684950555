import React from 'react';
import {
  MainPool,
  MctPool,
  MiningPool,
  NftDrop,
  NftPool,
  TokenDrop,
} from 'containers/Pools';
import { BackendPools, Pools } from 'types';

export const pools = [
  {
    title: Pools.MctPool,
    component: <MctPool />,
    poolType: BackendPools.Staking,
  },
  {
    title: Pools.MiningPool,
    component: <MiningPool />,
    poolType: BackendPools.Staking20Into20,
  },
  {
    title: Pools.NftDrop,
    component: <NftDrop />,
    poolType: BackendPools.Staking20Plus20Into1155,
  },
  {
    title: Pools.MainPool,
    component: <MainPool />,
    poolType: BackendPools.StakingMain,
  },
  {
    title: Pools.TokenDrop,
    component: <TokenDrop />,
    poolType: BackendPools.Staking20Plus20Into20,
  },
  {
    title: Pools.NftPool,
    component: <NftPool />,
    poolType: BackendPools.Staking20Plus1155Into1155,
  },
];
