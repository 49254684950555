import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BG_DARK_5, COLOR_MINT_600 } from 'theme/colors';
import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      padding: theme.spacing(10, 3, 16, 3),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(0),
      },
    },
    tableWrapper: {
      maxWidth: 1040,
      border: 'none',
      // background: 'transparent',
      // backdropFilter: 'blur(30px)',
      margin: theme.spacing(0, -3),
      [formatMedia.BREAKPOINT_DESKTOP]: {
        margin: '0 auto',
        border: `1px solid ${COLOR_MINT_600}`,
        borderRadius: theme.spacing(2.5),
        background: COLOR_BG_DARK_5,
      },
    },
    tableTitleBlock: {
      ...flexHelper('center'),
      padding: theme.spacing(2, 4),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 4, 0),
      },
    },
    tableContainer: {
      padding: theme.spacing(2, 4, 0, 4),
    },
    spinnerContainer: {
      position: 'relative',
      marginRight: 'auto',
    },
    spinner: {
      transform: 'scale(0.3)',
      position: 'absolute',
      right: -75,
      top: 0,
    },
    tabsWrapper: {
      borderBottom: `1px solid ${COLOR_MINT_600}`,
      marginTop: theme.spacing(2),
    },
    tabLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    tabBox: {
      background: 'rgba(147, 223, 200, 0.15)',
      borderRadius: 4,
      height: 24,
      width: 24,
      fontSize: 12,
      ...flexHelper(),
      marginLeft: theme.spacing(1),
    },
  });
});
