import { SelectValue } from 'types/components';

export const mobileSortingMenu: SelectValue[] = [
  {
    value: 'stakingToken0Staked*desc',
    label: 'Total Staked High',
  },
  {
    value: 'stakingToken0Staked*asc',
    label: 'Total Staked Low',
  },
  {
    value: 'status*asc',
    label: 'Status Completed',
  },
  {
    value: 'status*desc',
    label: 'Status Act/Upcoming',
  },
];
