/* eslint-disable @typescript-eslint/no-unused-vars */
import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

export const getMuiAccordion = (theme: Theme): Overrides['MuiAccordion'] => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
  },
});

export const getMuiAccordionDetails = (theme: Theme): Overrides['MuiAccordionDetails'] => ({
  root: {
    padding: 0,
  },
});
