import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BackendPools, StakesState, UpdatePoolInfoByAddressAction } from 'types';

const poolFieldNameByHisType = {
  [BackendPools.Staking]: 'mctPoolStakes' as const,
  [BackendPools.Staking20Into20]: 'miningPoolStakes' as const,
  [BackendPools.Staking20Plus20Into20]: 'tokenDropStakes' as const,
  [BackendPools.StakingMain]: 'mainPoolStakes' as const,
  [BackendPools.Staking20Plus1155Into1155]: 'nftPoolStakes' as const,
  [BackendPools.Staking20Plus20Into1155]: 'nftDropStakes' as const,
};

const initialState: StakesState = {
  miningPoolStakes: [],
  nftDropStakes: [],
  mainPoolStakes: [],
  nftPoolStakes: [],
  tokenDropStakes: [],
  mctPoolStakes: [],
  mctTotalUserRewards: 0,
  mainPoolSecondTokenAmount: '',
  isOnlyMyPools: false,
  poolsCount: {
    [BackendPools.Staking]: 0,
    [BackendPools.Staking20Into20]: 0,
    [BackendPools.Staking20Plus1155Into1155]: 0,
    [BackendPools.Staking20Plus20Into1155]: 0,
    [BackendPools.Staking20Plus20Into20]: 0,
    [BackendPools.StakingMain]: 0,
  },
};

export const stakesReducer = createSlice({
  name: 'stakes',
  initialState,
  reducers: {
    updateStakes: (state, action: PayloadAction<Partial<StakesState>>) => ({
      ...state,
      ...action.payload,
    }),
    updateIsOnlyMyPools: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOnlyMyPools: action.payload,
    }),
    updatePoolInfoByAddress: (
      state,
      action: PayloadAction<UpdatePoolInfoByAddressAction>,
    ) => {
      const fieldName = poolFieldNameByHisType[action.payload.poolType];
      const newPools = state[fieldName].map((pool) => {
        if(pool.poolAddress !== action.payload.address) return pool;
        return { ...pool, ...action.payload.newPoolData };
      });

      return {
        ...state,
        [fieldName]: newPools,
      };
    },
  },
});

export const { updateStakes, updateIsOnlyMyPools, updatePoolInfoByAddress } = stakesReducer.actions;

export default stakesReducer.reducer;
