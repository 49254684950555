import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { getFormatMedia } from 'theme/utils';

import { flexHelper } from 'utils';
import { COLOR_MINT_200, COLOR_MINT_300 } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      ...flexHelper('flex-end'),
      margin: theme.spacing(5.5, 'auto', 0),
      gap: theme.spacing(3),
      flexDirection: 'column-reverse',
      [formatMedia.BREAKPOINT_DESKTOP]: {
        flexDirection: 'row',
        gap: theme.spacing(1.5),
        margin: theme.spacing(2, 'auto', 0),
        maxWidth: 1040,
        padding: theme.spacing(1.5, 0.5),
      },
    },
    outerPages: {
      ...flexHelper(),
      gap: '3px',
      [formatMedia.BREAKPOINT_DESKTOP]: {
        gap: theme.spacing(1),
      },
    },
    outerPage: {
      '&>*': {
        color: COLOR_MINT_200,
      },
      '&:hover': {
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        '&>*': {
          color: `${COLOR_MINT_300} !important`,
          textDecoration: 'underline !important',
        },
      },
    },
    outerPagesLast: {
      display: 'none',
      [formatMedia.BREAKPOINT_DESKTOP]: {
        display: 'block',
      },
    },
    socialLinks: {
      ...flexHelper(),
      gap: theme.spacing(2.25),
      [formatMedia.BREAKPOINT_DESKTOP]: {
        gap: theme.spacing(2),
        marginLeft: theme.spacing(1.5),
      },
    },
    socialLink: {
      '&:hover, &:active': {
        background: 'none',
        '& > *': {
          '& > *': {
            '& > *': {
              fill: COLOR_MINT_200,
            },
          },
        },
      },
    },
  });
});
