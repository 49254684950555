import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const UpcomingIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.7998 9.30001H10.6998V7.2C10.6998 6.8129 10.3862 6.5 9.99981 6.5C9.61341 6.5 9.2998 6.8129 9.2998 7.2V10C9.2998 10.3871 9.61341 10.7 9.99981 10.7H12.7998C13.1869 10.7 13.4998 10.3871 13.4998 10C13.4998 9.61291 13.1869 9.30001 12.7998 9.30001ZM9.99992 15.5999C6.91221 15.5999 4.3999 13.0876 4.3999 9.99992C4.3999 6.91221 6.91221 4.3999 9.99992 4.3999C13.0876 4.3999 15.5999 6.91221 15.5999 9.99992C15.5999 13.0876 13.0876 15.5999 9.99992 15.5999ZM10 3C6.14021 3 3 6.14021 3 10C3 13.8598 6.14021 17 10 17C13.8598 17 17 13.8598 17 10C17 6.14021 13.8598 3 10 3Z" fill="#FF511B" />
  </BaseSVGIcon>
);
