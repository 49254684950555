import React, {
  FC, useEffect, useCallback, useState,
  useMemo,
} from 'react';
import clsx from 'clsx';

import {
  Hidden,
  Button,
  Box,
  TextField,
  Typography,
  MenuItem,
  Switch,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getMainPoolsData } from 'store/stakes/actions';
import { useWalletConnectorContext } from 'services';
import { usePoolsSorting, useShallowSelector } from 'hooks';
import userSelector from 'store/user/selectors';
import stakesSelector from 'store/stakes/selectors';
import { Pools } from 'types';
import { useLocation } from 'react-router-dom';
import { getTokensBalance } from 'store/user/actions';
import { useStyles } from '../Pools.styles';
import { CustomTable } from '../CustomTable';
import { ROWS_PER_PAGE } from '../Pools.helper';
import { MainPoolCardMobile } from './components/MainPoolCardMobile';
import { MainPoolStakeModal } from '../StakeModals';
import { mobileSortingMenu } from './MainPool.helpers';
import { MobileInfo } from '../MobileInfo';

export const MainPool: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { web3WithoutMetamask, walletService } = useWalletConnectorContext();
  const { chain, address } = useShallowSelector(userSelector.getUser);
  const mainPoolStakes = useShallowSelector(
    stakesSelector.getProp('mainPoolStakes'),
  );

  const { pathname } = useLocation();

  const {
    handleSort,
    handleMobileSort,
    handleMyPoolsFilter,
    handleChangePage,
    tableData,
    selectedMobileSorting,
    orderBy,
    order,
    isOnlyMyPools,
    page,
  } = usePoolsSorting(mainPoolStakes, mobileSortingMenu[0].value);

  const [activeStakeId, setActiveStakeId] = useState(null);
  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false);

  const isMoreStakesToShow = (page + 1) * ROWS_PER_PAGE < mainPoolStakes.length;

  const handleStakeClick = useCallback((stakeId: number) => {
    setActiveStakeId(stakeId);
    const stakeData = mainPoolStakes.find((stake) => stake.id === stakeId);
    dispatch(
      getTokensBalance({
        web3Provider: walletService.Web3(),
        tokensAddresses: [
          stakeData.stakingToken0Address,
          stakeData.stakingToken1Address,
        ],
      }),
    );
    setIsStakeModalOpen(true);
  }, [dispatch, mainPoolStakes, walletService]);

  const activeStakeData = useMemo(
    () => mainPoolStakes.find((stakeData) => stakeData.id === activeStakeId),
    [activeStakeId, mainPoolStakes],
  );

  useEffect(() => {
    dispatch(getMainPoolsData({ web3Provider: web3WithoutMetamask(chain) }));
  }, [dispatch, web3WithoutMetamask, chain, address]);

  return (
    <>
      {isStakeModalOpen && (
        <MainPoolStakeModal
          selectedStakeData={activeStakeData}
          open={isStakeModalOpen}
          onClose={() => setIsStakeModalOpen(false)}
        />
      )}
      <Hidden smDown implementation="css">
        <CustomTable
          className={classes.stakesTable}
          poolType={pathname.replace('/', '') as Pools}
          orderBy={orderBy}
          order={order}
          handleSort={handleSort}
          tableData={tableData}
          page={page}
          handleChangePage={handleChangePage}
          onStakeClick={handleStakeClick}
        />
      </Hidden>
      <Hidden mdUp implementation="css">
        <Box className={classes.stakesMobileContainer}>
          <Box className={classes.mobileSorting}>
            <TextField
              value={selectedMobileSorting}
              onChange={handleMobileSort}
              select
              className={clsx(classes.mobileSortingSelect, 'noLabel')}
              variant="filled"
            >
              {mobileSortingMenu.map(({ value, label }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <Box className={classes.myPoolsSwitch}>
              <Typography>My pools</Typography>
              <Box>
                <Switch
                  checked={isOnlyMyPools}
                  onChange={handleMyPoolsFilter}
                />
              </Box>
            </Box>
          </Box>
          <MobileInfo poolType={'MainPool' as Pools} />
          {tableData
            .slice(0, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
            .map((poolData) => (
              <MainPoolCardMobile
                key={poolData.poolAddress}
                onStakeClick={handleStakeClick}
                poolData={poolData}
              />
            ))}
        </Box>
        {isMoreStakesToShow && (
          <Box className={classes.center}>
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              onClick={(e) => handleChangePage(e, page + 1)}
            >
              Load more
            </Button>
          </Box>
        )}
      </Hidden>
    </>
  );
};
