import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const BinanceSmartChainIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.92568 3.05005L3.88002 6.10015L5.01357 7.23413L6.14712 8.36811L8.07356 6.43973L10 4.51135L11.9264 6.43973L13.8529 8.36811L14.9864 7.23413L16.12 6.10015L13.0743 3.05005C11.3992 1.37251 10.0158 0 10 0C9.98419 0 8.60076 1.37251 6.92568 3.05005ZM1.10386 8.87961L0 9.98601L1.10386 11.0924C1.71095 11.7009 2.23367 12.1988 2.26546 12.1988C2.29724 12.1988 2.81996 11.7009 3.42705 11.0924L4.53091 9.98601L3.42705 8.87961C2.81996 8.27109 2.29724 7.7732 2.26546 7.7732C2.23367 7.7732 1.71095 8.27109 1.10386 8.87961ZM8.86656 8.87978L7.76254 9.98635L8.88124 11.1066L10 12.2269L11.1188 11.1066L12.2375 9.98635L11.1334 8.87978C10.5262 8.27114 10.0162 7.7732 10 7.7732C9.9838 7.7732 9.47377 8.27114 8.86656 8.87978ZM16.5729 8.87961L15.4691 9.98601L16.5729 11.0924C17.18 11.7009 17.7028 12.1988 17.7345 12.1988C17.7663 12.1988 18.2891 11.7009 18.8961 11.0924L20 9.98601L18.8961 8.87961C18.2891 8.27109 17.7663 7.7732 17.7345 7.7732C17.7028 7.7732 17.18 8.27109 16.5729 8.87961ZM4.99907 12.7524L3.8807 13.8734L6.94035 16.9367L10 20L13.0598 16.9365L16.1196 13.873L14.9864 12.7383L13.8532 11.6036L11.9266 13.5321L10 15.4607L8.08733 13.546C7.03532 12.493 6.16174 11.6314 6.14604 11.6314C6.13035 11.6314 5.6142 12.1358 4.99907 12.7524Z" fill="#FFC90B" />
  </BaseSVGIcon>
);
