import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const EditIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.0182 10.6787L13.3232 7.98367L15.2712 6.03467L17.9652 8.72867L16.0182 10.6787ZM9.07954 17.6247L6.10254 17.8957L6.36654 14.9397L11.9835 9.32269L14.6795 12.0187L9.07954 17.6247ZM19.4034 7.33767L19.4024 7.33667L16.6644 4.59867C15.9234 3.85967 14.6504 3.82467 13.9484 4.52967L4.95241 13.5257C4.62641 13.8507 4.42441 14.2827 4.38241 14.7397L4.00341 18.9097C3.97741 19.2047 4.08241 19.4967 4.29241 19.7067C4.48141 19.8957 4.73641 19.9997 4.99941 19.9997C5.03041 19.9997 5.06041 19.9987 5.09041 19.9957L9.26041 19.6167C9.71841 19.5747 10.1494 19.3737 10.4744 19.0487L19.4714 10.0517C20.1994 9.32167 20.1684 8.10367 19.4034 7.33767Z" fill="#CFFFF1" />
    <mask id="mask0_1008_2609" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="4" width="17" height="16">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0182 10.6787L13.3232 7.98367L15.2712 6.03467L17.9652 8.72867L16.0182 10.6787ZM9.07954 17.6247L6.10254 17.8957L6.36654 14.9397L11.9835 9.32269L14.6795 12.0187L9.07954 17.6247ZM19.4034 7.33767L19.4024 7.33667L16.6644 4.59867C15.9234 3.85967 14.6504 3.82467 13.9484 4.52967L4.95241 13.5257C4.62641 13.8507 4.42441 14.2827 4.38241 14.7397L4.00341 18.9097C3.97741 19.2047 4.08241 19.4967 4.29241 19.7067C4.48141 19.8957 4.73641 19.9997 4.99941 19.9997C5.03041 19.9997 5.06041 19.9987 5.09041 19.9957L9.26041 19.6167C9.71841 19.5747 10.1494 19.3737 10.4744 19.0487L19.4714 10.0517C20.1994 9.32167 20.1684 8.10367 19.4034 7.33767Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1008_2609)" />
  </BaseSVGIcon>
);
