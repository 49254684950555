import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const HelpIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.8332 10.6974V11.6666C10.8332 12.1274 10.4607 12.4999 9.99984 12.4999C9.539 12.4999 9.1665 12.1274 9.1665 11.6666V9.99991C9.1665 9.53908 9.539 9.16658 9.99984 9.16658C10.689 9.16658 11.2498 8.60575 11.2498 7.91658C11.2498 7.22741 10.689 6.66658 9.99984 6.66658C9.31067 6.66658 8.74984 7.22741 8.74984 7.91658C8.74984 8.37741 8.37734 8.74991 7.9165 8.74991C7.45567 8.74991 7.08317 8.37741 7.08317 7.91658C7.08317 6.30825 8.3915 4.99991 9.99984 4.99991C11.6082 4.99991 12.9165 6.30825 12.9165 7.91658C12.9165 9.23325 12.0332 10.3357 10.8332 10.6974ZM9.99984 14.9999C9.539 14.9999 9.1665 14.6274 9.1665 14.1666C9.1665 13.7057 9.539 13.3332 9.99984 13.3332C10.4607 13.3332 10.8332 13.7057 10.8332 14.1666C10.8332 14.6274 10.4607 14.9999 9.99984 14.9999ZM9.99984 1.66658C5.40484 1.66658 1.6665 5.40491 1.6665 9.99991C1.6665 14.5949 5.40484 18.3332 9.99984 18.3332C14.5948 18.3332 18.3332 14.5949 18.3332 9.99991C18.3332 5.40491 14.5948 1.66658 9.99984 1.66658Z" fill="#324F46" />
    <mask id="mask0_963_5142" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="18" height="18">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.8332 10.6974V11.6666C10.8332 12.1274 10.4607 12.4999 9.99984 12.4999C9.539 12.4999 9.1665 12.1274 9.1665 11.6666V9.99991C9.1665 9.53908 9.539 9.16658 9.99984 9.16658C10.689 9.16658 11.2498 8.60575 11.2498 7.91658C11.2498 7.22741 10.689 6.66658 9.99984 6.66658C9.31067 6.66658 8.74984 7.22741 8.74984 7.91658C8.74984 8.37741 8.37734 8.74991 7.9165 8.74991C7.45567 8.74991 7.08317 8.37741 7.08317 7.91658C7.08317 6.30825 8.3915 4.99991 9.99984 4.99991C11.6082 4.99991 12.9165 6.30825 12.9165 7.91658C12.9165 9.23325 12.0332 10.3357 10.8332 10.6974ZM9.99984 14.9999C9.539 14.9999 9.1665 14.6274 9.1665 14.1666C9.1665 13.7057 9.539 13.3332 9.99984 13.3332C10.4607 13.3332 10.8332 13.7057 10.8332 14.1666C10.8332 14.6274 10.4607 14.9999 9.99984 14.9999ZM9.99984 1.66658C5.40484 1.66658 1.6665 5.40491 1.6665 9.99991C1.6665 14.5949 5.40484 18.3332 9.99984 18.3332C14.5948 18.3332 18.3332 14.5949 18.3332 9.99991C18.3332 5.40491 14.5948 1.66658 9.99984 1.66658Z" fill="white" />
    </mask>
    <g mask="url(#mask0_963_5142)" />
  </BaseSVGIcon>

);
