import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { COLOR_MINT_600 } from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles(() => createStyles({
  root: {
    height: '100vh',
    ...flexHelper(),
  },
  logoContainer: {
    position: 'relative',
  },
  mainLoaderText: {
    fontSize: '70px',
    lineHeight: '85px',
    color: COLOR_MINT_600,
    position: 'absolute',
    right: -30,
    bottom: -40,
  },
}));
