/* eslint-disable react/no-array-index-key */
import React, { SyntheticEvent, useState, VFC } from 'react';

import {
  Box,
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  Table,
  TablePagination,
  Typography,
  TableSortLabel, Button, Collapse,
} from '@material-ui/core';

import clsx from 'clsx';
import { IStakes, Pools, Stake } from 'types';
import { CloseIcon, InfoIcon } from 'theme/icons';
import { useStyles } from './CustomTable.styles';
import { CustomTableRow } from './components/TableRow';
import { Order, poolsTableHeadData, ROWS_PER_PAGE } from '../Pools.helper';

export interface TableProps {
  poolType: Pools;
  orderBy: string;
  order: Order;
  handleSort: (property: string) => void;
  tableData: IStakes[] | Stake[];
  page: number;
  handleChangePage: (e: SyntheticEvent, page: number) => void;
  className?: string;
  onStakeClick: (stakedId: number) => void;
}

export const CustomTable: VFC<TableProps> = ({
  poolType,
  orderBy,
  order,
  handleSort,
  tableData,
  page,
  handleChangePage,
  className,
  onStakeClick,
}) => {
  const classes = useStyles();
  const [showInfo, setShowInfo] = useState(false);

  const toggleShowInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Collapse collapsedHeight={0} in={showInfo}>
          <Box className={classes.infoText}>
            {poolsTableHeadData[poolType].info.title !== '' && (
              <Typography variant="body2" className={classes.infoTextTitle}>
                <strong>{poolsTableHeadData[poolType].info.title}</strong>
              </Typography>
            )}
            <Typography variant="body2">{poolsTableHeadData[poolType].info.text}</Typography>
            <Button variant="contained" className={classes.closeInfoBtn} onClick={() => setShowInfo(false)} endIcon={<CloseIcon />} />
          </Box>
        </Collapse>
        <Table aria-label="collapsible table">
          <TableBody>
            <TableRow>
              {poolsTableHeadData[poolType].tableHeadData.map(
                ({ label, type, id }) => (
                  <TableCell
                    key={`${label}-${id}`}
                    align="left"
                    sortDirection={orderBy === id ? order : false}
                  >
                    {type === 'sortable' && (
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : 'asc'}
                        onClick={() => handleSort(id)}
                        hideSortIcon={false}
                      >
                        <Typography variant="body1" className="s mint">
                          {label}
                        </Typography>
                      </TableSortLabel>
                    )}
                    {type === 'default' && (
                      <Typography variant="body1" className="s mint">
                        {label}
                      </Typography>
                    )}
                    {type === 'empty' && (
                      <Box className={classes.infoBtnWrapper}>
                        <Button variant="contained" className={classes.infoBtn} endIcon={<InfoIcon />} onClick={toggleShowInfo}>Info</Button>
                      </Box>
                    )}
                  </TableCell>
                ),
              )}
            </TableRow>

            {tableData
              .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
              .map((rowData) => (
                <CustomTableRow
                  key={rowData.poolAddress}
                  rowData={rowData}
                  innerTableHeadData={
                    poolsTableHeadData[poolType].innerTableHeadData
                  }
                  onStakeClick={onStakeClick}
                  poolType={poolType}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={tableData.length}
        rowsPerPage={ROWS_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
        className={classes.paginationContainer}
        nextIconButtonProps={{
          color: 'secondary',
        }}
        backIconButtonProps={{
          color: 'secondary',
        }}
      />
    </Box>
  );
};
