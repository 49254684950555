import { AbiItem } from 'web3-utils';
import { INetwork, IProvider, ISettings } from '@amfi/connect-wallet/dist/interface';

import { ContractsNames } from 'config';

export interface IConnectWallet {
  network: INetwork;
  provider: {
    [index: string]: IProvider;
  };
  settings: ISettings;
}

export interface IChainConfig {
  name: string;
  id: number;
  rpc: string;
  tx: {
    link: string;
  };
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExp: string;
}

export type TChainType = 'mainnet' | 'testnet';

export enum WalletProviders {
  'MetaMask' = 'MetaMask',
  'WalletConnect' = 'WalletConnect',
  'Onto' = 'Onto',
}

export enum Chains {
  ethereum = 'Ethereum',
  bsc = 'Binance-Smart-Chain',
}

export const CHAIN_IDS = {
  [Chains.bsc]: 56,
  [Chains.ethereum]: 1,
};

export interface IContracts {
  decimals: number;
  names: string[];
  contracts: {
    [contractName in ContractsNames]: {
      [chainType in TChainType]: {
        address?: {
          [chain in Chains]: string;
        };
        abi: AbiItem[];
      };
    };
  };
}
