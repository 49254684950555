import React from 'react';

import { COLOR_MINT_600 } from 'theme/colors';
import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const CopyIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="24" height="24" fill={COLOR_MINT_600} viewBox="0 0 24 18" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.09889 13V12C9.09889 10.346 10.4597 9 12.1319 9H13.1428V5.667C13.1428 5.299 12.8406 5 12.4685 5H5.72927C5.35722 5 5.05494 5.299 5.05494 5.667V12.333C5.05494 12.701 5.35722 13 5.72927 13H9.09889ZM9.09889 15H5.72927C4.2421 15 3.03296 13.804 3.03296 12.333V5.667C3.03296 4.196 4.2421 3 5.72927 3H12.4685C13.9557 3 15.1648 4.196 15.1648 5.667V9H18.1978C19.87 9 21.2308 10.346 21.2308 12V18C21.2308 19.654 19.87 21 18.1978 21H12.1319C10.4597 21 9.09889 19.654 9.09889 18V15ZM11.1209 12C11.1209 11.449 11.5748 11 12.1319 11H18.1978C18.7559 11 19.2088 11.449 19.2088 12V18C19.2088 18.551 18.7559 19 18.1978 19H12.1319C11.5748 19 11.1209 18.551 11.1209 18V12Z" />
    <mask id="mask0_87_529" maskUnits="userSpaceOnUse" x="3" y="3" width="19" height="18">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.09889 13V12C9.09889 10.346 10.4597 9 12.1319 9H13.1428V5.667C13.1428 5.299 12.8406 5 12.4685 5H5.72927C5.35722 5 5.05494 5.299 5.05494 5.667V12.333C5.05494 12.701 5.35722 13 5.72927 13H9.09889ZM9.09889 15H5.72927C4.2421 15 3.03296 13.804 3.03296 12.333V5.667C3.03296 4.196 4.2421 3 5.72927 3H12.4685C13.9557 3 15.1648 4.196 15.1648 5.667V9H18.1978C19.87 9 21.2308 10.346 21.2308 12V18C21.2308 19.654 19.87 21 18.1978 21H12.1319C10.4597 21 9.09889 19.654 9.09889 18V15ZM11.1209 12C11.1209 11.449 11.5748 11 12.1319 11H18.1978C18.7559 11 19.2088 11.449 19.2088 12V18C19.2088 18.551 18.7559 19 18.1978 19H12.1319C11.5748 19 11.1209 18.551 11.1209 18V12Z" />
    </mask>
    <g mask="url(#mask0_87_529)" />
  </BaseSVGIcon>
);
