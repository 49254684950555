import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageLocation = () => {
  const location = useLocation();
  const [locationPage, setLocationPage] = useState<string>('');
  useEffect(() => {
    setLocationPage(location.pathname);
  }, [location]);
  return locationPage;
};
