import { Spinner } from 'components';
import React from 'react';

import { IconProps } from 'theme/icons/icons.types';
import { useStyles } from './Loader.styles';

export const Loader: React.FC<IconProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Spinner />
    </div>
  );
};
