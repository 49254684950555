import {
  COLOR_BG_DARK_7, COLOR_MINT_400, COLOR_MINT_600, COLOR_MINT_800,
} from 'theme/colors';
import { createStyles, Theme } from '@material-ui/core/styles';
import { flexHelper } from 'utils';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  totalEarned: {
    ...flexHelper(),
    flexDirection: 'column',
    width: 170,
    border: `1px solid ${COLOR_MINT_600}`,
    borderRadius: 13,
    background: COLOR_BG_DARK_7,
    padding: theme.spacing(1, 1.5),
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      margin: theme.spacing(2.5, 'auto', 2),
      background: COLOR_MINT_800,
      width: '100%',
    },
  },
  title: {
    color: `${COLOR_MINT_400} !important`,
  },
}));
