import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    stakesTable: {
      margin: '0 auto',
      [formatMedia.BREAKPOINT_DESKTOP]: {},
    },
    stakesMobileContainer: {
      padding: theme.spacing(0, 3),
    },
    mobileSorting: {
      ...flexHelper('space-between', 'center'),
      margin: theme.spacing(1, 0),
    },
    myPoolsSwitch: {
      ...flexHelper(),
      width: 150,
      flexShrink: 0,
      marginLeft: theme.spacing(1),
      '& > *': {
        '&:first-child': {
          marginRight: theme.spacing(1),
        },
      },
    },
    mobileSortingSelect: {
      maxWidth: 200,
      '& > div > div.MuiSelect-root': {
        display: 'block',
      },
    },
    cardContainer: {
      margin: '0 auto',
    },
    stakeCard: {
      '&:not(last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    loadMoreBtnContainer: {
      marginTop: theme.spacing(4),
      ...flexHelper(),
      '& > button': {
        width: 170,
      },
    },

    spinnerContainer: {
      position: 'relative',
    },
    spinner: {
      transform: 'scale(0.3)',
      position: 'absolute',
      left: 30,
      top: 0,
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
  });
});
