import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
// import {
//   COLOR_MINT_600,
// } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    headTitle: {
      '& :not(:last-child)': {
        marginRight: theme.spacing(4.5),
      },
      '& > svg': {
        width: 48,
        height: 48,
      },
    },
  });
});
