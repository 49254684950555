import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const InfoIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.33337 5.92553C7.33337 5.57797 7.63204 5.2959 8.00004 5.2959C8.36804 5.2959 8.66671 5.57797 8.66671 5.92553V9.07368C8.66671 9.42123 8.36804 9.70331 8.00004 9.70331C7.63204 9.70331 7.33337 9.42123 7.33337 9.07368V5.92553ZM7.33337 10.9626C7.33337 10.615 7.63204 10.3329 8.00004 10.3329C8.36804 10.3329 8.66671 10.615 8.66671 10.9626C8.66671 11.3101 8.36804 11.5922 8.00004 11.5922C7.63204 11.5922 7.33337 11.3101 7.33337 10.9626ZM8.00004 13.4803C5.05937 13.4803 2.6667 11.2206 2.6667 8.44329C2.6667 5.66599 5.05937 3.40625 8.00004 3.40625C10.9407 3.40625 13.3334 5.66599 13.3334 8.44329C13.3334 11.2206 10.9407 13.4803 8.00004 13.4803ZM8.00004 2.14746C4.31804 2.14746 1.33337 4.96631 1.33337 8.44376C1.33337 11.9212 4.31804 14.7401 8.00004 14.7401C11.682 14.7401 14.6667 11.9212 14.6667 8.44376C14.6667 4.96631 11.682 2.14746 8.00004 2.14746Z" fill="#84D0B9" />
    {/* eslint-disable-next-line react/style-prop-object */}
    <mask id="mask0_1599_11182" maskUnits="userSpaceOnUse" x="1" y="2" width="14" height="13">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.33337 5.92553C7.33337 5.57797 7.63204 5.2959 8.00004 5.2959C8.36804 5.2959 8.66671 5.57797 8.66671 5.92553V9.07368C8.66671 9.42123 8.36804 9.70331 8.00004 9.70331C7.63204 9.70331 7.33337 9.42123 7.33337 9.07368V5.92553ZM7.33337 10.9626C7.33337 10.615 7.63204 10.3329 8.00004 10.3329C8.36804 10.3329 8.66671 10.615 8.66671 10.9626C8.66671 11.3101 8.36804 11.5922 8.00004 11.5922C7.63204 11.5922 7.33337 11.3101 7.33337 10.9626ZM8.00004 13.4803C5.05937 13.4803 2.6667 11.2206 2.6667 8.44329C2.6667 5.66599 5.05937 3.40625 8.00004 3.40625C10.9407 3.40625 13.3334 5.66599 13.3334 8.44329C13.3334 11.2206 10.9407 13.4803 8.00004 13.4803ZM8.00004 2.14746C4.31804 2.14746 1.33337 4.96631 1.33337 8.44376C1.33337 11.9212 4.31804 14.7401 8.00004 14.7401C11.682 14.7401 14.6667 11.9212 14.6667 8.44376C14.6667 4.96631 11.682 2.14746 8.00004 2.14746Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1599_11182)" />
  </BaseSVGIcon>
);
