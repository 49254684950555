import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import {
  COLOR_BG_DARK_7,
  COLOR_BG_DARK_8,
  COLOR_ERROR,
  COLOR_MINT_100,
  COLOR_MINT_200,
  COLOR_MINT_400,
  COLOR_MINT_600,
} from 'theme/colors';

export const getMuiInputBase = (theme: Theme): Overrides['MuiInputBase'] => ({
  root: {
    background: COLOR_BG_DARK_7,
  },
  input: {
    borderRadius: theme.spacing(1.5),
    padding: '24px 18px 12px 18px !important',
  },
});

export const getMuiOutlinedInput = (
  theme: Theme,
): Overrides['MuiOutlinedInput'] => ({
  root: {
    height: theme.spacing(7),
    color: COLOR_MINT_100,
    borderRadius: theme.spacing(1.75),
    '& legend': {
      maxWidth: 0,
    },
    '&$focused:not(&$error) input': {
      color: COLOR_MINT_400,
    },
    '&$focused:not(&$error) svg': {
      '& > *': {
        fill: COLOR_MINT_400,
      },
    },
    '&$focused:not(&$error) fieldset': {
      borderWidth: 1,
      borderColor: COLOR_MINT_400,
    },
    '&:not(&$disabled):hover fieldset, &$error fieldset': {
      borderWidth: 1,
      borderColor: COLOR_MINT_400,
    },
    '&$error fieldset': {
      borderWidth: 1,
      borderColor: COLOR_ERROR,
    },
    '&$error svg': {
      '& > *': {
        fill: COLOR_ERROR,
      },
    },
    '&$disabled fieldset': {
      borderWidth: 1,
      // backgroundColor: theme.palette.type === 'dark' ? COLOR_BLACK_1 : COLOR_GREY_3,
    },
    '&$disabled input': {
      borderWidth: 1,
      // color: theme.palette.type === 'dark' ? COLOR_BLACK_4 : COLOR_GREY_2,
    },
    '& fieldset': {
      borderWidth: 1,
      borderColor: COLOR_MINT_600,
    },
    '& svg': {
      zIndex: 99,
    },
  },
  multiline: {
    height: theme.spacing(20),
    '& > *': {
      '&:first-child': {
        // position: 'absolute',
        // top: theme.spacing(3),
        // left: theme.spacing(1),
        // width: '90%',
        maxHeight: 100,
        borderRadius: '0 !important',
        padding: `${theme.spacing(1)}px !important`,
        overflow: 'auto !important',
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        /* Hide scrollbar for Chrome, Safari and Opera */
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '& > *': {
          'label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder': {
            background: 'red !important',
          },
        },
        'label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder': {
          background: 'red !important',
        },
      },
    },
  },
});

export const getMuiFilledInput = (
  theme: Theme,
): Overrides['MuiFilledInput'] => ({
  root: {
    border: `1px solid ${COLOR_MINT_600}`,
    height: theme.spacing(4.5),
    color: COLOR_MINT_100,
    borderRadius: '50px !important',
    background: `${COLOR_BG_DARK_8} !important`,
    '& legend': {
      maxWidth: 0,
    },
    '&$focused:not(&$error) input': {
      color: COLOR_MINT_400,
    },
    '&$focused:not(&$error) svg': {
      '& > *': {
        fill: COLOR_MINT_400,
      },
    },
    '&$focused:not(&$error) fieldset': {
      borderWidth: 1,
      borderColor: COLOR_MINT_400,
    },
    '&:not(&$disabled):hover fieldset, &$error fieldset': {
      borderWidth: 1,
      borderColor: COLOR_MINT_400,
    },
    '&$error fieldset': {
      borderWidth: 1,
      borderColor: COLOR_ERROR,
    },
    '&$error svg': {
      '& > *': {
        fill: COLOR_ERROR,
      },
    },
    '&$disabled fieldset': {
      borderWidth: 1,
    },
    '&$disabled input': {
      borderWidth: 1,
    },
    '& fieldset': {
      borderWidth: 1,
      borderColor: COLOR_MINT_600,
    },
    '& svg': {
      zIndex: 99,
    },
  },
});

export const getMuiInputLabel = (): Overrides['MuiInputLabel'] => ({
  root: {
    color: COLOR_MINT_400,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(14px, 18px) scale(1)',
  },
  outlined: {
    '&$shrink': {
      color: COLOR_MINT_400,
      top: 14,
      left: 4,
    },
    '&$.dateInput': {
      color: 'red',
    },
  },
});

export const getMuiInputLabelDefaultProps = (): ComponentsProps['MuiInputLabel'] => ({
  // shrink: true,
});

export const getMuiTextField = (): Overrides['MuiTextField'] => ({
  root: {
    width: '100%',
    '&.noLabel': {
      width: '100%',
      '& > div': {
        '& > div': {
          padding: '12px 28px 12px 18px !important',
        },
      },
    },
    '&.dateInput': {
      '& .MuiInputLabel-outlined': {
        left: -1,
      },
      '& > div > input': {
        position: 'relative',
        top: 3,
      },
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  variant: 'outlined',
  SelectProps: {
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
    },
  },
});

export const getMuiFilledInputDefaultProps = (): ComponentsProps['MuiFilledInput'] => ({
  disableUnderline: true,
});

export const getMuiFormHelperText = (): Overrides['MuiFormHelperText'] => ({
  root: {
    marginTop: 16,
    fontSize: 16,
    color: COLOR_MINT_200,
  },

  contained: {
    marginLeft: 0,
  },
});
