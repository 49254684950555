export const formatLongAmount = (
  longAmount: string | number,
  maxLength = 5,
) => {
  if (!longAmount && Number(longAmount) !== 0) return '';
  const amount = longAmount ?? '';
  return amount.toString().length > maxLength
    ? `${amount.toString().slice(0, maxLength)}...`
    : amount;
};
