import { getTokenAmountDisplay } from 'utils';
/* eslint-disable max-len */
import {
  select, put, call, takeLatest, all,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { getStatus } from 'containers/Pools/Pools.helper';
import { BackendPools } from 'types';
import { poolsApi } from 'services';
import { tokenLogoPlaceholder } from 'assets/images';
import userSelector from 'store/user/selectors';
import { erc20Abi, mainPoolAbi } from 'config/abi';
import { sortPools } from 'utils/sortPools';
import actionTypes from '../actionTypes';
import { updateStakes } from '../reducer';
import { getMainPoolsData } from '../actions';

export function* getMainPoolsDataSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getMainPoolsData>) {
  yield put(apiActions.request(type));

  const { chain, address } = yield* select(userSelector.getUser);

  try {
    const { data: mainPools } = yield call(poolsApi.getPools, {
      network: chain,
      poolType: BackendPools.StakingMain,
    });

    const formattedMainPools = mainPools.map((stake) => ({
      name: stake.title,
      apr: stake.apr,
      stakingToken0Staked: stake.total_stake_token[0],
      stakingToken0Logo: stake.token1_preview || tokenLogoPlaceholder,
      stakingToken0Address: stake.stake_token[0],
      stakingToken1Staked: stake.total_stake_token[1],
      stakingToken1Logo: stake.token2_preview || tokenLogoPlaceholder,
      stakingToken1Address: stake.stake_token[1],
      rewardTokenAddress: stake.reward_token,
      rewardTokenLogo: stake.reward_preview || tokenLogoPlaceholder,
      status: getStatus(+stake.open_time, +stake.close_time),
      startDate: stake.open_time,
      endDate: stake.close_time,
      description: stake.description,
      poolAddress: stake.address,
      network: stake.network,
      rewardsInThePool: stake.reward_amount,
      minimumStakingTime: stake.penalty_period,
      earlyUnstakePenalty: stake.fee_percentage,
      poolType: stake.pool_type,
      txHash: stake.tx_hash,
      id: stake.id,
      stakingToken0Link: stake.stake_token_urls.length > 0 && stake.stake_token_urls[0],
      stakingToken1Link: stake.stake_token_urls.length > 0 && stake.stake_token_urls[1],
    }));

    // stakingToken0 symbols
    const stakingTokens0SymbolsPromises = formattedMainPools.map(
      ({ stakingToken0Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken0Address).methods
        .symbol()
        .call(),
    );
    const stakingTokens0Symbols = yield* all(stakingTokens0SymbolsPromises);

    // stakingToken1 symbols
    const stakingTokens1SymbolsPromises = formattedMainPools.map(
      ({ stakingToken1Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken1Address).methods
        .symbol()
        .call(),
    );
    const stakingTokens1Symbols = yield* all(stakingTokens1SymbolsPromises);

    // stakingToken0 decimals
    const stakingTokens0DecimalsPromises = formattedMainPools.map(
      ({ stakingToken0Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken0Address).methods
        .decimals()
        .call(),
    );
    const stakingTokens0Decimals = yield all(stakingTokens0DecimalsPromises);

    // stakingToken1 decimals
    const stakingTokens1DecimalsPromises = formattedMainPools.map(
      ({ stakingToken1Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken1Address).methods
        .decimals()
        .call(),
    );
    const stakingTokens1Decimals = yield all(stakingTokens1DecimalsPromises);

    let userAmounts = [];
    let userRewards = [];
    if (address) {
      // stakingTokens user staked
      const userAmountsPromises = formattedMainPools.map(({ poolAddress }) => new web3Provider.eth.Contract(mainPoolAbi, poolAddress).methods
        .getUserAmounts(address)
        .call());
      userAmounts = yield all(userAmountsPromises);

      // stakingTokens user staked
      const userRewardsPromises = formattedMainPools.map(({ poolAddress }) => new web3Provider.eth.Contract(mainPoolAbi, poolAddress).methods
        .pendingReward(address)
        .call());
      userRewards = yield all(userRewardsPromises);
    }

    const mainPoolsWithDataFromBlockchain = formattedMainPools.map(
      (pool, index) => {
        const rewardTokenSymbol =
          pool.rewardTokenAddress === pool.stakingToken0Address
            ? stakingTokens0Symbols[index]
            : stakingTokens1Symbols[index];
        const rewardTokenDecimals =
          pool.rewardTokenAddress === pool.stakingToken0Address
            ? stakingTokens0Decimals[index]
            : stakingTokens1Decimals[index];
        return {
          ...pool,
          stakingToken0Symbol: stakingTokens0Symbols[index],
          stakingToken1Symbol: stakingTokens1Symbols[index],
          stakingToken0Decimals: stakingTokens0Decimals[index],
          stakingToken1Decimals: stakingTokens1Decimals[index],
          rewardTokenSymbol,
          userStakedToken0Amount: getTokenAmountDisplay(
            userAmounts[index]?.[0] || 0,
            stakingTokens0Decimals[index],
          ),
          userStakedToken1Amount: getTokenAmountDisplay(
            userAmounts[index]?.[1] || 0,
            stakingTokens1Decimals[index],
          ),
          pendingReward: getTokenAmountDisplay(
            userRewards[index] || 0,
            rewardTokenDecimals,
          ),
        };
      },
    );

    const sortedPools = sortPools('status', 'desc', mainPoolsWithDataFromBlockchain);

    yield put(
      updateStakes({
        mainPoolStakes: sortedPools,
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_MAIN_POOLS_DATA, getMainPoolsDataSaga);
}
