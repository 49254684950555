import React, { FC, useState } from 'react';
import {
  Box, Button, Collapse, Typography,
} from '@material-ui/core';
import { CloseIcon, InfoIcon } from 'theme/icons';
import { Pools } from 'types';
import { useStyles } from './MobileInfo.styles';
import { poolsTableHeadData } from '../Pools.helper';

interface MobileInfoProps {
  poolType: Pools;
}

export const MobileInfo: FC<MobileInfoProps> = ({ poolType }) => {
  const classes = useStyles();
  const [showInfo, setShowInfo] = useState(false);

  const toggleShowInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <Box className={classes.root}>
      <Collapse collapsedSize={0} in={showInfo}>
        <Box className={classes.infoText}>
          {poolsTableHeadData[poolType].info.title !== '' && (
            <Typography variant="body2" className={classes.infoTextTitle}>
              <strong>{poolsTableHeadData[poolType].info.title}</strong>
            </Typography>
          )}
          <Typography variant="body2">{poolsTableHeadData[poolType].info.text}</Typography>
          <Button
            variant="contained"
            className={classes.closeInfoBtn}
            onClick={() => setShowInfo(false)}
            endIcon={<CloseIcon />}
          />
        </Box>
      </Collapse>
      <Box className={classes.infoBtnWrapper}>
        <Button variant="contained" className={classes.infoBtn} endIcon={<InfoIcon />} onClick={toggleShowInfo}>Info</Button>
      </Box>
    </Box>
  );
};
