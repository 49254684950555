/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent, useCallback, useEffect, useMemo, useState, VFC,
} from 'react';
import {
  Box, Button, Container, MenuItem, TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo.svg';
import { LaunchIcon } from 'theme/icons';
import { routes } from 'appConstants';
import { DisconnectModal } from 'components';
import { useWalletConnectorContext } from 'services';
import { useShallowSelector, usePageLocation, useWindowState } from 'hooks';
import {
  Chains, State, UserState, WalletProviders,
} from 'types';
import userSelector from 'store/user/selectors';
import { useDispatch } from 'react-redux';
import { changeChain } from 'store/user/reducer';
import { checkIsAdmin } from 'store/user/actions';
import { ConnectWalletModal } from 'components/ConnectWalletModal';
import { LaunchPoolModal } from 'components/LaunchPoolModal';
import { useStyles } from './Header.styles';
import { chains } from './Header.helper';

export interface HeaderProps {
  className?: string;
}

export const Header: VFC<HeaderProps> = ({ className }) => {
  const { width } = useWindowState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = usePageLocation();

  const { connect, disconnect, walletService } = useWalletConnectorContext();
  const {
    address,
    chain,
    isAdmin,
    provider: walletProvider,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const [currentChain, setCurrentChain] = useState(chain);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [isLaunchPoolModalOpen, setIsLaunchPoolModalOpen] = useState(false);

  const handleChainChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const newChain = event.target.value as Chains;
      if (walletProvider) {
        await connect(walletProvider, newChain);
      }
      setCurrentChain(newChain);
      dispatch(changeChain(newChain));
    },
    [walletProvider],
  );

  const handleToogleDisconnectModal = useCallback(() => {
    setIsDisconnectModalOpen(!isDisconnectModalOpen);
  }, [isDisconnectModalOpen]);

  const disconnectWallet = useCallback(() => {
    disconnect();
    setIsDisconnectModalOpen(false);
  }, []);

  const handleConnectWallet = async (provider: WalletProviders) => {
    await connect(provider, currentChain);
    setIsConnectModalOpen(false);
  };

  const showButtons = useMemo(() => {
    if (location === '/launch-pool') {
      if (width < 1025) {
        return false;
      }
    }
    return true;
  }, [width, location]);

  const classes = useStyles();

  useEffect(() => {
    if (address.length && walletService.Web3()) {
      dispatch(
        checkIsAdmin({
          web3Provider: walletService.Web3() as any,
        }),
      );
    }
  }, [address, walletService.Web3()]);

  return (
    <Container className={clsx(classes.root, className)}>
      {isDisconnectModalOpen && (
        <DisconnectModal
          open={isDisconnectModalOpen}
          onClose={handleToogleDisconnectModal}
          disconnectWallet={disconnectWallet}
        />
      )}
      {isConnectModalOpen && (
        <ConnectWalletModal
          open={isConnectModalOpen}
          onConnectWallet={handleConnectWallet}
          onClose={() => setIsConnectModalOpen(false)}
        />
      )}
      {isLaunchPoolModalOpen && (
        <LaunchPoolModal
          open={isLaunchPoolModalOpen}
          onClose={() => setIsLaunchPoolModalOpen(false)}
        />
      )}
      <Box className={classes.header}>
        <Box onClick={() => navigate(routes.root)}>
          <img style={{ cursor: 'pointer' }} src={Logo} alt="logo" />
        </Box>
        {showButtons && (
          <Box className={classes.buttons}>
            <TextField
              value={currentChain}
              onChange={handleChainChange}
              select
              className="noLabel"
              defaultValue={chains[0].value}
            >
              {chains.map(({ value, label, icon }) => (
                <MenuItem key={value} value={value}>
                  {icon}&nbsp; {label}
                </MenuItem>
              ))}
            </TextField>
            {address ? (
              <>
                {isAdmin && (
                  <Button
                    onClick={() => setIsLaunchPoolModalOpen(true)}
                    color="primary"
                    className={clsx(classes.launchPool, classes.headerButton)}
                    variant="outlined"
                    size="large"
                    endIcon={<LaunchIcon />}
                  >
                    Launch a pool
                  </Button>
                )}
                <Button
                  className={clsx(classes.address, classes.headerButton)}
                  onClick={handleToogleDisconnectModal}
                  color="primary"
                  variant="outlined"
                  size="large"
                >
                  {address}
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setIsConnectModalOpen(true)}
                className={clsx(classes.connect, classes.headerButton)}
                color="secondary"
                variant="outlined"
                size="large"
              >
                Connect Wallet
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};
