import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { COLOR_MINT_600, COLOR_BG_DARK_9 } from 'theme/colors';
import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      ...flexHelper(),
      border: `1px solid ${COLOR_MINT_600}`,
      background: COLOR_BG_DARK_9,
      overflow: 'hidden',

      '&.m': {
        width: 128,
        height: 128,
        borderRadius: theme.spacing(1.75),
      },
      '&.l': {
        width: 512,
        height: 512,
        borderRadius: theme.spacing(2.5),
      },
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    img: {
      '&.m': {
        width: 128,
        height: 128,
        borderRadius: theme.spacing(1.75),
      },
      '&.l': {
        width: 512,
        height: 512,
        borderRadius: theme.spacing(2.5),
      },
    },
  });
});
