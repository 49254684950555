import React from 'react';

import { COLOR_MINT_300 } from 'theme/colors';
import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const CompleteIconBig: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="164" height="164" viewBox="0 0 164 164" fill={COLOR_MINT_300} {...props}>
    <path d="M120.748 64.3734C123.751 61.3712 123.751 56.5038 120.748 53.5016C117.746 50.4995 112.879 50.4995 109.877 53.5016L69.1875 94.1907L54.1234 79.1266C51.1212 76.1245 46.2538 76.1245 43.2516 79.1266C40.2495 82.1288 40.2495 86.9962 43.2516 89.9984L63.7516 110.498C66.7538 113.501 71.6212 113.501 74.6234 110.498L120.748 64.3734Z" fill="#84D0B9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 82C0 36.7127 36.7127 0 82 0C127.287 0 164 36.7127 164 82C164 127.287 127.287 164 82 164C36.7127 164 0 127.287 0 82ZM15.375 82C15.375 45.204 45.204 15.375 82 15.375C118.796 15.375 148.625 45.204 148.625 82C148.625 118.796 118.796 148.625 82 148.625C45.204 148.625 15.375 118.796 15.375 82Z" fill="#84D0B9" />
  </BaseSVGIcon>
);
