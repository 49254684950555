import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { flexHelper } from 'utils';
import { COLOR_MINT_300 } from '../../theme/colors';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    ...flexHelper(),
  },
  header: {
    width: '100%',
    maxWidth: 1040,
    ...flexHelper('space-between'),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  headerButton: {
    width: 200,
  },
  launchPool: {
    padding: `${theme.spacing(1.5, 2.25)} !important`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      '& > span': {
        ...flexHelper('space-between'),
      },
    },
  },
  address: {
    '& > span': {
      overflow: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: COLOR_MINT_300,
    },
  },
  connect: {
    padding: '0 !important',
  },
  buttons: {
    width: '100%',
    ...flexHelper('flex-end', 'center'),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      marginTop: theme.spacing(4),
    },
    '& > *': {
      maxWidth: 199,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 'unset',
      },
      '&:not(:last-child)': {
        marginRight: 16,
        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
          marginBottom: 16,
        },
      },
    },
  },
}));
