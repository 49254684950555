import axios, { AxiosRequestConfig } from 'axios';

import { backendUrl } from 'config/constants';

axios.defaults.baseURL = backendUrl;

axios.interceptors.request.use(
  (config:AxiosRequestConfig) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.common = {
      // @ts-ignore
      ...config.headers.common,
      Authorization: `${
        localStorage.getItem('microtuber_token')
          ? `Token ${localStorage.getItem('microtuber_token')}`
          : ''
      }`,
    };
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

export default axios;
