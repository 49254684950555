import { getTokenAmountDisplay } from 'utils';
/* eslint-disable max-len */
import {
  select, put, call, takeLatest, all,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { getStatus } from 'containers/Pools/Pools.helper';
import { BackendPools } from 'types';
import { poolsApi } from 'services';
import userSelector from 'store/user/selectors';
import { erc20Abi, miningPoolAbi } from 'config/abi';
import { tokenLogoPlaceholder } from 'assets/images';
import actionTypes from '../actionTypes';
import { updateStakes } from '../reducer';
import { getMiningPoolsData } from '../actions';
import { sortPools } from '../../../utils/sortPools';

export function* getMiningPoolsDataSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getMiningPoolsData>) {
  yield put(apiActions.request(type));

  const { chain, address } = yield* select(userSelector.getUser);

  try {
    const { data: miningPools } = yield call(poolsApi.getPools, {
      network: chain,
      poolType: BackendPools.Staking20Into20,
    });

    const formattedMiningPools = miningPools.map((stake) => ({
      name: stake.title,
      apr: stake.apr,
      stakingToken0Staked: stake.total_stake_token[0],
      stakingToken0Logo: stake.token1_preview || tokenLogoPlaceholder,
      stakingToken0Address: stake.stake_token[0],
      rewardTokenAddress: stake.reward_token,
      rewardTokenLogo: stake.reward_preview || tokenLogoPlaceholder,
      status: getStatus(+stake.open_time, +stake.close_time),
      rewardsInThePool: stake.reward_amount,
      startDate: stake.open_time,
      endDate: stake.close_time,
      minimumStakingTime: stake.penalty_period,
      earlyUnstakePenalty: stake.fee_percentage,
      description: stake.description,
      poolAddress: stake.address,
      network: stake.network,
      id: stake.id,
      poolType: stake.pool_type,
      txHash: stake.tx_hash,
      stakingToken0Link: stake.stake_token_urls.length > 0 && stake.stake_token_urls[0] && stake.stake_token_urls[0],
    }));

    // stakingToken symbols
    const stakingTokensSymbolsPromises = formattedMiningPools.map(
      ({ stakingToken0Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken0Address).methods
        .symbol()
        .call(),
    );
    const stakingTokensSymbols = yield* all(stakingTokensSymbolsPromises);

    // rewardToken symbols
    const rewardTokensSymbolsPromises = formattedMiningPools.map(
      ({ rewardTokenAddress }) => new web3Provider.eth.Contract(erc20Abi, rewardTokenAddress).methods
        .symbol()
        .call(),
    );
    const rewardTokensSymbols = yield* all(rewardTokensSymbolsPromises);

    let token0UserStakedAmount = [];
    if (address) {
    // stakingToken user staked
      const token0UserStakedAmountPromises = formattedMiningPools.map(
        ({ poolAddress }) => new web3Provider.eth.Contract(miningPoolAbi, poolAddress).methods
          .stakeInfo(address)
          .call(),
      );
      token0UserStakedAmount = yield all(token0UserStakedAmountPromises);
    }

    // stakingToken decimals
    const stakingTokensDecimalsPromises = formattedMiningPools.map(
      ({ stakingToken0Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken0Address).methods
        .decimals()
        .call(),
    );
    const stakingTokensDecimals = yield all(stakingTokensDecimalsPromises);

    // rewardToken decimals
    const rewardTokensDecimalsPromises = formattedMiningPools.map(
      ({ rewardTokenAddress }) => new web3Provider.eth.Contract(erc20Abi, rewardTokenAddress).methods
        .decimals()
        .call(),
    );
    const rewardTokenDecimals = yield all(rewardTokensDecimalsPromises);

    let pendingRewards = [];
    if (address) {
    // pending rewards for user
      const pendingRewardsPromises = formattedMiningPools.map(
        ({ poolAddress }) => new web3Provider.eth.Contract(miningPoolAbi, poolAddress).methods
          .pendingReward(address)
          .call(),
      );
      pendingRewards = yield all(pendingRewardsPromises);
    }

    const miningPoolsWithDataFromBlockchain = formattedMiningPools.map(
      (pool, index) => ({
        ...pool,
        stakingToken0Symbol: stakingTokensSymbols[index],
        stakingToken0Decimals: stakingTokensDecimals[index],
        rewardTokenDecimals: rewardTokenDecimals[index],
        rewardTokenSymbol: rewardTokensSymbols[index],
        pendingReward: getTokenAmountDisplay(
          pendingRewards[index] || 0,
          rewardTokenDecimals[index],
        ),
        userStakedToken0Amount: getTokenAmountDisplay(
          token0UserStakedAmount[index]?.amount || 0,
          stakingTokensDecimals[index],
        ),
      }),
    );

    const sortedPools = sortPools('status', 'desc', miningPoolsWithDataFromBlockchain);

    yield put(
      updateStakes({
        miningPoolStakes: sortedPools,
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_MINING_POOLS_DATA, getMiningPoolsDataSaga);
}
