import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const EthereumIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path d="M9.95356 14.975L3.81689 11.35L9.95273 20L16.0944 11.35L9.95106 14.975H9.95356ZM10.0469 0L3.90856 10.1858L10.0461 13.8142L16.1836 10.1892L10.0469 0Z" fill="#CFFFF1" />
  </BaseSVGIcon>
);
