import { fork } from 'redux-saga/effects';

import approveSaga from './approveSaga';
import stakeSaga from './stake';
import unstakeSaga from './unstake';
import createNewPool from './createNewPool';
import approveNfts from './approveNfts';
import getMiningPoolsData from './getMiningPools';
import getNftDropsData from './getNftDrops';
import getMainPoolsData from './getMainPools';
import getNftPoolsData from './getNftPools';
import getTokenDropsData from './getTokenDrops';
import getMctPoolsData from './getMctPools';
import stakeToNftPool from './stakeToNftPool';
import unstakeFromNftPool from './unstakeFromNftPool';
import unstakeFromNftDrop from './unstakeFromNftDrop';
import stakeToNftDrop from './stakeToNftDrop';
import updatePool from './updatePool';
import stakeToMiningPool from './stakeToMiningPool';
import unstakeFromMiningPool from './unstakeFromMiningPool';
import stakeToErc20ToErc20Pool from './stakeToErc20PlusErc20Pool';
import getMainPoolSecondTokenAmount from './getMainPoolSecondTokenAmount';
import unstakeFromErc20PlusErc20Pool from './unstakeFromErc20PlusErc20Pool';
import editPoolDescription from './editPoolDescription';
import getPoolsCount from './getPoolsCount';

export default function* stakesSagas() {
  yield fork(approveSaga);
  yield fork(stakeSaga);
  yield fork(unstakeSaga);
  yield fork(createNewPool);
  yield fork(approveNfts);
  yield fork(getMiningPoolsData);
  yield fork(getNftDropsData);
  yield fork(getMainPoolsData);
  yield fork(getNftPoolsData);
  yield fork(getTokenDropsData);
  yield fork(getMctPoolsData);
  yield fork(stakeToNftPool);
  yield fork(unstakeFromNftPool);
  yield fork(unstakeFromNftDrop);
  yield fork(stakeToNftDrop);
  yield fork(updatePool);
  yield fork(stakeToMiningPool);
  yield fork(unstakeFromMiningPool);
  yield fork(stakeToErc20ToErc20Pool);
  yield fork(getMainPoolSecondTokenAmount);
  yield fork(unstakeFromErc20PlusErc20Pool);
  yield fork(editPoolDescription);
  yield fork(getPoolsCount);
}
