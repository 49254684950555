import React from 'react';
import { noop } from 'lodash';

import { StakedIcon } from 'theme/icons';
import { Token } from 'types';

import { StakeModalProps } from './BaseStakeModal.types';

export const mockToken: Token = {
  image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
  decimals: 18,
  address: '',
  symbol: 'LOL',
};

export const mctToken: Token = {
  address: '',
  decimals: 18,
  symbol: 'MCT',
  image: <StakedIcon />,
};

export const nftToken: Token = {
  address: '',
  decimals: 0,
  symbol: 'NFT',
  image: 'https://lh3.googleusercontent.com/9KlOmj2LnRmmvgeTSBKX8cZRNQtvO_Jdh-SyDNjzw8DVjJftfOI8bBsdQWcfiAbr4mOC-e42onTm6XxugzJAKtwSbc-X8a1PAQ-TP9A=s0',
};

export const nftToken2: Token = {
  address: '',
  decimals: 0,
  symbol: 'NFT',
  image: 'https://img.seadn.io/files/1fed037b35e6d4bbb5657c9503813d6b.png?fit=max&w=600',
};

const balance = (398329 * 10 ** 18).toString();
export const userStakeAmount = (888.12345 * 10 ** 18).toString();
export const userRewardAmount = (88.12345 * 10 ** 18).toString();

export const stakeModalBasePropsMocked: Pick<StakeModalProps, 'balance' | 'onClose' | 'open'> = {
  balance,
  onClose: noop,
  open: true,
};
