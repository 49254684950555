import React, { VFC } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import { ArrowLeftIcon } from 'theme/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'appConstants';
import { Pools, RequestStatus } from 'types';
import {
  Form, Formik, FormikErrors, FormikTouched, FormikValues,
} from 'formik';
import clsx from 'clsx';
import { ImageUploader, Loader, SuccessModal } from 'components';
import { useDispatch } from 'react-redux';
import uiSelector from 'store/ui/selectors';
import stakesActionTypes from 'store/stakes/actionTypes';
import { createNewPool } from 'store/stakes/actions';
import successModalSelector from 'store/successModal/selectors';
import { useWalletConnectorContext } from 'services';
import { useShallowSelector } from 'hooks';
import { updateSuccessModal } from 'store/successModal/reducer';
import { readablePoolName } from 'utils';
import web3 from 'web3';
import { useStyles } from './LaunchPool.styles';
import { IConfigElement } from './LaunchPool.types';
import { formConfig } from './LaunchPool.helper';

export const LaunchPool: VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();
  const navigate = useNavigate();
  const { poolType } = useParams();

  const successModalState = useShallowSelector(successModalSelector.getSuccessModal);
  const { [stakesActionTypes.CREATE_NEW_POOL]: createNewPoolRequestStatus } = useShallowSelector(
    uiSelector.getUI,
  );

  const isCreateNewPoolInProcess = RequestStatus.REQUEST === createNewPoolRequestStatus;

  const {
    initialValues, validationSchema, fields, formClassName,
  } = formConfig[poolType as Pools];
  type FormValues = typeof initialValues;

  const handleNavigateHome = () => {
    navigate(routes.root);
  };

  const handleCloseModal = () => {
    dispatch(
      updateSuccessModal({
        isSuccessModalOpen: false,
      }),
    );
  };
  const calculateRewardAmount = (values) => {
    if (+values.rewardsPerSecond > 0 &&
      values.rewardTokenIndex &&
      values.rewardTokenIndex !== '' &&
      web3.utils.isAddress(values.rewardTokenIndex === '0' ? values.stakingTokenAddress : values.stakingToken2Address)
    ) {
      const result = Math
        .floor((new Date(values.endDate)
          .getTime() - new Date(values.startDate)
          .getTime()) / 1000) * +values.rewardsPerSecond;
      return (
        `${result} of reward token ${values.rewardTokenIndex === '0' ? values.stakingTokenAddress : values.stakingToken2Address} will be deducted from your wallet and deposited as the rewards`
      );
    }
    if (+values.rewardsPerSecond > 0 && web3.utils.isAddress(values.rewardTokenAddress)) {
      const result = Math
        .floor((new Date(values.endDate)
          .getTime() - new Date(values.startDate)
          .getTime()) / 1000) * +values.rewardsPerSecond;
      return (
        `${result} of reward token ${values.rewardTokenAddress} will be deducted from your wallet and deposited as the rewards`
      );
    }
    return '';
  };

  const handleCreateNewPool = (formValues: FormValues) => {
    dispatch(
      createNewPool({
        web3Provider: walletService.Web3(),
        poolType: poolType as Pools,
        poolData: formValues,
      }),
    );
  };

  const renderField = (
    field: IConfigElement,
    values: FormikValues,
    errors: FormikErrors<FormValues>,
    touched: FormikTouched<FormValues>,
    setFieldValue: (fieldName: string, value: unknown, shouldValidate?: boolean) => void,
    handleChange: () => void,
    handleBlur: () => void,
  ) => {
    // base recursive case
    if (field.fieldType === 'field') {
      const { id, className } = field;
      switch (field.elementType) {
        case 'uploader':
          return (
            <ImageUploader
              key={id}
              id={id}
              label={field?.label}
              onChange={(file) => setFieldValue(id, file)}
              error={Boolean(errors[id] && touched[id])}
              onBlur={handleBlur}
              className={clsx(classes.field, classes[className])}
              {...field?.customProps}
            />
          );

        case 'radio':
          return (
            <FormControl key={id} className={clsx(classes.field, classes[className])}>
              {field?.title && (
                <Typography className={clsx(classes.title, 'mintLight')}>{field.title}</Typography>
              )}
              <RadioGroup
                defaultValue="0"
                name={id}
                id={id}
                onChange={handleChange}
              >
                <FormControlLabel value="0" control={<Radio />} label="Token 1" />
                <FormControlLabel value="1" control={<Radio />} label="Token 2" />
              </RadioGroup>
            </FormControl>
          );
        default:
          return (
            <Box key={field.className} className={classes[className]}>
              {field?.title && (
                <Typography className={clsx(classes.title, 'mintLight')}>{field.title}</Typography>
              )}
              <TextField
                id={id}
                label={field?.label}
                onChange={handleChange}
                error={Boolean(errors[id] && touched[id])}
                value={values[id]}
                type={field.elementType}
                className={clsx(classes.field, { dateInput: field.elementType === 'date' })}
                onBlur={handleBlur}
                {...field?.customProps}
              />
            </Box>
          );
      }
    }

    return (
      <Box key={field.className} className={classes[field?.containerClassName]}>
        <Box className={classes[field.className]}>
          {field.content.map(
            (element) => renderField(
              element, values, errors, touched, setFieldValue, handleChange, handleBlur,
            ),
          )}
        </Box>
        {field?.subtitle && <Typography className="mint">For example 1:5</Typography>}
      </Box>
    );
  };

  return (
    <Box className={classes.container}>
      <SuccessModal
        open={successModalState?.isSuccessModalOpen}
        onClose={handleCloseModal}
        address={successModalState?.newStakingContractAddress}
        title="Pool was successfully created"
      />
      {isCreateNewPoolInProcess && <Loader />}
      <Box className={classes.navigation}>
        <IconButton onClick={handleNavigateHome} color="primary" className={classes.homeButton}>
          <ArrowLeftIcon />
        </IconButton>
        <Typography>Home</Typography>
      </Box>
      <Box className={classes.form}>
        <Typography className={classes.poolNameTitle} variant="h5">
          {readablePoolName(poolType)}
        </Typography>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => handleCreateNewPool(values)}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleSubmit,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box className={clsx(classes[formClassName], classes.formInner)}>
                {fields.map((field) => renderField(
                  field,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  // @ts-ignore a huge type library function
                  handleChange,
                  handleBlur,
                ))}
              </Box>

              <Box className={classes.submitButtonContainer}>
                <Typography
                  className={classes.rewardHelperText}
                  variant="body2"
                >
                  {calculateRewardAmount(values)}
                </Typography>
                <Button
                  size="large"
                  type="submit"
                  color="secondary"
                  disabled={!isValid}
                  variant="outlined"
                  fullWidth
                  className={classes.submitButton}
                >
                  Launch
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
