import {
  select, put, takeLatest, call, delay,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { nftPoolAbi } from 'config/abi';
import { setNotification } from 'utils';
import { getTokensBalanceSaga } from 'store/user/sagas/getTokensBalance';
import { unstakeFromNftPool } from '../actions';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';
import { updatePoolSaga } from './updatePool';
import { getNfrPoolsDataSaga } from './getNftPools';
import userActionTypes from '../../user/actionTypes';
import { DELAY_BEFORE_UPDATE } from '../../../config/constants';

export function* unstakeFromNftPoolSaga({
  type,
  payload: {
    web3Provider,
    poolAddress,
    id,
    stakingToken0Address,
  },
}: ReturnType<typeof unstakeFromNftPool>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const nftPoolContract = yield new web3Provider.eth.Contract(
    nftPoolAbi,
    poolAddress,
  );

  try {
    yield* call(nftPoolContract.methods.withdraw().send, { from: myAddress });
    yield call(updatePoolSaga, {
      type: actionTypes.UPDATE_POOl,
      payload: { id },
    });

    setNotification({ type: 'success', message: 'Successfully unstaked' });

    yield delay(DELAY_BEFORE_UPDATE);

    yield call(getNfrPoolsDataSaga, {
      type: actionTypes.GET_NFT_POOLS_DATA,
      payload: { web3Provider },
    });

    yield call(getTokensBalanceSaga, {
      type: userActionTypes.GET_TOKENS_BALANCE,
      payload: { web3Provider, tokensAddresses: [stakingToken0Address] },
    });

    setNotification({ type: 'success', message: 'Successfully unstaked' });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UNSTAKE_FROM_NFT_POOL, unstakeFromNftPoolSaga);
}
