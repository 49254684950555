import { formattedDate } from 'utils';
import * as Yup from 'yup';
import { IConfigArray } from '../LaunchPool.types';
import {
  DAY_AFTER_TOMORROW, endDateValidation, startDateValidation, TOMORROW,
} from './helpers';

export const mctPoolFormInitValues = {
  poolName: '',
  maximumGoalOfStaking: '',
  maximumStakeAmountPerWallet: '',
  apr: '',
  earlyUnstakePenalty: '',
  startDate: formattedDate(TOMORROW),
  endDate: formattedDate(DAY_AFTER_TOMORROW),
  description: '',
  stakingTokenLink: '',
};

export const validationSchema = Yup.object().shape({
  poolName: Yup.string().min(0).max(20).required(),
  startDate: startDateValidation,
  endDate: endDateValidation,
  apr: Yup.number().positive().integer().min(0)
    .required(),
  maximumGoalOfStaking: Yup.number().integer().min(0).required(),
  maximumStakeAmountPerWallet: Yup.number()
    .min(0)
    .integer()
    .lessThan(Yup.ref('maximumGoalOfStaking'))
    .required(),
  earlyUnstakePenalty: Yup.number().positive().integer().min(0)
    .max(100)
    .required(),
  description: Yup.string().min(0).max(300).required(),
  stakingTokenLink: Yup.string().url(),
});

export const mctPoolConfig: IConfigArray = [
  {
    fieldType: 'field',
    className: 'poolName',
    id: 'poolName',
    elementType: 'text',
    label: 'Pool name',
    customProps: {
      helperText: 'Please use 1-2 words',
    },
  },
  {
    fieldType: 'field',
    className: 'maximumGoalOfStaking',
    id: 'maximumGoalOfStaking',
    elementType: 'text',
    label: 'Maximum goal of staking',
  },
  {
    fieldType: 'container',
    className: 'mctDates',
    content: [
      {
        fieldType: 'field',
        className: 'startDate',
        id: 'startDate',
        elementType: 'date',
        label: 'Start date',
      },
      {
        fieldType: 'field',
        className: 'endDate',
        id: 'endDate',
        elementType: 'date',
        label: 'End date',
      },
    ],
  },
  {
    fieldType: 'field',
    className: 'maximumStakeAmountPerWallet',
    id: 'maximumStakeAmountPerWallet',
    elementType: 'text',
    label: 'Maximum stake amount per wallet',
  },
  {
    fieldType: 'field',
    className: 'apr',
    id: 'apr',
    elementType: 'text',
    label: 'APR',
    customProps: {
      helperText: 'Please insert the number of %',
    },
  },
  {
    fieldType: 'field',
    className: 'earlyUnstakePenalty',
    id: 'earlyUnstakePenalty',
    elementType: 'number',
    label: 'Early unstake penalty',
    customProps: {
      helperText: 'Please insert the number of %',
    },
  },
  {
    fieldType: 'field',
    className: 'description',
    id: 'description',
    elementType: 'text',
    label: 'Description',
    customProps: {
      multiline: true,
      placeholder: 'Please add the description up to 300 characters.',
    },
  },
  {
    fieldType: 'field',
    className: 'stakingTokenLink',
    id: 'stakingTokenLink',
    elementType: 'text',
    label: 'Link to buy staking token',
  },
];

export const mctPoolFormConfig = {
  initialValues: mctPoolFormInitValues,
  validationSchema,
  fields: mctPoolConfig,
  formClassName: 'mctPoolForm',
};
