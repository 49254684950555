import { formattedDate } from 'utils';
import * as Yup from 'yup';
import web3 from 'web3';
import { IConfigArray } from '../LaunchPool.types';
import { startDateValidation, TOMORROW } from './helpers';

export const nftPoolFormInitValues = {
  poolName: '',
  stakingTokenAddress: '',
  stakingTokenAmount: '',
  stakingTokenLogo: '',
  stakingToken2Address: '',
  stakingToken2Logo: '',
  stakingToken2Id: '',
  rewardTokenAddress: '',
  rewardTokenLogo: '',
  rewardTokenId: '',
  rewardTokenAmount: '',
  stakingPeriod: '',
  entranceWindow: '',
  startDate: formattedDate(TOMORROW),
  description: '',
  stakingTokenLink: '',
  stakingToken1Link: '',
};

export const validationSchema = Yup.object().shape({
  poolName: Yup.string().min(0).max(20).required(),
  stakingTokenAddress: Yup.string().test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  stakingToken2Address: Yup.string().test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  rewardTokenAddress: Yup.string().test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  stakingTokenLogo: Yup.string().required(),
  stakingToken2Logo: Yup.string().required(),
  rewardTokenLogo: Yup.string().required(),
  rewardTokenAmount: Yup.number().positive().integer().required(),
  stakingTokenAmount: Yup.number().positive().integer().required(),
  stakingToken2Id: Yup.number().integer().required(),
  rewardTokenId: Yup.number().integer().required(),
  stakingPeriod: Yup.number().positive().integer().required(),
  entranceWindow: Yup.number().integer().min(1).lessThan(Yup.ref('stakingPeriod'))
    .required(),
  startDate: startDateValidation,
  description: Yup.string().min(0).max(300).required(),
  stakingTokenLink: Yup.string().url(),
  stakingToken1Link: Yup.string().url(),
});

export const nftPoolConfig: IConfigArray = [
  {
    fieldType: 'field',
    className: 'poolName',
    id: 'poolName',
    elementType: 'text',
    label: 'Pool name',
  },
  {
    fieldType: 'container',
    className: 'nftPoolTokens',
    containerClassName: 'nftPoolTokensContainer',
    content: [
      {
        fieldType: 'container',
        className: 'nftPoolTokensLeft',
        content: [
          {
            fieldType: 'field',
            className: 'stakingTokenAddress',
            id: 'stakingTokenAddress',
            elementType: 'text',
            label: 'Address',
            title: 'Staking Token Address',
          },
          {
            fieldType: 'field',
            className: 'stakingTokenLogo',
            id: 'stakingTokenLogo',
            elementType: 'uploader',
            label: 'Upload token logo',
            customProps: {
              size: 'small',
            },
          },
          {
            fieldType: 'field',
            className: 'stakingToken2Address',
            id: 'stakingToken2Address',
            elementType: 'text',
            label: 'Address',
            title: 'Staking NFT Address',
          },
          {
            fieldType: 'field',
            className: 'stakingToken2Logo',
            id: 'stakingToken2Logo',
            elementType: 'uploader',
            label: 'Upload NFT preview',
            customProps: {
              size: 'large',
            },
          },
          {
            fieldType: 'field',
            className: 'stakingToken2Id',
            id: 'stakingToken2Id',
            elementType: 'text',
            label: 'ID',
          },
        ],
      },
      {
        fieldType: 'container',
        className: 'nftPoolTokensRight',
        content: [
          {
            fieldType: 'field',
            className: 'rewardTokenAddress',
            id: 'rewardTokenAddress',
            elementType: 'text',
            label: 'Address',
            title: 'Reward NFT address',
          },
          {
            fieldType: 'field',
            className: 'rewardTokenLogo',
            id: 'rewardTokenLogo',
            elementType: 'uploader',
            label: 'Upload NFT preview',
            customProps: {
              size: 'large',
            },
          },
          {
            fieldType: 'field',
            className: 'rewardTokenId',
            id: 'rewardTokenId',
            elementType: 'text',
            label: 'ID',
          },
          {
            fieldType: 'field',
            className: 'rewardTokenAmount',
            id: 'rewardTokenAmount',
            elementType: 'text',
            label: 'Amount',
            title: 'NFTs to deposit as rewards to the pool',
          },
        ],
      },
    ],
  },
  {
    fieldType: 'container',
    className: 'stakingTime',
    containerClassName: 'stakingTimeContainer',
    content: [
      {
        fieldType: 'field',
        title: 'Staking period',
        className: 'stakingPeriod',
        id: 'stakingPeriod',
        elementType: 'number',
        label: 'Days',
      },
      {
        fieldType: 'field',
        title: 'Entrance window',
        className: 'entranceWindow',
        id: 'entranceWindow',
        elementType: 'text',
        label: 'Days',
      },
    ],
  },
  {
    fieldType: 'container',
    className: 'nftPoolDateAndAmount',
    containerClassName: 'nftPoolDateAndAmountContainer',
    content: [
      {
        fieldType: 'field',
        className: 'nftPoolStartDate',
        id: 'startDate',
        elementType: 'date',
        label: 'Start date',
      },
      {
        title: 'Amount',
        fieldType: 'field',
        className: 'nftPoolNftAmount',
        id: 'stakingTokenAmount',
        elementType: 'text',
        label: 'Number',
        customProps: {
          // helperText: 'Amount of Token1 to be staked to get NFT.',
          helperText: 'Please insert the amount of Token1 to be staked to get NFT',
        },
      },
    ],
  },
  {
    fieldType: 'field',
    className: 'description',
    id: 'description',
    elementType: 'text',
    label: 'Description',
    customProps: {
      multiline: true,
      placeholder: 'Please add the description up to 300 characters.',
    },
  },
  {
    fieldType: 'field',
    className: 'stakingTokenLink',
    id: 'stakingTokenLink',
    elementType: 'text',
    label: 'Link to buy staking token',
  },
  {
    fieldType: 'field',
    className: 'stakingToken1Link',
    id: 'stakingToken1Link',
    elementType: 'text',
    label: 'Link to buy staking nft token',
  },
];

export const nftPoolFormConfig = {
  initialValues: nftPoolFormInitValues,
  validationSchema,
  fields: nftPoolConfig,
  formClassName: 'nftPoolForm',
};
