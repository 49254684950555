import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';

import {
  Box,
  Button,
  Hidden,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { usePoolsSorting, useShallowSelector } from 'hooks';
import {
  Pools, State, UserState,
} from 'types';
import stakesSelector from 'store/stakes/selectors';
import userSelector from 'store/user/selectors';
import { useWalletConnectorContext } from 'services';
import { getMctPoolsData } from 'store/stakes/actions';
import { getTokensBalance } from 'store/user/actions';
import { StakeCard, TotalEarned } from 'components';
import { CustomTable } from 'containers';
import { useLocation } from 'react-router-dom';
import { mobileSortingMenu } from './MctPool.helpers';
import { ROWS_PER_PAGE } from '../Pools.helper';
import { MctStakeModal } from '../StakeModals';
import { useStyles } from '../Pools.styles';
import { MobileInfo } from '../MobileInfo';

export const MctPool: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { connect, web3WithoutMetamask, walletService } =
    useWalletConnectorContext();
  const { pathname } = useLocation();

  const { mctPoolStakes, mctTotalUserRewards } = useShallowSelector(
    stakesSelector.getStakes,
  );
  const userData = useShallowSelector<State, UserState>(userSelector.getUser);

  const {
    handleSort,
    handleMobileSort,
    handleMyPoolsFilter,
    handleChangePage,
    tableData,
    selectedMobileSorting,
    orderBy,
    order,
    isOnlyMyPools,
    page,
  } = usePoolsSorting(mctPoolStakes, mobileSortingMenu[0].value);

  const [isStakeModalOpen, setStakeModalOpen] = useState(false);
  const [activeStakeId, setActiveStakeId] = useState(null);

  const handleCloseStakeModal = useCallback(() => {
    setStakeModalOpen(false);
  }, []);

  const handleOpenStakeModal = useCallback(() => {
    setStakeModalOpen(true);
  }, []);

  const handleStakeClick = (stakeId: number) => {
    const stakeData = mctPoolStakes.find((stake) => stake.id === stakeId);
    if (!userData.address) {
      connect(userData.provider, userData.chain);
      return;
    }
    dispatch(
      getTokensBalance({
        web3Provider: walletService.Web3(),
        tokensAddresses: [stakeData.stakingToken0Address],
      }),
    );
    setActiveStakeId(stakeId);
    handleOpenStakeModal();
  };

  const activeStakeData = useMemo(
    () => mctPoolStakes.find((stakeData) => stakeData.id === activeStakeId),
    [activeStakeId, mctPoolStakes],
  );

  useEffect(() => {
    dispatch(
      getMctPoolsData({ web3Provider: web3WithoutMetamask(userData.chain) }),
    );
  }, [web3WithoutMetamask, userData.chain, userData.address, dispatch]);

  return (
    <>
      {isStakeModalOpen && (
        <MctStakeModal
          selectedStakeData={activeStakeData}
          open={isStakeModalOpen}
          onClose={handleCloseStakeModal}
        />
      )}
      <Hidden smDown implementation="css">
        <CustomTable
          className={classes.stakesTable}
          poolType={pathname.replace('/', '') as Pools}
          orderBy={orderBy}
          order={order}
          handleSort={handleSort}
          tableData={tableData}
          page={page}
          handleChangePage={handleChangePage}
          onStakeClick={handleStakeClick}
        />
      </Hidden>
      <Hidden mdUp implementation="css">
        <Box className={classes.stakesMobileContainer}>
          <TotalEarned amount={mctTotalUserRewards} />
          <Box className={classes.mobileSorting}>
            <TextField
              value={selectedMobileSorting}
              onChange={handleMobileSort}
              select
              className={clsx(classes.mobileSortingSelect, 'noLabel')}
              variant="filled"
            >
              {mobileSortingMenu.map(({ value, label }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <Box className={classes.myPoolsSwitch}>
              <Typography>My pools</Typography>
              <Box>
                <Switch
                  checked={isOnlyMyPools}
                  onChange={handleMyPoolsFilter}
                />
              </Box>
            </Box>
          </Box>
          <MobileInfo poolType={'MCTPool' as Pools} />
          <Box className={classes.cardContainer}>
            {tableData
              .slice(0, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
              .map((stakeData) => (
                <StakeCard
                  key={stakeData.poolAddress}
                  stakeData={stakeData}
                  onStakeClick={handleStakeClick}
                  className={classes.stakeCard}
                />
              ))}
            <Box className={classes.loadMoreBtnContainer}>
              {(page + 1) * ROWS_PER_PAGE < tableData.length && (
                <Button
                  size="large"
                  color="secondary"
                  variant="outlined"
                  onClick={(e) => handleChangePage(e, page + 1)}
                >
                  Load more
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};
