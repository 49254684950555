import React, { VFC } from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { Button } from '@material-ui/core';

import { WalletProviders } from 'types';
import { MetaMaskLogo, WalletConnectLogo } from 'theme/icons';
import { OntoIcon } from 'assets/images';
import { useStyles } from './ConnectWalletModal.styles';

export interface ConnectWalletModalProps extends ModalProps {
  onConnectWallet: (provider: WalletProviders) => void;
}

export const ConnectWalletModal: VFC<ConnectWalletModalProps> = ({
  open,
  onConnectWallet,
  onClose,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Modal customTitle="Choose a wallet" open={open} onClose={onClose} className={classes.root} {...props}>
      <Button
        color="primary"
        variant="outlined"
        size="large"
        className={classes.connectButton}
        onClick={() => onConnectWallet(WalletProviders.MetaMask)}
      >
        <MetaMaskLogo className={classes.connectButtonImg} />
      </Button>
      <Button
        color="primary"
        variant="outlined"
        size="large"
        className={classes.connectButton}
        onClick={() => onConnectWallet(WalletProviders.Onto)}
      >
        <img className={classes.connectButtonImg} src={OntoIcon} alt="onto" />
      </Button>
      <Button
        color="primary"
        variant="outlined"
        size="large"
        className={classes.connectButton}
        onClick={() => onConnectWallet(WalletProviders.WalletConnect)}
      >
        <WalletConnectLogo className={classes.connectButtonImg} />
      </Button>
    </Modal>
  );
};
