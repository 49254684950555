/* eslint-disable @typescript-eslint/naming-convention */
const STAKE = 'STAKE';
const UNSTAKE = 'UNSTAKE';
const APPROVE = 'APPROVE';
const APPROVE_NFTS = 'APPROVE_NFTS';
const CREATE_NEW_POOL = 'CREATE_NEW_POOL';
const CREATE_MCT_POOL = 'CREATE_NEW_POOL';
const GET_MINING_POOLS_DATA = 'GET_MINING_POOLS_DATA';
const GET_NFT_DROPS_DATA = 'GET_NFT_DROPS_DATA';
const GET_MAIN_POOLS_DATA = 'GET_MAIN_POOLS_DATA';
const GET_NFT_POOLS_DATA = 'GET_NFT_POOLS_DATA';
const GET_TOKEN_DROPS_DATA = 'GET_TOKEN_DROPS_DATA';
const GET_MCT_POOLS = 'GET_MCT_POOLS';
const UPDATE_POOl = 'UPDATE_POOl';

const STAKE_TO_NFT_POOL = 'STAKE_TO_NFT_POOL';
const STAKE_TO_NFT_DROP = 'STAKE_TO_NFT_DROP';
const STAKE_TO_MINING_POOL = 'STAKE_TO_MINING_POOL';
const STAKE_TO_ERC20_PLUS_ERC20 = 'STAKE_TO_ERC20_PLUS_ERC20';
const UNSTAKE_FROM_NFT_POOL = 'UNSTAKE_FROM_NFT_POOL';
const UNSTAKE_FROM_NFT_DROP = 'UNSTAKE_FROM_NFT_DROP';
const UNSTAKE_FROM_MINING_POOl = 'UNSTAKE_FROM_MINING_POOl';
const UNSTAKE_FROM_ERC20_PLUS_ERC20 = 'UNSTAKE_FROM_ERC20_PLUS_ERC20';

const GET_MAIN_POOL_SECOND_TOKEN_AMOUNT = 'GET_MAIN_POOL_SECOND_TOKEN_AMOUNT';
const EDIT_POOL_DESCRIPTION = 'EDIT_POOL_DESCRIPTION';
const GET_POOLS_COUNT = 'GET_POOLS_COUNT';

export default {
  STAKE,
  UNSTAKE,
  APPROVE,
  CREATE_NEW_POOL,
  CREATE_MCT_POOL,
  APPROVE_NFTS,
  GET_MINING_POOLS_DATA,
  GET_NFT_DROPS_DATA,
  GET_MAIN_POOLS_DATA,
  GET_NFT_POOLS_DATA,
  GET_TOKEN_DROPS_DATA,
  GET_MCT_POOLS,
  STAKE_TO_NFT_POOL,
  UNSTAKE_FROM_NFT_POOL,
  STAKE_TO_NFT_DROP,
  UNSTAKE_FROM_NFT_DROP,
  UPDATE_POOl,
  STAKE_TO_MINING_POOL,
  UNSTAKE_FROM_MINING_POOl,
  STAKE_TO_ERC20_PLUS_ERC20,
  GET_MAIN_POOL_SECOND_TOKEN_AMOUNT,
  UNSTAKE_FROM_ERC20_PLUS_ERC20,
  EDIT_POOL_DESCRIPTION,
  GET_POOLS_COUNT,
};
