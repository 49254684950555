import {
  select, put, takeLatest, call, delay,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { miningPoolAbi } from 'config/abi';
import { getTokenAmount, setNotification } from 'utils';
import { getTokensBalanceSaga } from 'store/user/sagas/getTokensBalance';
import { DELAY_BEFORE_UPDATE } from 'config/constants';
import { unstakeFromMiningPool } from '../actions';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';
import { updatePoolSaga } from './updatePool';
import { getMiningPoolsDataSaga } from './getMiningPools';
import userActionTypes from '../../user/actionTypes';

export function* unstakeFromMiningPoolSaga({
  type,
  payload: {
    web3Provider,
    poolAddress,
    id,
    stakingToken0Decimals,
    stakingToken0Address,
    amountToUnstake,
  },
}: ReturnType<typeof unstakeFromMiningPool>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const miningPoolContract = yield new web3Provider.eth.Contract(
    miningPoolAbi,
    poolAddress,
  );

  try {
    const amountToUnstakeWithDecimals = getTokenAmount(
      amountToUnstake,
      +stakingToken0Decimals,
    );

    yield* call(
      miningPoolContract.methods.withdraw(amountToUnstakeWithDecimals).send,
      {
        from: myAddress,
      },
    );

    yield call(updatePoolSaga, {
      type: actionTypes.UPDATE_POOl,
      payload: { id },
    });

    yield delay(DELAY_BEFORE_UPDATE);

    yield call(getMiningPoolsDataSaga, {
      type: actionTypes.GET_MINING_POOLS_DATA,
      payload: { web3Provider },
    });

    yield call(getTokensBalanceSaga, {
      type: userActionTypes.GET_TOKENS_BALANCE,
      payload: { web3Provider, tokensAddresses: [stakingToken0Address] },
    });

    setNotification({ type: 'success', message: 'Successfully unstaked' });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(
    actionTypes.UNSTAKE_FROM_MINING_POOl,
    unstakeFromMiningPoolSaga,
  );
}
