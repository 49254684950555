import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { flexHelper } from 'utils';

export const useStyles = makeStyles(() => createStyles({
  loader: {
    zIndex: 999,
    height: '100%',
    backgroundColor: 'transparent',
    ...flexHelper(),
  },

  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },

  icon: {
    width: '100px',
    height: 'auto',
    zIndex: 999,
    position: 'relative',
    top: '-5px',
    animation: '$rotating 2s linear infinite',
  },
}));
