import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const TwitterIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="32" height="33" fill="none" viewBox="0 0 32 33" {...props}>
    <path d="M16 0.924988C7.16429 0.924988 0 8.08927 0 16.925C0 25.7607 7.16429 32.925 16 32.925C24.8357 32.925 32 25.7607 32 16.925C32 8.08927 24.8357 0.924988 16 0.924988ZM23.6893 12.9857C23.7 13.1536 23.7 13.3286 23.7 13.5C23.7 18.7428 19.7071 24.7821 12.4107 24.7821C10.1607 24.7821 8.075 24.1286 6.31786 23.0036C6.63929 23.0393 6.94643 23.0536 7.275 23.0536C9.13214 23.0536 10.8393 22.425 12.2 21.3607C10.4571 21.325 8.99286 20.1821 8.49286 18.6107C9.10357 18.7 9.65357 18.7 10.2821 18.5393C9.38474 18.357 8.57812 17.8696 7.99934 17.1599C7.42056 16.4503 7.10531 15.5622 7.10714 14.6464V14.5964C7.63214 14.8928 8.25 15.075 8.89643 15.1C8.35301 14.7378 7.90735 14.2472 7.59897 13.6715C7.29059 13.0959 7.12901 12.453 7.12857 11.8C7.12857 11.0607 7.32143 10.3857 7.66786 9.79999C8.66394 11.0262 9.9069 12.0291 11.3159 12.7434C12.725 13.4578 14.2686 13.8677 15.8464 13.9464C15.2857 11.25 17.3 9.06785 19.7214 9.06785C20.8643 9.06785 21.8929 9.54642 22.6179 10.3178C23.5143 10.15 24.3714 9.81427 25.1357 9.36427C24.8393 10.2821 24.2179 11.0571 23.3929 11.5464C24.1929 11.4607 24.9643 11.2393 25.6786 10.9286C25.1393 11.7214 24.4643 12.425 23.6893 12.9857Z" fill="#A4F8DE" />
  </BaseSVGIcon>
);
