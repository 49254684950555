import React, { useEffect, VFC } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box, Button, FormControl, Grid, Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { Modal } from 'components/Modal';
import { getStatusIcon } from 'utils/getStatusIcon';
import { Chains, IStakes, Status } from 'types';
import {
  formattedDate, getCurrentTimestampInSeconds, getLinkToScaner, transformIntervalFromSeconds,
} from 'utils';
import userSelector from 'store/user/selectors';

import { stakeToNftPool, unstakeFromNftPool } from 'store/stakes/actions';
import { useWalletConnectorContext } from 'services';

import { ExternalLinkBox, ImageContainer } from 'components';
import { useShallowSelector } from 'hooks';
import BigNumber from 'bignumber.js';
import { useStakeUnstakeRadioGroup } from '../components';
import { useBaseStyles } from '../BaseStakeModal';
import { RadioStates } from '../BaseStakeModal/BaseStakeModal.types';

export interface NftPoolStakeModalProps {
  selectedStakeData: IStakes;
  onClose: () => void;
  open: boolean;
  chain?: Chains;
}

export const NftPoolStakeModal: VFC<NftPoolStakeModalProps> = ({
  onClose,
  open,
  selectedStakeData,
  chain,
}) => {
  const dispatch = useDispatch();

  const { walletService } = useWalletConnectorContext();
  const {
    isUserDepositedToPool,
    rewardTokenLogo,
    stakingToken1Logo,
    stakingToken0Symbol,
    stakingToken0Address,
    stakingToken1Address,
    stakingToken0AmountToStake,
    // stakingToken0Staked,
    rewardTokenAddress,
    rewardTokenId,
    poolAddress,
    status,
    stakingToken1Id,
    id,
    entranceWindow,
    endDate,
  } = selectedStakeData;

  const stakingToken0UserBalance = useShallowSelector(
    userSelector.getBalanceOfToken(stakingToken0Address),
  );

  const { StakeUnstakeRadioGroup, isStakeSelected, setRadioState } =
    useStakeUnstakeRadioGroup();

  const isEntranceWindowEnd = getCurrentTimestampInSeconds() > +entranceWindow;
  const isStakeEnd = getCurrentTimestampInSeconds() > +endDate;

  const isEnoughToken0ToStake = new BigNumber(stakingToken0UserBalance).isGreaterThanOrEqualTo(
    stakingToken0AmountToStake,
  );

  const isUnstakeAvailable = isUserDepositedToPool;

  const isUnstakeBtnAvailable =
    (!isEntranceWindowEnd || isStakeEnd) && isUnstakeAvailable;
  const isStakeBtnAvailable =
    isEnoughToken0ToStake &&
    !isEntranceWindowEnd &&
    !isUserDepositedToPool;

  const stakeOrUnstakeText = isStakeSelected ? 'Stake' : `Unstake ${isStakeEnd ? '+ Claim' : ''}`;

  const handleStakeClick = () => {
    dispatch(
      stakeToNftPool({
        web3Provider: walletService.Web3(),
        stakingToken1Address,
        poolAddress,
        stakingToken0Address,
        stakingToken0AmountToStake,
        id,
      }),
    );
  };

  const handleUnstakeClick = () => {
    dispatch(
      unstakeFromNftPool({
        poolAddress,
        web3Provider: walletService.Web3(),
        id,
        stakingToken0Address,
      }),
    );
  };

  useEffect(() => {
    if (status?.label === Status.completed) {
      setRadioState(RadioStates.unstake);
    } else {
      setRadioState(RadioStates.stake);
    }
  }, [status?.label, setRadioState]);

  const baseStakeModalClasses = useBaseStyles();

  return (
    <Modal open={open} onClose={onClose} customTitle="NFT Pool">
      <FormControl component="fieldset">
        {isUnstakeAvailable &&
          selectedStakeData.status.label !== Status.completed && (
            <StakeUnstakeRadioGroup />
        )}

        <Grid container className={baseStakeModalClasses.info}>
          {isStakeSelected && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="body1"
                className={clsx(baseStakeModalClasses.title, 'xs')}
              >
                Reward NFT
              </Typography>
              <Box
                className={clsx(
                  baseStakeModalClasses.infoItemContent,
                  baseStakeModalClasses.imgBox,
                )}
              >
                <ImageContainer
                  src={rewardTokenLogo}
                  alt="NFT reward preview"
                />
              </Box>
              <ExternalLinkBox
                link={getLinkToScaner({
                  chain,
                  hash: rewardTokenAddress,
                  params: `?a=${rewardTokenId}`,
                  type: 'token',
                })}
                variant="light"
              />
            </Grid>
          )}

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              Status
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              {getStatusIcon(status.label)}
              <Typography className={baseStakeModalClasses.status}>
                {status.label}
              </Typography>
            </Box>
          </Grid>

          {isStakeSelected && (
            <>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Staking time
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {transformIntervalFromSeconds(
                      selectedStakeData?.stakingTime,
                      'Day',
                    )}&nbsp;days
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Amount to stake
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {stakingToken0AmountToStake} {stakingToken0Symbol}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Your {stakingToken0Symbol} balance
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>{stakingToken0UserBalance} {stakingToken0Symbol}</Typography>
                </Box>
              </Grid>
            </>
          )}

          {!isStakeSelected && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="body1"
                className={clsx(baseStakeModalClasses.title, 'xs')}
              >
                You staked
              </Typography>
              <Box className={baseStakeModalClasses.infoItemContent}>
                <Typography>{isUserDepositedToPool ? stakingToken0AmountToStake : '0'} {stakingToken0Symbol}</Typography>
              </Box>
              <Typography
                variant="body1"
                className={clsx(baseStakeModalClasses.title, 'xs')}
              >
                You staked NFT
              </Typography>
              <Box
                className={clsx(
                  baseStakeModalClasses.infoItemContent,
                  baseStakeModalClasses.imgBox,
                )}
              >
                <ImageContainer
                  src={stakingToken1Logo}
                  alt="NFT stake preview"
                />
              </Box>
              <ExternalLinkBox
                link={getLinkToScaner({
                  chain,
                  hash: stakingToken1Address,
                  params: `?a=${stakingToken1Id}`,
                  type: 'token',
                })}
                variant="light"
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {!isStakeSelected && (
              <Typography
                variant="body1"
                className={clsx(baseStakeModalClasses.title, 'xs')}
              >
                Your NFT earned
              </Typography>
            )}
            <Box
              className={clsx(
                baseStakeModalClasses.infoItemContent,
                baseStakeModalClasses.imgBox,
              )}
            >
              {isStakeSelected ? (
                <ImageContainer
                  src={stakingToken1Logo}
                  alt="NFT stake preview"
                />
              ) : (
                <ImageContainer
                  src={rewardTokenLogo}
                  alt="NFT reward preview"
                />
              )}
            </Box>
            <ExternalLinkBox
              link={
                isStakeSelected
                  ? getLinkToScaner({
                    chain,
                    hash: stakingToken1Address,
                    params: `?a=${stakingToken1Id}`,
                    type: 'token',
                  })
                  : getLinkToScaner({
                    chain,
                    hash: rewardTokenAddress,
                    params: `?a=${rewardTokenId}`,
                    type: 'token',
                  })
              }
              variant="light"
            />
            {!isEntranceWindowEnd && (
              <Typography
                variant="body1"
                className={clsx(baseStakeModalClasses.entranceWindowTitle, 's')}
              >
                If you change your mind you can unstake your tokens before&nbsp;
                {formattedDate(new Date((+entranceWindow) * 1000))}.
                After that your tokens will be locked till&nbsp;
                {formattedDate(new Date(+endDate * 1000))}.
              </Typography>
            )}
            {isEntranceWindowEnd && (
              <Typography
                variant="body1"
                className={clsx(baseStakeModalClasses.entranceWindowTitle, 's')}
              >
                Entrance window has ended!
              </Typography>
            )}
          </Grid>
        </Grid>
        <Button
          className={baseStakeModalClasses.button}
          variant="outlined"
          color="secondary"
          onClick={isStakeSelected ? handleStakeClick : handleUnstakeClick}
          disabled={isStakeSelected ? !isStakeBtnAvailable : !isUnstakeBtnAvailable}
        >
          {stakeOrUnstakeText}
        </Button>
      </FormControl>
    </Modal>
  );
};
