/* eslint-disable max-len */
import {
  select, put, call, takeLatest, all,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { getStatus } from 'containers/Pools/Pools.helper';
import { BackendPools } from 'types';
import { poolsApi } from 'services';
import userSelector from 'store/user/selectors';
import { erc20Abi, tokenDropAbi } from 'config/abi';
import { getTokenAmountDisplay } from 'utils';
import { tokenLogoPlaceholder } from 'assets/images';
import { sortPools } from 'utils/sortPools';
import actionTypes from '../actionTypes';
import { updateStakes } from '../reducer';
import { getTokenDropsData } from '../actions';

export function* getTokenDropsDataSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getTokenDropsData>) {
  yield put(apiActions.request(type));

  const { chain, address } = yield* select(userSelector.getUser);

  try {
    const { data: tokenDrops } = yield call(poolsApi.getPools, {
      network: chain,
      poolType: BackendPools.Staking20Plus20Into20,
    });

    const formattedTokenDrops = tokenDrops.map((stake) => ({
      name: stake.title,
      apr: stake.apr,
      poolAddress: stake.address,
      network: stake.network,
      description: stake.description,
      startDate: stake.open_time,
      endDate: stake.close_time,
      stakingToken0Address: stake.stake_token[0],
      stakingToken0Logo: stake.token1_preview || tokenLogoPlaceholder,
      stakingToken0Staked: stake.total_stake_token[0],
      stakingToken1Address: stake.stake_token[1],
      stakingToken1Logo: stake.token2_preview || tokenLogoPlaceholder,
      stakingToken1Staked: stake.total_stake_token[1],
      rewardTokenAddress: stake.reward_token,
      rewardTokenLogo: stake.reward_preview || tokenLogoPlaceholder,
      status: getStatus(+stake.open_time, +stake.close_time),
      minimumStakingPeriod: stake.penalty_period,
      rewardsInThePool: stake.reward_amount,
      minimumStakingTime: stake.penalty_period,
      earlyUnstakePenalty: stake.fee_percentage,
      poolType: stake.pool_type,
      txHash: stake.tx_hash,
      id: stake.id,
      stakingToken0Link: stake.stake_token_urls.length > 0 && stake.stake_token_urls[0],
      stakingToken1Link: stake.stake_token_urls.length > 0 && stake.stake_token_urls[1],
    }));

    // stakingToken symbols
    const stakingTokensSymbolsPromises = formattedTokenDrops.map(
      ({ stakingToken0Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken0Address).methods
        .symbol()
        .call(),
    );

    // stakingToken1 symbols
    const stakingTokens1SymbolsPromises = formattedTokenDrops.map(
      ({ stakingToken1Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken1Address).methods
        .symbol()
        .call(),
    );

    // rewardToken symbols
    const rewardTokensSymbolsPromises = formattedTokenDrops.map(
      ({ rewardTokenAddress }) => new web3Provider.eth.Contract(erc20Abi, rewardTokenAddress).methods
        .symbol()
        .call(),
    );

    let userAmounts = [];
    let userRewards = [];
    if (address) {
      // stakingTokens user staked
      const userAmountsPromises = formattedTokenDrops.map(({ poolAddress }) => new web3Provider.eth.Contract(tokenDropAbi, poolAddress).methods
        .getUserAmounts(address)
        .call());
      userAmounts = yield all(userAmountsPromises);

      // user pending rewards
      const userRewardsPromises = formattedTokenDrops.map(({ poolAddress }) => new web3Provider.eth.Contract(tokenDropAbi, poolAddress).methods
        .pendingReward(address)
        .call());
      userRewards = yield all(userRewardsPromises);
    }

    // stakingToken0 decimals
    const stakingTokens0DecimalsPromises = formattedTokenDrops.map(
      ({ stakingToken0Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken0Address).methods
        .decimals()
        .call(),
    );

    // stakingToken1 decimals
    const stakingTokens1DecimalsPromises = formattedTokenDrops.map(
      ({ stakingToken1Address }) => new web3Provider.eth.Contract(erc20Abi, stakingToken1Address).methods
        .decimals()
        .call(),
    );

    // rewardToken decimals
    const rewardTokensDecimalsPromises = formattedTokenDrops.map(
      ({ rewardTokenAddress }) => new web3Provider.eth.Contract(erc20Abi, rewardTokenAddress).methods
        .decimals()
        .call(),
    );

    const stakesInfoFromBlockchain = yield all(
      [].concat(
        stakingTokensSymbolsPromises,
        stakingTokens1SymbolsPromises,
        rewardTokensSymbolsPromises,
        stakingTokens0DecimalsPromises,
        stakingTokens1DecimalsPromises,
        rewardTokensDecimalsPromises,
      ),
    );
    /**
     * stakesAmount = 2;
     * stakesInfoFromBlockchain = ['ETH', 'ETH', 'WBNB', 'WBNB', 'WETH', 'WETH', 18, 18, 6, 6];
     * stakingTokens0Symbols = ['ETH' <-- token0 symbol in the first pool, 'ETH' <-- token0 symbol in the second pool];
     * stakingTokens1Symbols = ['WBNB', 'WBNB'];
     * ...
     */
    const stakesAmount = formattedTokenDrops.length;

    const stakingTokens0Symbols = stakesInfoFromBlockchain.slice(
      stakesAmount,
      stakesAmount * 2,
    );
    const stakingTokens1Symbols = stakesInfoFromBlockchain.slice(
      0,
      stakesAmount,
    );
    const rewardTokensSymbols = stakesInfoFromBlockchain.slice(
      stakesAmount * 2,
      stakesAmount * 3,
    );
    const stakingTokens0Decimals = stakesInfoFromBlockchain.slice(
      stakesAmount * 3,
      stakesAmount * 4,
    );
    const stakingTokens1Decimals = stakesInfoFromBlockchain.slice(
      stakesAmount * 4,
      stakesAmount * 5,
    );
    const rewardTokensDecimals = stakesInfoFromBlockchain.slice(
      stakesAmount * 5,
      stakesAmount * 6,
    );

    const tokenDropsWithDataFromBlockchain = formattedTokenDrops.map(
      (pool, index) => ({
        ...pool,
        stakingToken0Symbol: stakingTokens0Symbols[index],
        stakingToken1Symbol: stakingTokens1Symbols[index],
        rewardTokenSymbol: rewardTokensSymbols[index],
        userStakedToken0Amount: getTokenAmountDisplay(
          userAmounts[index] ? userAmounts[index][0] : 0,
          stakingTokens0Decimals[index],
        ),
        userStakedToken1Amount: getTokenAmountDisplay(
          userAmounts[index] ? userAmounts[index][1] : 0,
          stakingTokens1Decimals[index],
        ),
        stakingToken0Decimals: stakingTokens0Decimals[index],
        stakingToken1Decimals: stakingTokens1Decimals[index],
        pendingReward: getTokenAmountDisplay(
          userRewards[index] || 0,
          rewardTokensDecimals[index],
        ),
      }),
    );

    const sortedPools = sortPools('status', 'desc', tokenDropsWithDataFromBlockchain);

    yield put(
      updateStakes({
        tokenDropStakes: sortedPools,
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_TOKEN_DROPS_DATA, getTokenDropsDataSaga);
}
