/* eslint-disable max-len */
import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { contractsConfig, ContractsNames } from 'config';
import { isMainnet } from 'config/constants';
import { updateBalance } from 'store/user/reducer';
import userSelector from '../selectors';
import actionTypes from '../actionTypes';
import { getTokenBalance } from '../actions';

// TODO: delete this saga when wrerite mct pool to new saga
export function* getTokenBalanceSaga({
  type, payload: {
    web3Provider,
  },
}: ReturnType<typeof getTokenBalance>) {
  yield put(apiActions.request(type));
  const {
    abi: tokenAbi,
    address: tokenAddress,
  } = contractsConfig.contracts[ContractsNames.token][isMainnet ? 'mainnet' : 'testnet'];

  const myAddress = yield select(userSelector.getProp('address'));
  const chain = yield select(userSelector.getProp('chain'));
  try {
    const tokenContract = yield (new web3Provider.eth.Contract(tokenAbi, tokenAddress[chain]));

    const balance = yield call(tokenContract.methods.balanceOf(myAddress).call);

    yield put(updateBalance(balance));

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_TOKEN_BALANCE, getTokenBalanceSaga);
}
