import { IStakes, Status } from 'types';

export const sortPools = (
  orderBy: string | Status,
  order: string,
  poolsData: IStakes[],
) => {
  if (orderBy === 'status') {
    return [...poolsData].sort((stakeA, stakeB) => {
      if (order === 'asc') {
        return stakeA['status'].value - stakeB['status'].value || +stakeB.endDate - +stakeA.endDate;
      }
      return stakeB['status'].value - stakeA['status'].value || +stakeB.endDate - +stakeA.endDate;
    });
  }

  return [...poolsData].sort((stakeA, stakeB) => {
    if (order === 'asc') {
      return +stakeA[orderBy] - +stakeB[orderBy];
    }
    return +stakeB[orderBy] - +stakeA[orderBy];
  });
};
