import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';
import { COLOR_BG_DARK_7 } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    icon: {
      ...flexHelper(),
      flexDirection: 'column',

    },
    desc: {
      minWidth: '300px',
      maxWidth: '80%',
      textAlign: 'center',
      fontSize: 24,
      lineHeight: '60px',
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset',
        maxWidth: '100%',
      },
    },
    button: {
      padding: theme.spacing(2, 4),
      background: COLOR_BG_DARK_7,
      width: '80%',
      [formatMedia.BREAKPOINT_TABLET]: {
        width: '199px',
      },
    },
  });
});
