/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SuccessModalState } from 'types';

const initialState: SuccessModalState = {
  newStakingContractAddress: '',
  isSuccessModalOpen: false,
};

export const successModalReducer = createSlice({
  name: 'successModal',
  initialState,
  reducers: {
    updateSuccessModal: (state, action: PayloadAction<Partial<SuccessModalState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  updateSuccessModal,
} = successModalReducer.actions;

export default successModalReducer.reducer;
