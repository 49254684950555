import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      width: '100%',
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    modalTitle: {
      ...flexHelper('space-between'),
      marginBottom: theme.spacing(1),
    },
  });
});
