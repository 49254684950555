import { put, call, takeEvery } from 'redux-saga/effects';
import { poolsApi } from 'services';
import apiActions from 'store/api/actions';
import { setNotification } from 'utils';
import { editPoolDescription } from '../actions';
import actionTypes from '../actionTypes';
import { updatePoolInfoByAddress } from '../reducer';

export function* editPoolDescriptionSaga({
  type,
  payload: {
    poolType, txHash, description, network, address,
  },
}: ReturnType<typeof editPoolDescription>) {
  yield put(apiActions.request(type));

  try {
    yield call(poolsApi.editPoolDescription, {
      address,
      tx_hash: txHash,
      network,
      description,
      poolType,
    });

    setNotification({
      type: 'success',
      message: 'Description updated!',
    });

    yield put(updatePoolInfoByAddress({ address, poolType, newPoolData: { description } }));
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.EDIT_POOL_DESCRIPTION, editPoolDescriptionSaga);
}
