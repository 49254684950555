import { formattedDate } from 'utils';
import * as Yup from 'yup';
import web3 from 'web3';
import { IConfigArray } from '../LaunchPool.types';
import {
  DAY_AFTER_TOMORROW,
  earlyUnstakePenaltyValidation,
  endDateValidation,
  minimumStakingPeriodValidation,
  startDateValidation,
  TOMORROW,
} from './helpers';

export const miningPoolFormInitValues = {
  poolName: '',
  stakingTokenAddress: '',
  stakingTokenLogo: '',
  rewardTokenAddress: '',
  rewardTokenLogo: '',
  rewardsPerSecond: '',
  minimumStakingPeriod: '',
  startDate: formattedDate(TOMORROW),
  endDate: formattedDate(DAY_AFTER_TOMORROW),
  earlyUnstakePenalty: '',
  description: '',
  stakingTokenLink: '',
};

export const validationSchema = Yup.object().shape({
  poolName: Yup.string().min(0).max(20).required('Pool name is required'),
  stakingTokenLogo: Yup.string().required(),
  rewardTokenLogo: Yup.string().required(),
  stakingTokenAddress: Yup.string().test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  rewardTokenAddress: Yup.string().test('web3-address', 'Wrong address', (value) => web3.utils.isAddress(value)),
  rewardsPerSecond: Yup.number().required(),
  minimumStakingPeriod: minimumStakingPeriodValidation,
  earlyUnstakePenalty: earlyUnstakePenaltyValidation,
  startDate: startDateValidation,
  endDate: endDateValidation,
  description: Yup.string().min(0).max(300).required(),
  stakingTokenLink: Yup.string().url(),
});

export const miningPoolConfig: IConfigArray = [
  {
    fieldType: 'field',
    className: 'poolName',
    id: 'poolName',
    elementType: 'text',
    label: 'Pool name',
  },
  {
    fieldType: 'container',
    className: 'addresses',
    containerClassName: 'addressesContainer',
    content: [
      {
        fieldType: 'container',
        className: 'tokensFirst',
        content: [
          {
            fieldType: 'field',
            title: 'Staking token address',
            className: 'stakingTokenAddress',
            id: 'stakingTokenAddress',
            elementType: 'text',
            label: 'Address',
          },
          {
            fieldType: 'field',
            className: 'stakingTokenLogo',
            id: 'stakingTokenLogo',
            elementType: 'uploader',
            label: 'Upload token logo',
            customProps: {
              size: 'small',
            },
          },
        ],
      },
      {
        fieldType: 'container',
        className: 'tokensSecond',
        content: [
          {
            title: 'Reward token address',
            fieldType: 'field',
            className: 'rewardTokenAddress',
            id: 'rewardTokenAddress',
            elementType: 'text',
            label: 'Address',
          },
          {
            fieldType: 'field',
            className: 'rewardTokenLogo',
            id: 'rewardTokenLogo',
            elementType: 'uploader',
            label: 'Upload token logo',
            customProps: {
              size: 'small',
            },
          },
        ],
      },
    ],
  },
  {
    fieldType: 'field',
    className: 'rewardsPerSecond',
    id: 'rewardsPerSecond',
    elementType: 'text',
    label: 'Amount',
    title: 'The rewards to be distributed per second',
  },
  {
    fieldType: 'field',
    className: 'minimumStakingPeriod',
    id: 'minimumStakingPeriod',
    elementType: 'number',
    label: 'Days',
    title: 'Minimum staking period',
  },
  {
    fieldType: 'container',
    className: 'dates',
    containerClassName: 'datesContainer',
    content: [
      {
        fieldType: 'field',
        className: 'startDate',
        id: 'startDate',
        elementType: 'date',
        label: 'Start date',
      },
      {
        fieldType: 'field',
        className: 'endDate',
        id: 'endDate',
        elementType: 'date',
        label: 'End date',
      },
    ],
  },
  {
    fieldType: 'field',
    className: 'earlyUnstakePenalty',
    id: 'earlyUnstakePenalty',
    elementType: 'number',
    label: 'Percent',
    title: 'Early unstake penalty',
    customProps: {
      helperText: 'Please insert the number of %',
    },
  },
  {
    fieldType: 'field',
    className: 'description',
    id: 'description',
    elementType: 'text',
    label: 'Description',
    customProps: {
      multiline: true,
      placeholder: 'Please add the description up to 300 characters.',
    },
  },
  {
    fieldType: 'field',
    className: 'stakingTokenLink',
    id: 'stakingTokenLink',
    elementType: 'text',
    label: 'Link to buy staking token',
  },
];

export const miningPoolFormConfig = {
  initialValues: miningPoolFormInitValues,
  validationSchema,
  fields: miningPoolConfig,
  formClassName: 'miningPoolForm',
};
