import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const StakedIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_155_1912)">
      <path d="M20.3979 20.3705C25.0281 15.7403 25.0281 8.23331 20.3979 3.60312C15.7677 -1.02706 8.26072 -1.02706 3.63053 3.60312C-0.999664 8.23331 -0.999664 15.7403 3.63053 20.3705C8.26072 25.0007 15.7677 25.0007 20.3979 20.3705Z" fill="#EA571A" />
      <path d="M13.7293 19.9011C13.848 19.9655 13.9551 19.9895 14.0534 19.9862H14.0563C14.0623 19.9862 14.0672 19.9852 14.0732 19.9852C14.1654 19.9796 14.2676 19.9488 14.3749 19.881C14.9769 19.5023 14.597 18.9001 14.597 18.9001C14.597 18.9001 14.597 18.9001 13.9918 17.9375L13.9886 17.9385C13.9779 17.9572 13.9671 17.9766 13.957 17.9953C13.4461 18.9345 13.4455 18.9369 13.4455 18.9369C13.4455 18.9369 13.1043 19.5617 13.7293 19.9011Z" fill="#E0FFF6" />
      <path d="M10.1284 11.5124L10.2899 11.1753L4.57178 14.9903L4.63926 15.0768C4.93 15.4516 5.523 16.0784 6.22641 16.0784C6.45383 16.0784 6.67565 16.012 6.88622 15.88C7.56558 15.4555 8.16789 15.0875 8.67488 14.7758C9.08322 14.526 9.42917 14.3146 9.70696 14.1399L9.7636 14.1042L9.75276 14.0377C9.62077 13.171 9.75031 12.2979 10.1284 11.5124Z" fill="#E0FFF6" />
      <path d="M19.3933 9.28838L19.3904 9.25976L15.2031 3.98706L4.83414 10.3806L4.89874 10.4697C5.07755 10.715 5.35026 11.0609 5.63805 11.3121L5.69545 11.3627L10.4333 8.27255C10.526 8.21218 10.6579 8.24063 10.717 8.33088C10.779 8.42537 10.7521 8.55305 10.6579 8.61485L4.03979 12.9329L4.10914 13.021C4.32639 13.2959 4.77064 13.7838 5.31335 13.9575L5.3611 13.9732L11.5325 9.85649L11.5723 9.83685L11.596 9.82872L11.6288 9.82271C11.6372 9.82203 11.6429 9.8211 11.6666 9.82271C11.6736 9.82271 11.6803 9.82271 11.7023 9.82779C11.7105 9.83084 11.7172 9.83185 11.7323 9.83871L11.7739 9.8636L11.8301 9.9299L11.841 9.95284L11.855 10.024L11.8521 10.0541L11.8232 10.1354L11.8047 10.1593L11.7677 10.1937C10.9178 10.7869 10.3493 11.6779 10.1678 12.7012C9.98644 13.7252 10.2133 14.7576 10.8075 15.608L12.6968 18.3133L12.783 18.2378C13.2006 17.8698 14.1028 16.9125 13.4513 15.9543C13.1023 15.4432 12.9054 15.1507 12.7929 14.9857C12.7714 14.9529 12.7493 14.9206 12.7286 14.8878L12.7326 14.8835L12.3589 14.3481C12.3479 14.3321 12.085 13.9533 12.0095 13.4393C11.9057 12.7273 12.2012 12.0897 12.8406 11.6432C13.1078 11.4567 13.388 11.3465 13.6765 11.3148C13.6926 11.3129 13.7096 11.3118 13.7207 11.311L13.745 11.3099C14.0447 11.2871 14.3511 11.3526 14.6559 11.5003C15.3583 11.8406 15.7511 12.4246 15.7589 13.1444C15.7638 13.6487 15.5774 14.0525 15.5556 14.0959L15.1398 14.9573C15.1339 14.978 15.1272 14.9997 15.1189 15.0217L15.1181 15.0248C15.0935 15.0873 15.0605 15.1527 15.0267 15.2192C14.9514 15.3698 14.8245 15.6207 14.6163 16.0328L14.5002 16.2647C13.9803 17.2906 15.003 18.131 15.4664 18.4453L15.5586 18.5078L17.5533 14.9555L17.5662 14.9177C17.6502 14.7759 19.6284 11.4242 19.3933 9.28838Z" fill="#E0FFF6" />
    </g>
    <defs>
      <clipPath id="clip0_155_1912">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </BaseSVGIcon>
);
