import { ModalProps } from 'components/Modal';

import { Stake } from 'types';

export interface StakeModalProps extends ModalProps {
  // TODO: change to IStakes
  selectedStakeData: Stake;
  balance?: string | number;
  onClose: () => void;
  open: boolean;
}

export enum RadioStates {
  stake = 'stake',
  unstake = 'unstake',
}
