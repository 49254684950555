import { LinkProps } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

import {
  COLOR_BG_DARK_7, COLOR_MINT_100,
  COLOR_MINT_300, COLOR_MINT_600,
  COLOR_MINT_700,
} from 'theme/colors';

export const getMuiIconButton = (): Overrides['MuiIconButton'] => ({
  root: {
    width: 46,
    height: 46,
    borderRadius: '100%',
    background: COLOR_BG_DARK_7,
    '&:hover, &:active': {
      background: COLOR_MINT_300,
    },
    '&$disabled': {
      opacity: 0.5,
      color: COLOR_MINT_700,
      backgroundColor: 'transparent',
    },
  },
  colorPrimary: {
    border: `1px solid ${COLOR_MINT_600}`,
    color: COLOR_MINT_700,
    width: 40,
    height: 40,
    '&:hover, &:active': {
      borderColor: COLOR_MINT_300,
      '& > *': {
        '& > *': {
          '& > *': {
            fill: COLOR_MINT_300,
          },
        },
      },
    },
    '&$disabled': {
      opacity: 0.25,
      backgroundColor: COLOR_MINT_300,
    },
    '& > *': {
      '& > *': {
        '& > *': {
          fill: COLOR_MINT_100,
        },
      },
    },
  },
  colorSecondary: {
    color: COLOR_MINT_700,
    background: 'transparent',
    width: 32,
    height: 32,
    '&:hover': {
      borderWidth: 0,
      backgroundColor: COLOR_MINT_600,
      '& > *': {
        '& > *': {
          '& > *': {
            fill: COLOR_MINT_300,
          },
        },
      },
    },
    '&:active': {
      borderWidth: 0,
      background: COLOR_MINT_600,
    },
    '&$disabled': {
      opacity: 0.5,
      borderWidth: 0,
      borderColor: COLOR_MINT_700,
    },
    '& > *': {
      '& > *': {
        '& > *': {
          fill: COLOR_MINT_100,
        },
      },
    },
  },
});

export const getMuiIconButtonDefaultProps = (): LinkProps => ({ });
