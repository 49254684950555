import {
  select, put, takeLatest, call, delay,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { nftDropAbi } from 'config/abi';
import { setNotification } from 'utils';
import { getTokensBalanceSaga } from 'store/user/sagas/getTokensBalance';
import { DELAY_BEFORE_UPDATE } from 'config/constants';
import { stakeToNftDrop } from '../actions';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';
import userActionTypes from '../../user/actionTypes';
import { approveSaga } from './approveSaga';
import { updatePoolSaga } from './updatePool';
import { getNftDropsDataSaga } from './getNftDrops';

export function* stakeToNftDropSaga({
  type,
  payload: {
    web3Provider,
    stakingToken0Address,
    stakingToken1Address,
    stakingToken0AmountToStake,
    stakingToken1AmountToStake,
    poolAddress,
    id,
  },
}: ReturnType<typeof stakeToNftDrop>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const nftDropContract = yield new web3Provider.eth.Contract(
    nftDropAbi,
    poolAddress,
  );

  try {
    yield* call(approveSaga, {
      type: actionTypes.APPROVE,
      payload: {
        web3Provider,
        spender: poolAddress,
        approveTokenAddress: stakingToken0Address,
        amount: stakingToken0AmountToStake,
      },
    });

    yield* call(approveSaga, {
      type: actionTypes.APPROVE,
      payload: {
        web3Provider,
        spender: poolAddress,
        approveTokenAddress: stakingToken1Address,
        amount: stakingToken1AmountToStake,
      },
    });

    yield* call(nftDropContract.methods.deposit().send, { from: myAddress });

    yield call(updatePoolSaga, {
      type: actionTypes.UPDATE_POOl,
      payload: { id },
    });

    yield delay(DELAY_BEFORE_UPDATE);

    yield call(getNftDropsDataSaga, {
      type: actionTypes.GET_NFT_DROPS_DATA,
      payload: { web3Provider },
    });

    yield call(getTokensBalanceSaga, {
      type: userActionTypes.GET_TOKENS_BALANCE,
      payload: {
        web3Provider,
        tokensAddresses: [
          stakingToken0Address,
          stakingToken1Address,
        ],
      },
    });

    setNotification({ type: 'success', message: 'Successfully staked' });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.STAKE_TO_NFT_DROP, stakeToNftDropSaga);
}
