import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_MINT_600, COLOR_MINT_800,
} from 'theme/colors';

import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(2),
  },
  infoBtnWrapper: {
    ...flexHelper('flex-end'),
    // padding: '0 11px',
  },
  infoBtn: {
    borderRadius: theme.spacing(1),
  },
  infoText: {
    background: COLOR_MINT_800,
    border: `1px solid ${COLOR_MINT_600}`,
    borderRadius: '13px',
    padding: '8px 31px 10px 16px',
    lineHeight: '24px',
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  infoTextTitle: {
    marginBottom: theme.spacing(1),
  },
  closeInfoBtn: {
    position: 'absolute',
    top: '8px',
    right: '7px',
    borderRadius: '50%',
    padding: 0,
    minWidth: 0,
    '& span': {
      margin: 0,
    },
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
}));
