import axios from 'core/axios';
import backendUrls from 'appConstants/backendUrls';
import {
  BackendChains,
  IEditDescriptionParameters,
  IPoolsParameters,
  IUpdatePoolParameters,
  TGetPoolsReturnedValue,
  IEditPoolDescription,
} from 'types';

const urlBlock = '/pools/staking';

export default {
  getPools: ({
    network,
    poolType,
  }: IPoolsParameters): Promise<{ data: TGetPoolsReturnedValue[] }> => axios.get(`/pools/${poolType.toLowerCase()}/`, {
    params: {
      network: BackendChains[network],
    },
  }),
  createPool: (data: FormData) => axios.post(backendUrls.createPool, data),
  getPoolsCount: (network: BackendChains) => axios.get(`${backendUrls.getPoolsCount}`, {
    params: {
      network,
    },
  }),
  editDescription: ({
    address,
    network,
    description,
  }: IEditDescriptionParameters) => axios.patch(`${urlBlock}/${address}`, {
    network: BackendChains[network],
    description,
  }),
  editPoolDescription: ({
    address,
    network,
    description,
    poolType,
    tx_hash,
  }: IEditPoolDescription) => axios.patch(`/pools/${poolType.toLowerCase()}/${address}/`, {
    network,
    description,
    tx_hash,
  }),
  updatePool: ({ id }: IUpdatePoolParameters) => axios.post('/pools/update_request/', {
    id,
  }),
};
