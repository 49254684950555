import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chains, UserState } from 'types';

const initialState: UserState = {
  address: '',
  chain: Chains.bsc,
  // TODO: mct balance is depreceated, delete it
  mctBalance: 0,
  isAdmin: false,
  provider: null,
  balances: {},
};

export type UpdateBalancesPayload = {
  [key: string]: string;
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state, action: PayloadAction<Partial<UserState>>) => ({
      ...state, ...action.payload,
    }),
    updateIsAdmin: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAdmin: action.payload,
    }),
    updateBalance: (state, action: PayloadAction<number>) => ({
      ...state,
      mctBalance: action.payload,
    }),
    updateBalances: (state, action: PayloadAction<UpdateBalancesPayload>) => ({
      ...state,
      balances: { ...state.balances, ...action.payload },
    }),
    changeChain: (state, action: PayloadAction<Chains>) => ({
      ...state,
      chain: action.payload,
    }),
    disconnectWalletState: (state) => {
      localStorage.removeItem('walletconnect');
      return {
        ...initialState,
        chain: state.chain,
      };
    },
  },
});

export const {
  updateUserState,
  disconnectWalletState,
  changeChain,
  updateBalance,
  updateIsAdmin,
  updateBalances,
} = userReducer.actions;

export default userReducer.reducer;
