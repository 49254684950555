import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_BG_DARK_5, COLOR_MINT_300, COLOR_MINT_600, COLOR_MINT_800,
} from 'theme/colors';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      minWidth: 920,
      maxWidth: 1040,
      background: COLOR_BG_DARK_5,
      [formatMedia.BREAKPOINT_DESKTOP]: {
      },
    },
    tableTitleBlock: {
      ...flexHelper(),
      padding: theme.spacing(2, 4),
      borderBottom: `1px solid ${COLOR_MINT_300}`,
    },
    infoBtnWrapper: {
      ...flexHelper('flex-end'),
      padding: '0 11px',
    },
    infoBtn: {
      borderRadius: theme.spacing(1),
      '& > *': {
        '& > *': {
          fill: `${COLOR_MINT_300} !important`,
        },
      },
    },
    infoText: {
      background: COLOR_MINT_800,
      border: `1px solid ${COLOR_MINT_600}`,
      borderRadius: '13px',
      padding: '15px 18px 23px 25px',
      lineHeight: '24px',
      position: 'relative',
    },
    infoTextTitle: {
      marginBottom: theme.spacing(1),
    },
    closeInfoBtn: {
      position: 'absolute',
      top: '16px',
      right: '18px',
      borderRadius: '50%',
      padding: 0,
      minWidth: 0,
      '& span': {
        margin: 0,
      },
    },
    tableContainer: {
      padding: theme.spacing(2, 4, 0, 4),
    },
    paginationContainer: {
      borderTop: `1px solid ${COLOR_MINT_600}`,
      padding: `${theme.spacing(1, 4)} !important`,
    },
    spinnerContainer: {
      position: 'relative',
      marginRight: 'auto',
    },
    spinner: {
      transform: 'scale(0.3)',
      position: 'absolute',
      right: -75,
      top: 0,
    },
  });
});
