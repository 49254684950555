import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_MINT_600, COLOR_MINT_800, COLOR_MINT_200 } from 'theme/colors';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    input: {
      width: 0,
      height: 0,
    },

    root: {
      [formatMedia.BREAKPOINT_TABLET]: {},
      width: 'fit-content',
      cursor: 'pointer',
      ...flexHelper('flex-start', 'center'),
    },

    preview: {
      background: COLOR_MINT_800,
      border: `1px solid ${COLOR_MINT_600}`,
      overflow: 'hidden',
      transition: '0.1s ease',
      ...flexHelper('center', 'center'),
      '&:hover': {
        borderColor: COLOR_MINT_200,
      },
    },

    error: {
      borderColor: 'red',
    },

    previewImg: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    small: {
      minWidth: 56,
      width: 56,
      height: 56,
      borderRadius: '50%',
    },

    large: {
      minWidth: 121,
      width: 121,
      height: 121,
      borderRadius: 14,
    },

    label: {
      marginLeft: theme.spacing(1.5),
      transition: '0.1s ease',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });
});
