import React, { ReactNode } from 'react';
import { ActiveIcon, UpcomingIcon, CompleteIcon } from 'theme/icons';
import { Status } from 'types';

const iconHelper = {
  active: <ActiveIcon />,
  upcoming: <UpcomingIcon />,
  completed: <CompleteIcon />,
};

export const getStatusIcon = (status : Status): ReactNode => iconHelper[status];
