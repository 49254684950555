import BigNumber from 'bignumber.js';
import { RoundingModes } from 'types';
import { formatNumber } from './numberFormatter';

/**
 * @example 1 -> 1000000000000000000
 */
export const getTokenAmount = (
  balance: string | number,
  decimals = 18,
  shouldForamateNumber = false,
): string => {
  if (balance === '') {
    return '0';
  }

  if (typeof balance === 'number') {
    balance.toString();
  }

  const displayValue = new BigNumber(balance).multipliedBy(new BigNumber(10).pow(decimals));

  if (shouldForamateNumber) {
    return formatNumber(+displayValue.toString());
  }

  return displayValue.toString(10);
};

/**
 * @example 1000000000000000000 -> 1
 */
export const getTokenAmountDisplay = (
  balance: string | number,
  decimals = 18,
  roundingMode?: RoundingModes,
): string => {
  if (balance === '') {
    return '0';
  }

  if (typeof balance === 'number') {
    balance.toString();
  }

  const displayValue = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals));

  if(roundingMode === undefined) {
    return parseFloat(displayValue.toFixed(4)).toString();
  }
  return parseFloat(displayValue.toFixed(4, roundingMode)).toString();
};
