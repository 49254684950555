import React, { VFC } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useStyles } from './TotalEarned.styles';

interface TotalEarnedProps {
  amount?: string | number;
}

export const TotalEarned: VFC<TotalEarnedProps> = ({ amount = '0' }) => {
  const classes = useStyles();

  return (
    <Box className={classes.totalEarned}>
      <Typography className={classes.title} variant="body2">Total rewards earned</Typography>
      <Typography>{amount} MCT</Typography>
    </Box>
  );
};
