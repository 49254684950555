import React, { useEffect } from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { BreakpointsProvider } from 'hooks/useBreakpoints';
import { theme } from 'theme';
import { Layout, Routes } from 'containers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    const root = document.getElementById('root');
    root.classList.remove('preloader');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BreakpointsProvider>
        <StylesProvider>
          <CssBaseline />
          <ToastContainer
            autoClose={4000}
            hideProgressBar
            position="top-right"
            closeButton={false}
          />
          <Layout>
            <Routes />
          </Layout>
        </StylesProvider>
      </BreakpointsProvider>
    </ThemeProvider>
  );
}

export default App;
