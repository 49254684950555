/* eslint-disable max-len */
import {
  all, put, select, takeEvery,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { erc20Abi } from 'config/abi';
import { updateBalances } from 'store/user/reducer';
import { getTokenAmountDisplay } from '../../../utils/formatTokenAmount';
import userSelector from '../selectors';
import actionTypes from '../actionTypes';
import { getTokensBalance } from '../actions';

export function* getTokensBalanceSaga({
  type,
  payload: { web3Provider, tokensAddresses },
}: ReturnType<typeof getTokensBalance>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  try {
    const tokenContract = yield new web3Provider.eth.Contract(erc20Abi);

    const balancePromises = tokensAddresses.map(
      (tokenAddress) => {
        tokenContract.options.address = tokenAddress;
        return tokenContract.methods.balanceOf(myAddress).call();
      },
    );
    const decimalsPromises = tokensAddresses.map(
      (tokenAddress) => {
        tokenContract.options.address = tokenAddress;
        return tokenContract.methods.decimals().call();
      },
    );

    const balancesAndDecimals = yield all([].concat(balancePromises, decimalsPromises));
    const balances: Array<string> = balancesAndDecimals.slice(0, tokensAddresses.length);
    const decimals: Array<string> = balancesAndDecimals.slice(tokensAddresses.length);

    const balancesObject = {};
    for (let i = 0; i < tokensAddresses.length; i += 1) {
      balancesObject[tokensAddresses[i]] = getTokenAmountDisplay(
        balances[i],
        +decimals[i],
      );
    }

    yield put(
      updateBalances(balancesObject),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.GET_TOKENS_BALANCE, getTokensBalanceSaga);
}
