import ui from './ui/reducer';
import user from './user/reducer';
import stakes from './stakes/reducer';
import successModal from './successModal/reducer';
import descriptionModal from './descriptionModal/reducer';

export default {
  ui,
  user,
  stakes,
  successModal,
  descriptionModal,
};
