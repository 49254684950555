import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import {
  COLOR_MINT_100, COLOR_MINT_200, COLOR_MINT_700, COLOR_DARK,
} from 'theme/colors';
import { flexHelper } from 'utils';
import { ExternalLinkBoxVariants } from './ExternalLinkBox';

export const useStyles = makeStyles<Theme, { variant: ExternalLinkBoxVariants }>((theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      padding: theme.spacing(1),
      background: ({ variant }) => (variant === 'dark' ? COLOR_DARK : COLOR_MINT_700),
      borderRadius: theme.spacing(0.5),

      [formatMedia.BREAKPOINT_TABLET]: {},
    },
    linkContainer: {
      ...flexHelper('space-between', 'center'),
      overflow: 'hidden',
    },
    link: {
      flexBasis: 'calc(100% - 24px - 8px)',
      color: ({ variant }) => (variant === 'dark' ? COLOR_MINT_100 : COLOR_MINT_200),
    },
    icon: {
      width: 24,
      height: 24,
      flexBasis: '24px',
    },
  });
});
