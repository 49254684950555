import React from 'react';

import { COLOR_MINT_600 } from 'theme/colors';
import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const LaunchIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="17" height="17" viewBox="0 0 17 17" fill={COLOR_MINT_600} {...props}>
    <path d="M6.65839 3.29147C4.95839 5.1998 3.79172 7.94146 3.68339 8.1998L1.80005 7.39147C1.25839 7.15813 1.12505 6.4498 1.54172 6.03313L4.05005 3.5248C4.44172 3.13313 5.00839 2.95813 5.55839 3.06647L6.65839 3.29147ZM7.90005 11.7581C8.15005 12.0081 8.51672 12.0748 8.83339 11.9248C9.80005 11.4748 11.8751 10.4165 13.2167 9.0748C17.0417 5.2498 17.0751 2.13313 16.8501 0.799801C16.8206 0.636021 16.7417 0.485178 16.624 0.367505C16.5063 0.249833 16.3555 0.17096 16.1917 0.141467C14.8584 -0.0835325 11.7417 -0.0501993 7.91672 3.7748C6.57505 5.11647 5.52505 7.19147 5.06672 8.15813C4.91672 8.4748 4.99172 8.8498 5.23339 9.09146L7.90005 11.7581ZM13.7084 10.3415C11.8001 12.0415 9.05839 13.2081 8.80005 13.3165L9.60839 15.1998C9.84172 15.7415 10.5501 15.8748 10.9667 15.4581L13.4751 12.9498C13.8667 12.5581 14.0417 11.9915 13.9334 11.4415L13.7084 10.3415ZM6.45005 12.5081C6.53086 12.9117 6.51077 13.329 6.39156 13.723C6.27236 14.1169 6.05773 14.4754 5.76672 14.7665C5.12505 15.4081 3.13339 15.8831 1.84172 16.1331C1.26672 16.2415 0.758388 15.7331 0.866721 15.1581C1.11672 13.8665 1.58339 11.8748 2.23339 11.2331C2.52447 10.9421 2.88291 10.7275 3.27687 10.6083C3.67083 10.4891 4.08813 10.469 4.49172 10.5498C5.46672 10.7331 6.26672 11.5331 6.45005 12.5081ZM9.83339 5.4998C9.83339 4.58313 10.5834 3.83313 11.5001 3.83313C12.4167 3.83313 13.1667 4.58313 13.1667 5.4998C13.1667 6.41647 12.4167 7.16647 11.5001 7.16647C10.5834 7.16647 9.83339 6.41647 9.83339 5.4998Z" fill="#A4F8DE" />
  </BaseSVGIcon>
);
