import { isMainnet } from 'config/constants';
import { Chains } from 'types';

type LinkToScanerConfig = {
  chain: Chains;
  hash: string;
  type?: 'tx' | 'address' | 'token';
  params?: string;
};

export const getLinkToScaner = ({
  chain,
  hash,
  type = 'address',
  params = '',
}: LinkToScanerConfig) => {
  switch (chain) {
    case Chains.bsc:
      return `https://${
        isMainnet ? '' : 'testnet.'
      }bscscan.com/${type}/${hash}${params}`;

    case Chains.ethereum:
      return `https://${
        isMainnet ? '' : 'kovan.'
      }etherscan.io/${type}/${hash}${params}`;

    default:
      return '';
  }
};
