import React, {
  useCallback, useMemo, useState, VFC,
} from 'react';

import {
  Accordion,
  AccordionDetails,
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { ArrowLeftIcon, StakedIcon } from 'theme/icons';
import { getStatusIcon } from 'utils/getStatusIcon';
import { IStakes, Status } from 'types';
import { Copyable } from 'components/Copyable';
import { formatLongAmount, formattedDate } from 'utils';
import { useStyles } from './StakeCard.styles';
import { DescriptionHelper } from '../DescriptionHelper';

export interface StakeCardProps {
  stakeData: IStakes;
  onStakeClick: (stakeId: number) => void;
  utc?: string;
  className?: string;
}

export const StakeCard: VFC<StakeCardProps> = ({
  stakeData: {
    name,
    apr,
    startDate,
    endDate,
    stakingToken0Staked,
    maxGoalOfStaking,
    maxStakeAmount,
    status,
    poolAddress,
    userStakedToken0Amount,
    description,
    poolType,
    txHash,
    id,
    network,
    stakingToken0Link,
  },
  utc = '00:00',
  onStakeClick,
  className,
}) => {
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  const classes = useStyles({ isDetailsOpen });

  const toggleDetailsOpen = useCallback(() => {
    setDetailsOpen(!isDetailsOpen);
  }, [isDetailsOpen]);

  const isStakeButtonDisabled = useMemo(
    () => (status.label === Status.completed && userStakedToken0Amount === '0') ||
    status.label === Status.upcoming,
    [status.label, userStakedToken0Amount],
  );

  const stakeButtonName = useMemo(
    () => (status.label === Status.completed ? 'Unstake' : 'Stake'),
    [status.label],
  );

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.infoItem}>
          <Box className={clsx(classes.textWithIcon, classes.infoItemTitle)}>
            <Typography
              variant="body1"
              className="mint"
            >
              Pool name
            </Typography>
            <DescriptionHelper
              description={description}
              address={poolAddress}
              txHash={txHash}
              poolType={poolType}
              network={network}
            />
          </Box>
          <Typography variant="body1">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.infoItem}>
          <Typography
            variant="body1"
            className={clsx(classes.infoItemTitle, 'x', 'mint')}
          >
            Total staked
          </Typography>
          <Box className={classes.textWithIcon}>
            <StakedIcon />
            <Typography variant="body1">{formatLongAmount(stakingToken0Staked, 7)}</Typography>
          </Box>
          {isDetailsOpen && stakingToken0Link && (
            <Button variant="contained" href={stakingToken0Link} target="_blank" className={classes.buyStakingToken}>Buy now</Button>
          )}
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.infoItem}>
          <Typography
            variant="body1"
            className={clsx(classes.infoItemTitle, 'x', 'mint')}
          >
            APR
          </Typography>
          <Typography variant="body1">{`${apr}%`}</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.infoItem}>
          <Typography
            variant="body1"
            className={clsx(classes.infoItemTitle, 'x', 'mint')}
          >
            Status
          </Typography>
          <Box className={clsx(classes.textWithIcon)}>
            {getStatusIcon(status.label)}
            <Typography className="capitalize">{status.label}</Typography>
          </Box>
        </Grid>
        <Accordion className={classes.detailsContainer} expanded={isDetailsOpen}>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.infoItem}>
                <Typography
                  variant="body1"
                  className={clsx(classes.infoItemTitle, 'x', 'mint')}
                >
                  Start date
                </Typography>
                <Typography variant="body1">{formattedDate(new Date(+startDate * 1000))}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.infoItem}>
                <Typography
                  variant="body1"
                  className={clsx(classes.infoItemTitle, 'x', 'mint')}
                >
                  End date
                </Typography>
                <Typography variant="body1">{formattedDate(new Date(+endDate * 1000))}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.infoItem}>
                <Typography
                  variant="body1"
                  className={clsx(classes.infoItemTitle, 'x', 'mint')}
                >
                  UTC
                </Typography>
                <Typography>{utc}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.infoItem}>
                <Typography
                  variant="body1"
                  className={clsx(classes.infoItemTitle, 'x', 'mint')}
                >
                  Total goal
                </Typography>
                <Typography variant="body1">{maxGoalOfStaking}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.infoItem}>
                <Typography
                  variant="body1"
                  className={clsx(classes.infoItemTitle, 'x', 'mint')}
                >
                  Maximum stake
                </Typography>
                <Typography variant="body1">{maxStakeAmount}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.infoItem}>
                <Typography
                  variant="body1"
                  className={clsx(classes.infoItemTitle, 'x', 'mint')}
                >
                  Pool address
                </Typography>
                <Copyable
                  onlyIconActive
                  transparent
                  valueToCopy={poolAddress}
                  zeroPadding
                >
                  <Typography noWrap>{poolAddress}</Typography>
                </Copyable>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <Button
            size="large"
            onClick={toggleDetailsOpen}
            endIcon={<ArrowLeftIcon />}
            className={classes.openDetailsBtn}
            variant="text"
            fullWidth
          >
            Details
          </Button>
        </Grid>
        {isDetailsOpen && (
          <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
            <Button
              onClick={() => onStakeClick(id)}
              size="small"
              className={classes.actionButton}
              fullWidth
              disabled={isStakeButtonDisabled}
            >
              {stakeButtonName}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
