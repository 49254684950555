import {
  select, put, takeLatest, call, delay,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { nftPoolAbi } from 'config/abi';
import { setNotification } from 'utils';
import { getTokensBalanceSaga } from 'store/user/sagas/getTokensBalance';
import { DELAY_BEFORE_UPDATE } from 'config/constants';
import { stakeToNftPool } from '../actions';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';
import { approveSaga } from './approveSaga';
import { approveNftsSaga } from './approveNfts';
import { updatePoolSaga } from './updatePool';
import { getNfrPoolsDataSaga } from './getNftPools';
import userActionTypes from '../../user/actionTypes';

export function* stakeToNftPoolSaga({
  type,
  payload: {
    web3Provider,
    stakingToken0Address,
    stakingToken1Address,
    stakingToken0AmountToStake,
    poolAddress,
    id,
  },
}: ReturnType<typeof stakeToNftPool>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const nftPoolContract = yield new web3Provider.eth.Contract(
    nftPoolAbi,
    poolAddress,
  );

  try {
    yield* call(approveSaga, {
      type: actionTypes.APPROVE,
      payload: {
        web3Provider,
        spender: poolAddress,
        approveTokenAddress: stakingToken0Address,
        amount: stakingToken0AmountToStake,
      },
    });

    yield* call(approveNftsSaga, {
      type: actionTypes.APPROVE_NFTS,
      payload: {
        web3Provider,
        operator: poolAddress,
        collectionAddress: stakingToken1Address,
        isApprove: true,
      },
    });

    yield* call(nftPoolContract.methods.deposit().send, { from: myAddress });

    yield call(updatePoolSaga, {
      type: actionTypes.UPDATE_POOl,
      payload: { id },
    });

    yield delay(DELAY_BEFORE_UPDATE);

    yield call(getNfrPoolsDataSaga, {
      type: actionTypes.GET_NFT_POOLS_DATA,
      payload: { web3Provider },
    });

    yield call(getTokensBalanceSaga, {
      type: userActionTypes.GET_TOKENS_BALANCE,
      payload: { web3Provider, tokensAddresses: [stakingToken0Address] },
    });

    setNotification({ type: 'success', message: 'Successfully staked' });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.STAKE_TO_NFT_POOL, stakeToNftPoolSaga);
}
