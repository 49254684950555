/* eslint-disable max-len */
import { put, call, takeLatest } from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { poolsApi } from 'services';
import { BackendChains } from 'types';
import actionTypes from '../actionTypes';
import { getPoolsCount } from '../actions';
import { updateStakes } from '../reducer';

export function* getPoolsCountSaga({
  type,
  payload: { chain },
}: ReturnType<typeof getPoolsCount>) {
  yield put(apiActions.request(type));

  try {
    const networkForBackend = BackendChains[chain];

    const { data: poolsCount } = yield call(
      poolsApi.getPoolsCount,
      networkForBackend,
    );
    yield put(updateStakes({ poolsCount }));

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_POOLS_COUNT, getPoolsCountSaga);
}
