import React, { FC } from 'react';

import { Box } from '@material-ui/core';

import { Header, GlobalLoader } from 'containers';
import { lightShadow } from 'assets/images';
import { useStyles } from './Layout.styles';

export const Layout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <GlobalLoader />
      <Box className={classes.root}>
        <Box className={classes.content}>
          <img src={lightShadow} alt="shadow" className={classes.mintBlob} />
          <Header />
          {children}
        </Box>
      </Box>
    </>
  );
};
