import React from 'react';

import { COLOR_MINT_400 } from 'theme/colors';
import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const ImageIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon width="24" height="24" fill={COLOR_MINT_400} viewBox="0 0 24 24" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 10C8.828 10 9.5 9.328 9.5 8.5C9.5 7.672 8.828 7 8 7C7.172 7 6.5 7.672 6.5 8.5C6.5 9.328 7.172 10 8 10ZM18 18.9995H6.56104L13.566 13.1545C13.812 12.9455 14.258 12.9465 14.499 13.1535L19 16.9935V17.9995C19 18.5515 18.552 18.9995 18 18.9995ZM6 5H18C18.552 5 19 5.448 19 6V14.364L15.797 11.632C14.807 10.79 13.258 10.79 12.277 11.626L5 17.698V6C5 5.448 5.448 5 6 5ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 21 19.654 21 18V6C21 4.346 19.654 3 18 3Z" fill="#84D0B9" />
    <mask id="mask0_1477_7752" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 10C8.828 10 9.5 9.328 9.5 8.5C9.5 7.672 8.828 7 8 7C7.172 7 6.5 7.672 6.5 8.5C6.5 9.328 7.172 10 8 10ZM18 18.9995H6.56104L13.566 13.1545C13.812 12.9455 14.258 12.9465 14.499 13.1535L19 16.9935V17.9995C19 18.5515 18.552 18.9995 18 18.9995ZM6 5H18C18.552 5 19 5.448 19 6V14.364L15.797 11.632C14.807 10.79 13.258 10.79 12.277 11.626L5 17.698V6C5 5.448 5.448 5 6 5ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 21 19.654 21 18V6C21 4.346 19.654 3 18 3Z" />
    </mask>
    <g mask="url(#mask0_1374_7674)" />
  </BaseSVGIcon>
);
