import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { COLOR_BG_DARK_5, COLOR_MINT_600, COLOR_MINT_700 } from 'theme/colors';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const greenContainer = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: 80,
    padding: theme.spacing(3),
    background: COLOR_MINT_700,
    border: `1px solid ${COLOR_MINT_600}`,
    borderRadius: 14,
    marginBottom: theme.spacing(2),
  };

  const shortField = {
    '& > div': {
      width: '50%',
    },
    [theme.breakpoints.down(1024)]: {
      '& > div': {
        width: '100%',
      },
    },
  };

  return createStyles({
    form: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: 1040,
      background: COLOR_BG_DARK_5,
      border: `1px solid ${COLOR_MINT_600}`,
      backdropFilter: 'blur(30px)',
      padding: theme.spacing(4, 3),
      borderRadius: 20,

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
      },
    },
    container: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0, 2, 4, 2),
    },
    navigation: {
      padding: theme.spacing(1.5, 0),
      margin: theme.spacing(0, 'auto'),
      maxWidth: 1040,
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5, 2),
      },
    },
    homeButton: {
      marginRight: theme.spacing(1.5),
    },
    poolNameTitle: {
      marginBottom: theme.spacing(4.5),
    },

    submitButtonContainer: {
      ...flexHelper('flex-end'),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    submitButton: {
      marginLeft: theme.spacing(4),
      maxWidth: 159,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(2),
      },
    },

    // COMMON FORMS STYLES
    formInner: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: 80,
      rowGap: 15,
      [theme.breakpoints.down(1024)]: {
        display: 'block',
      },
    },
    field: {
      marginBottom: theme.spacing(2.5),
    },

    title: {
      marginBottom: theme.spacing(1.5),
    },

    poolName: {
      gridArea: 'poolName',
    },

    description: {
      gridArea: 'description',
    },

    stakingTokenAddress: {
      gridArea: 'stakingTokenAddress',
    },
    stakingTokenLogo: {
      gridArea: 'stakingTokenLogo',
    },
    stakingToken2Address: {
      gridArea: 'stakingToken2Address',
    },
    stakingToken2Logo: {
      gridArea: 'stakingToken2Logo',
    },
    rewardTokenAddress: {
      gridArea: 'rewardTokenAddress',
    },
    rewardTokenLogo: {
      gridArea: 'rewardTokenLogo',
    },

    datesContainer: {
      gridArea: 'dates',
    },
    dates: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 25,
      marginTop: 37,
      [theme.breakpoints.down(1024)]: {
        display: 'block',
        marginTop: 0,
      },
    },

    startDate: {
    },
    endDate: {},

    earlyUnstakePenalty: {
      gridArea: 'earlyUnstakePenalty',
      '& > div': {
        width: '50%',
      },
      ...shortField,
    },
    stakingTokenLink: {
      gridArea: 'stakingTokenLink',
    },
    stakingToken1Link: {
      gridArea: 'stakingToken1Link',
    },

    // ===== FORMS STYLES =====
    // ===== MINING POOL ======
    miningPoolForm: {
      gridTemplateAreas: `
      "poolName ."
      "addresses addresses"
      "rewardsPerSecond minimumStakingPeriod"
      "dates earlyUnstakePenalty"
      "description description"
      "stakingTokenLink ."
      `,
    },
    rewardsPerSecond: {
      gridArea: 'rewardsPerSecond',
      '& > div': {
        width: '50%',
      },
      ...shortField,
    },
    minimumStakingPeriod: {
      gridArea: 'minimumStakingPeriod',
      ...shortField,
    },

    addressesContainer: {
      gridArea: 'addresses',
    },

    addresses: {
      ...greenContainer,
      [theme.breakpoints.down(1024)]: {
        display: 'block',
      },
    },

    // ====== MCT POOL =====
    mctPoolForm: {
      gridTemplateAreas: `
      "poolName maximumGoalOfStaking"
      "dates maximumStakeAmountPerWallet"
      "apr earlyUnstakePenalty"
      "description ."
      "stakingTokenLink ."
      `,
    },

    mctDates: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 25,
      [theme.breakpoints.down(1024)]: {
        display: 'block',
      },
    },

    maximumGoalOfStaking: {
      gridArea: 'maximumGoalOfStaking',
    },

    maximumStakeAmountPerWallet: {
      gridArea: 'maximumStakeAmountPerWallet',
    },

    apr: {
      gridArea: 'apr',
      maxWidth: 214,
    },
    // NFT DROP
    nftDropForm: {
      gridTemplateAreas: `
      "poolName ."
      "tokens tokens"
      "nftContainer nftContainer"
      "stakingTimeContainer ."
      "nftDropStartDate ."
      "description description"
      "stakingTokenLink stakingToken1Link"
      `,
    },
    nftDropStartDate: {
      gridArea: 'nftDropStartDate',
      maxWidth: 200,
    },
    tokensContainer: {
      gridArea: 'tokens',
    },
    tokens: {
      ...greenContainer,
      [theme.breakpoints.down(600)]: {
        display: 'block',
      },
    },

    nftInfoContainer: {
      gridArea: 'nftContainer',
    },

    nftInfo: {
      ...greenContainer,
      [theme.breakpoints.down(600)]: {
        display: 'block',
      },
    },

    stakingTokenAmount: {
      ...shortField,
    },

    stakingToken2Amount: {
      ...shortField,
    },

    nftDropRewardTokenAmount: {
      ...shortField,
      marginTop: 27,
      [theme.breakpoints.down(1024)]: {
        marginTop: 0,
      },
    },

    stakingTimeContainer: {
      gridArea: 'stakingTimeContainer',
    },
    stakingTime: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: 35,
      [theme.breakpoints.down(1024)]: {
        display: 'block',
      },
    },

    // ==== TOKEN DROP FORM ====
    tokenDropForm: {
      gridTemplateAreas: `
      "poolName ."
      "tokenDropTokens tokenDropTokens"
      "rewardsPerSecond minimumStakingPeriod"
      "dates earlyUnstakePenalty"
      "description description"
      "stakingTokenLink stakingToken1Link"
      `,
    },

    stakingRateContainer: {
      gridArea: 'stakingRate',
      marginBottom: theme.spacing(4),
    },

    stakingRate: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 20,
      alignItems: 'flex-end',

      [theme.breakpoints.down(750)]: {
        display: 'block',
      },
    },

    tokenDropTokensContainer: {
      gridArea: 'tokenDropTokens',
    },
    tokenDropTokens: {
      ...greenContainer,
      gridTemplateAreas: `
      "stakingTokenAddress stakingToken2Address"
      "stakingTokenLogo stakingToken2Logo"
      "stakingRate ."
      "rewardTokenAddress ."
      "rewardTokenLogo ."
      `,

      [theme.breakpoints.down(750)]: {
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `
        "stakingTokenAddress"
        "stakingTokenLogo"
        "stakingToken2Address"
        "stakingToken2Logo"
        "stakingRate"
        "rewardTokenAddress"
        "rewardTokenLogo"
        `,
      },
    },

    // ====== MAIN POOL FORM ========
    mainPoolForm: {
      gridTemplateAreas: `
      "poolName ."
      "tokenDropTokens tokenDropTokens"
      "radioGroup ."
      "rewardsPerSecond minimumStakingPeriod"
      "dates earlyUnstakePenalty"
      "description description"
      "stakingTokenLink stakingToken1Link"
      `,
    },
    radioGroup: {
      gridArea: 'radioGroup',
    },

    // ===== NFT POOL FORM ========
    nftPoolForm: {
      gridTemplateAreas: `
      "poolName ."
      "nftPoolTokens nftPoolTokens"
      "stakingTimeContainer ."
      "nftPoolDateAndAmount ."
      "description description"
      "stakingTokenLink stakingToken1Link"
      `,
    },

    nftPoolDateAndAmountContainer: {
      gridArea: 'nftPoolDateAndAmount',
    },
    nftPoolDateAndAmount: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: 35,
      [theme.breakpoints.down(1024)]: {
        display: 'block',
      },
    },

    nftPoolTokensContainer: {
      gridArea: 'nftPoolTokens',
    },
    nftPoolTokens: {
      ...greenContainer,
      [theme.breakpoints.down(850)]: {
        display: 'block',
      },
    },

    nftPoolStartDate: {
      marginTop: 35,
      [theme.breakpoints.down(1024)]: {
        marginTop: 0,
      },
    },

    nftPoolTokensRight: {
      marginTop: 188,
      [theme.breakpoints.down(850)]: {
        marginTop: 0,
      },
    },

    stakingToken2Id: {
      width: 121,
    },
    rewardTokenId: {
      width: 121,
    },
    rewardTokenAmount: {
      ...shortField,
    },
    rewardHelperText: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
        textAlign: 'center',
      },
    },
  });
});
