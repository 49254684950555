import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_MINT_800,
  COLOR_MINT_600, COLOR_MINT_300, COLOR_MINT_700,
} from 'theme/colors';
import { maxRowsOverflowHelper } from 'utils';

export const useStyles = makeStyles<Theme, { isDetailsOpen: boolean }>(
  (theme: Theme) => createStyles({
    root: {
      minWidth: 0,
      borderRadius: theme.spacing(2.5),
      padding: theme.spacing(3, 0),
      border: `1px solid ${COLOR_MINT_600}`,
      background: COLOR_MINT_800,
      boxSizing: 'border-box',
      marginBottom: theme.spacing(2),
    },
    padding: {
      padding: theme.spacing(0, 3),
    },
    detailsContainer: {
      transition: '200ms',
      height: ({ isDetailsOpen }) => (isDetailsOpen ? 'fit-content' : 0),
      overflow: 'hidden',
    },
    detailsInner: {
      width: '100%',
    },
    flex: {
      display: 'flex',
    },
    marginRight: {
      marginRight: theme.spacing(1),
    },
    poolName: {
      ...maxRowsOverflowHelper(),
      [theme.breakpoints.down(500)]: {
        display: 'inline-block',
        maxWidth: '160px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
    marginBottom: {
      marginBottom: theme.spacing(3),
    },
    label: {
      marginBottom: theme.spacing(1),
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 24,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down(400)]: {
        gridTemplateColumns: '1fr',
      },
    },
    tokenLogo: {
      width: 24,
      height: 24,
      borderRadius: '50%',
    },
    nftPreviewContainer: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(1.5),
    },
    nftPreview: {
      width: 288,
      height: 288,
      objectFit: 'cover',
      borderRadius: 14,
    },
    buttonIcon: {
      transform: 'rotate(-90deg)',
    },
    buttonIconActive: {
      transform: 'rotate(90deg)',
    },
    stakeButton: {
      marginLeft: theme.spacing(2),
    },
    buyStakingToken: {
      marginTop: theme.spacing(1),
      fontSize: '14px',
      linHeight: '143%',
      borderRadius: '154px',
      padding: '6px 21px',
      background: `${COLOR_MINT_300} !important`,
      color: `${COLOR_MINT_700} !important`,
      whiteSpace: 'nowrap',
      textTransform: 'none',
    },
  }),
);
