import { CreateCSSProperties, CSSProperties } from '@material-ui/styles';

/**
 * @param justifyContent default 'center'
 * @param alignItems default 'center'
 */
export const flexHelper = <T extends object = {}>(justifyContent = 'center', alignItems = 'center'): CSSProperties | CreateCSSProperties<T> => ({
  display: 'flex',
  justifyContent,
  alignItems,
});
