import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  COLOR_BG_DARK_7, COLOR_MINT_400,
  COLOR_MINT_600,
} from 'theme/colors';

import { getFormatMedia } from 'theme/utils';

export const useStyles = makeStyles<
Theme,
{ withBorder: boolean; transparent: boolean }
>((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {},
    },
    copy: {
    },
    withIcon: {},
    icon: {
      position: 'absolute',
      right: theme.spacing(2),
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      '&:hover, &:active': {
        '& > *': {
          fill: COLOR_MINT_400,
        },
      },
    },
    copyableContainer: {
      position: 'relative',
      width: '100%',
      minWidth: 0,
      maxWidth: 'fit-content',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      background: ({ transparent }) => (transparent ? 'none' : COLOR_BG_DARK_7),
      border: ({ withBorder }) => (withBorder ? `1px solid ${COLOR_MINT_600}` : 'none'),
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(2, 6.5, 2, 2.5),
      cursor: 'pointer',
    },
  });
});
