import { createAction } from '@reduxjs/toolkit';
import {
  CheckIsAdminReq,
  GetTokenBalanceReq,
  GetTokensBalanceAction,
} from 'types/requests';
import actionTypes from './actionTypes';

export const getTokenBalance = createAction<GetTokenBalanceReq>(
  actionTypes.GET_TOKEN_BALANCE,
);
export const getTokensBalance = createAction<GetTokensBalanceAction>(
  actionTypes.GET_TOKENS_BALANCE,
);
export const checkIsAdmin = createAction<CheckIsAdminReq>(
  actionTypes.CHECK_IS_ADMIN,
);
