import { MILISECONDS_IN_DAY } from 'appConstants';
import * as Yup from 'yup';

export const TODAY = new Date(Date.now());
export const TOMORROW = new Date(Date.now() + MILISECONDS_IN_DAY);
export const DAY_AFTER_TOMORROW = new Date(Date.now() + MILISECONDS_IN_DAY * 2);

// TODO: uncomment line below after test
// export const startDateValidation = Yup.date().min(TODAY).required();
export const startDateValidation = Yup.date().required();
export const endDateValidation = Yup.date().when('startDate', (startDate) => Yup.date()
  .min(startDate)
  .test((endDate) => new Date(endDate) > new Date(startDate))
  .required());

export const minimumStakingPeriodValidation = Yup.number().integer('Number must be integer').when(
  ['endDate', 'startDate'],
  (endDate, startDate) => {
    const stakingPeriodInDays =
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / MILISECONDS_IN_DAY;
    return Yup.number().integer().min(1).max(stakingPeriodInDays)
      .required();
  },
);

export const earlyUnstakePenaltyValidation = Yup.number()
  .positive()
  .min(0)
  .max(100)
  .test((percent) => {
    const decimals = percent?.toString().split('.')[1];
    if (decimals?.length > 0) return false;
    return true;
  })
  .required();
