import { createAction } from '@reduxjs/toolkit';
import {
  StakeReq,
  UnstakeReq,
  ApproveReq,
  ApproveNftsReq,
  CreateNewPoolReq,
  GetMiningPoolsAction,
  GetNftDropsAction,
  GetMainPoolsAction,
  GetNftPoolsAction,
  GetTokenDropsAction,
  GetMctPoolsAction,
  StakeToNftPoolAction,
  UnstakeFromNftPoolAction,
  StakeToNftDropAction,
  UnstakeFromNftDropAction,
  UpdatePoolAction,
  StakeToMiningPoolAction,
  UnstakeFromMiningPoolAction,
  StakeToErc20PlusErc20PoolAction,
  GetMainPoolSecondTokenAmountAction,
  UnstakeFromErc20PlusErc20PoolAction,
  EditPoolDescriptionAction,
  GetPoolsCountAction,
} from 'types/requests';
import actionTypes from './actionTypes';

export const stake = createAction<StakeReq>(actionTypes.STAKE);
export const unstake = createAction<UnstakeReq>(actionTypes.UNSTAKE);
export const approve = createAction<ApproveReq>(actionTypes.APPROVE);
export const approveNfts = createAction<ApproveNftsReq>(
  actionTypes.APPROVE_NFTS,
);
export const createNewPool = createAction<CreateNewPoolReq>(
  actionTypes.CREATE_NEW_POOL,
);
export const getMiningPoolsData = createAction<GetMiningPoolsAction>(
  actionTypes.GET_MINING_POOLS_DATA,
);
export const getNftDropsData = createAction<GetNftDropsAction>(
  actionTypes.GET_NFT_DROPS_DATA,
);
export const getMainPoolsData = createAction<GetMainPoolsAction>(
  actionTypes.GET_MAIN_POOLS_DATA,
);
export const getNftPoolsData = createAction<GetNftPoolsAction>(
  actionTypes.GET_NFT_POOLS_DATA,
);
export const getTokenDropsData = createAction<GetTokenDropsAction>(
  actionTypes.GET_TOKEN_DROPS_DATA,
);
export const getMctPoolsData = createAction<GetMctPoolsAction>(
  actionTypes.GET_MCT_POOLS,
);

export const getPoolsCount = createAction<GetPoolsCountAction>(
  actionTypes.GET_POOLS_COUNT,
);

export const updatePool = createAction<UpdatePoolAction>(
  actionTypes.UPDATE_POOl,
);
export const getMainPoolSecondTokenAmount = createAction<GetMainPoolSecondTokenAmountAction>(
  actionTypes.GET_MAIN_POOL_SECOND_TOKEN_AMOUNT,
);
export const editPoolDescription = createAction<EditPoolDescriptionAction>(
  actionTypes.EDIT_POOL_DESCRIPTION,
);

// STAKE and UNSTAKE actions
export const stakeToNftPool = createAction<StakeToNftPoolAction>(
  actionTypes.STAKE_TO_NFT_POOL,
);
export const stakeToNftDrop = createAction<StakeToNftDropAction>(
  actionTypes.STAKE_TO_NFT_DROP,
);
export const stakeToMiningPool = createAction<StakeToMiningPoolAction>(
  actionTypes.STAKE_TO_MINING_POOL,
);
export const stakeToErc20PlusErc20Pool = createAction<StakeToErc20PlusErc20PoolAction>(
  actionTypes.STAKE_TO_ERC20_PLUS_ERC20,
);
export const unstakeFromNftPool = createAction<UnstakeFromNftPoolAction>(
  actionTypes.UNSTAKE_FROM_NFT_POOL,
);
export const unstakeFromNftDrop = createAction<UnstakeFromNftDropAction>(
  actionTypes.UNSTAKE_FROM_NFT_DROP,
);
export const unstakeFromMiningPool = createAction<UnstakeFromMiningPoolAction>(
  actionTypes.UNSTAKE_FROM_MINING_POOl,
);
export const unstakeFromErc20PlusErc20 = createAction<UnstakeFromErc20PlusErc20PoolAction>(
  actionTypes.UNSTAKE_FROM_ERC20_PLUS_ERC20,
);
