/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { Subscription } from 'rxjs';
import {
  Chains, State, UserState, WalletProviders,
} from 'types';
import { disconnectWalletState, updateUserState } from 'store/user/reducer';
import { useDispatch } from 'react-redux';
import userSelector from 'store/user/selectors';
import { useShallowSelector } from 'hooks';
import { provider as web3Provider } from 'web3-core';
import { chains } from 'config';
import Web3 from 'web3';
import { setNotification } from 'utils';
import { WalletService } from '../walletService';

interface IContextValue {
  connect: (provider: WalletProviders, chain: Chains) => Promise<void>;
  disconnect: () => void;
  walletService: WalletService;
  web3WithoutMetamask: (chain: Chains) => Web3;
}

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC = ({ children }) => {
  const [currentSubsriber, setCurrentSubsciber] = useState<Subscription>();
  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const {
    address,
    provider: userWalletProvider,
    chain: userChain,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const disconnect = useCallback(() => {
    dispatch(disconnectWalletState());
    localStorage.removeItem('walletconnect');
    WalletConnect.resetConnect();
    currentSubsriber?.unsubscribe();
    setCurrentSubsciber(null);
  }, [WalletConnect, currentSubsriber, dispatch]);

  const subscriberSuccess = useCallback(
    (data: any) => {
      if (document.visibilityState !== 'visible') {
        disconnect();
        return;
      }

      if (data.name === 'accountsChanged') {
        localStorage.removeItem('walletconnect');
        dispatch(updateUserState({ address: data.address }));
      }
    },
    [WalletConnect, disconnect, dispatch],
  );

  const subscriberError = useCallback(
    (err: any) => {
      console.error(err);
      if (err.code === 4) {
        // toast.error(
        //   'You changed to wrong network. Please choose Binance-Smart-Chain',
        // );
      }
    },
    [disconnect],
  );

  const connect = useCallback(
    async (provider: WalletProviders, chain: Chains) => {
      const connected = await WalletConnect.initWalletConnect(provider, chain);
      if (connected) {
        try {
          if (!currentSubsriber) {
            const sub = WalletConnect.eventSubscribe().subscribe(
              subscriberSuccess,
              subscriberError,
            );
            setCurrentSubsciber(sub as any);
          }

          const accountInfo: any = await WalletConnect.getAccount();

          if (accountInfo.address) {
            dispatch(updateUserState({ provider, address: accountInfo.address }));
          }
        } catch (error) {
          console.log(error);
          disconnect();
          // TODO: need to update library to handle error more clearly
          // wallet provider errors
          if (error.code === 4) {
            switch (error.type) {
              case 'MetaMask':
                if (!(window as any).ethereum) {
                  window.open(
                    `https://metamask.app.link/dapp/${
                      window.location.hostname + window.location.pathname
                    }/?utm_source=mm`,
                  );
                }
                break;
              case 'Onto':
                if (error.message.message === 'No extension') {
                  window.open('https://onto.app/en/download/');
                }

                if (error.message.subtitle === 'Chain error') {
                  setNotification({
                    type: 'error',
                    message: `Please set network: ${chain}`,
                  });
                }
                break;
              case 'WalletConnect':
                if (error.message.subtitle === 'Chain error') {
                  setNotification({
                    type: 'error',
                    message: error.message.text,
                  });
                }
                break;

              default:
                break;
            }
          }
        }
      }
    },
    [WalletConnect, address, dispatch, subscriberError, subscriberSuccess, currentSubsriber],
  );

  const web3WithoutMetamask = useCallback((chain: Chains) => {
    const [first] = Object.values(chains[chain].provider.WalletConnect.provider.rpc.rpc);
    return new Web3(first as web3Provider);
  }, []);

  useEffect(() => {
    // connect if user has been connected previously
    if (userWalletProvider && connect && userChain) {
      connect(userWalletProvider, userChain);
    }
  }, []);

  return (
    <Web3Context.Provider
      value={{
        connect,
        disconnect,
        walletService: WalletConnect,
        web3WithoutMetamask,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

export { WalletConnectContext, useWalletConnectorContext };
