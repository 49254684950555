import React, { useCallback, useEffect, VFC } from 'react';
import { useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';
import {
  Box, Button, FormControl, Grid, Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { Modal, ModalProps } from 'components/Modal';
import { getStatusIcon } from 'utils/getStatusIcon';
import { IStakes, Status, Chains } from 'types';
import {
  formattedDate,
  getLinkToScaner,
  transformIntervalFromSeconds,
  getCurrentTimestampInSeconds,
} from 'utils';
import userSelector from 'store/user/selectors';

import { stakeToNftDrop, unstakeFromNftDrop } from 'store/stakes/actions';
import { useWalletConnectorContext } from 'services';

import { ExternalLinkBox, ImageContainer } from 'components';
import { useShallowSelector } from 'hooks';
import { useBaseStyles } from '../BaseStakeModal';
import { RadioStates } from '../BaseStakeModal/BaseStakeModal.types';
import { useStakeUnstakeRadioGroup } from '../components';

export interface NftDropStakeModalProps extends ModalProps {
  selectedStakeData: IStakes;
  onClose: () => void;
  open: boolean;
  chain?: Chains;
}

export const NftDropStakeModal: VFC<NftDropStakeModalProps> = ({
  onClose,
  open,
  selectedStakeData,
  chain,
}) => {
  const {
    rewardTokenLogo,
    rewardTokenAddress,
    rewardTokenId,
    status,
    stakingTime,
    stakingToken0Symbol,
    stakingToken1Symbol,
    stakingToken0AmountToStake,
    stakingToken1AmountToStake,
    stakingToken0Address,
    stakingToken1Address,
    poolAddress,
    isUserDepositedToPool,
    id,
    endDate,
    entranceWindow,
    minimumStakingTime,
  } = selectedStakeData;
  const dispatch = useDispatch();

  const { walletService } = useWalletConnectorContext();

  const stakingToken0UserBalance = useShallowSelector(
    userSelector.getBalanceOfToken(stakingToken0Address),
  );
  const stakingToken1UserBalance = useShallowSelector(
    userSelector.getBalanceOfToken(stakingToken1Address),
  );

  const { StakeUnstakeRadioGroup, isStakeSelected, setRadioState } =
    useStakeUnstakeRadioGroup();

  const isEntranceWindowEnd =
    getCurrentTimestampInSeconds() > +entranceWindow;
  const isStakeEnd = getCurrentTimestampInSeconds() > +endDate - +minimumStakingTime;

  const isEnoughToken0ToStake = new BigNumber(
    stakingToken0UserBalance,
  ).isGreaterThanOrEqualTo(stakingToken0AmountToStake);
  const isEnoughToken1ToStake = new BigNumber(
    stakingToken1UserBalance,
  ).isGreaterThanOrEqualTo(stakingToken1AmountToStake);

  const isUnstakeAvailable = isUserDepositedToPool;

  const isUnstakeBtnAvailable = isUserDepositedToPool || isStakeEnd;
  const isStakeBtnAvailable =
    isEnoughToken0ToStake &&
    isEnoughToken1ToStake &&
    !isEntranceWindowEnd &&
    !isUserDepositedToPool && !isStakeEnd;

  const stakeOrUnstakeText = isStakeSelected
    ? 'Stake'
    : `Unstake ${isStakeEnd ? '+ Claim' : ''}`;

  const handleCloseModal = useCallback(() => {
    setRadioState(RadioStates.stake);
    onClose();
  }, [onClose, setRadioState]);

  const handleStakeClick = () => {
    dispatch(
      stakeToNftDrop({
        poolAddress,
        web3Provider: walletService.Web3(),
        stakingToken0Address,
        stakingToken1Address,
        stakingToken0AmountToStake,
        stakingToken1AmountToStake,
        id,
      }),
    );
  };

  const handleUnstakeClick = () => {
    dispatch(
      unstakeFromNftDrop({
        poolAddress,
        web3Provider: walletService.Web3(),
        id,
        stakingToken0Address,
        stakingToken1Address,
      }),
    );
  };

  useEffect(() => {
    if (selectedStakeData?.status?.label === Status.completed) {
      setRadioState(RadioStates.unstake);
    }
  }, [selectedStakeData?.status?.label, setRadioState]);

  useEffect(() => {
    if (!isUnstakeAvailable) {
      setRadioState(RadioStates.stake);
    }
  }, [isUnstakeAvailable, setRadioState]);

  const baseStakeModalClasses = useBaseStyles();
  // const classes = useStyles();

  return (
    <Modal open={open} onClose={handleCloseModal} customTitle="NFT Drop">
      <FormControl component="fieldset">
        {isUnstakeAvailable &&
          selectedStakeData.status.label !== Status.completed && (
            <StakeUnstakeRadioGroup />
        )}

        <Grid container className={baseStakeModalClasses.info}>
          {((!isStakeSelected && isStakeEnd) || isStakeSelected) && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="body1"
                className={clsx(baseStakeModalClasses.title, 'xs')}
              >
                {isStakeSelected ? 'Reward NFT' : 'Your NFT earned'}
              </Typography>
              <Box
                className={clsx(
                  baseStakeModalClasses.infoItemContent,
                  baseStakeModalClasses.imgBox,
                )}
              >
                <ImageContainer
                  src={rewardTokenLogo}
                  alt="NFT reward preview"
                />
              </Box>
              <ExternalLinkBox
                link={getLinkToScaner({
                  chain,
                  hash: rewardTokenAddress,
                  params: `?a=${rewardTokenId}`,
                  type: 'token',
                })}
                variant="light"
              />
            </Grid>
          )}
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              Status
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              {getStatusIcon(status.label)}
              <Typography className={baseStakeModalClasses.status}>
                {status.label}
              </Typography>
            </Box>
          </Grid>
          {isStakeSelected && (
            <>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Staking time
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {transformIntervalFromSeconds(stakingTime, 'Day')} days
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Your {stakingToken0Symbol} balance
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {stakingToken0UserBalance} {stakingToken0Symbol}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography
                  variant="body1"
                  className={clsx(baseStakeModalClasses.title, 'xs')}
                >
                  Your {stakingToken1Symbol} balance
                </Typography>
                <Box className={baseStakeModalClasses.infoItemContent}>
                  <Typography>
                    {stakingToken1UserBalance} {stakingToken1Symbol}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant="body1"
              className={clsx(baseStakeModalClasses.title, 'xs')}
            >
              {isStakeSelected ? 'Amount to stake' : 'You staked'}
            </Typography>
            <Box className={baseStakeModalClasses.infoItemContent}>
              <Typography>
                {`${stakingToken0AmountToStake} ${stakingToken0Symbol} + ${stakingToken1AmountToStake} ${stakingToken1Symbol}`}
              </Typography>
            </Box>
            {!isEntranceWindowEnd && (
              <Typography
                variant="body1"
                className={clsx(baseStakeModalClasses.entranceWindowTitle, 's')}
              >
                If you change your mind you can unstake your tokens before&nbsp;
                {formattedDate(new Date((+entranceWindow) * 1000))}
                . After that your tokens will be locked till&nbsp;
                {formattedDate(new Date(+endDate * 1000))}.
              </Typography>
            )}
            {isEntranceWindowEnd && (
              <Typography
                variant="body1"
                className={clsx(baseStakeModalClasses.entranceWindowTitle, 's')}
              >
                Entrance window has ended!
              </Typography>
            )}
          </Grid>
        </Grid>
        <Button
          className={baseStakeModalClasses.button}
          variant="outlined"
          color="secondary"
          onClick={isStakeSelected ? handleStakeClick : handleUnstakeClick}
          disabled={
            isStakeSelected ? !isStakeBtnAvailable : !isUnstakeBtnAvailable
          }
        >
          {stakeOrUnstakeText}
        </Button>
      </FormControl>
    </Modal>
  );
};
