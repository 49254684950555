import React, { useState, VFC } from 'react';

import clsx from 'clsx';

import { Box, Typography } from '@material-ui/core';
import { PlusIcon } from 'theme/icons';
import { useStyles } from './ImageUploader.styles';

export interface ImageUploaderProps {
  id: string;
  onChange: (file: File) => void;
  onBlur?: () => void;
  previewImg?: string;
  label?: string;
  size?: 'small' | 'large';
  error?: boolean;
  disabled?: boolean;
  className?: string;
}

export const ImageUploader: VFC<ImageUploaderProps> = ({
  className,
  id,
  size = 'small',
  previewImg = '',
  label = 'Upload image',
  error = false,
  disabled = false,
  onChange,
  onBlur,
}) => {
  const classes = useStyles();
  const [filePreviewUrl, setFilePreviewUrl] = useState(previewImg);

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.currentTarget.files[0];
    if (!file) return;
    onChange(file);
    const fileUrl = URL.createObjectURL(file);
    setFilePreviewUrl(fileUrl);
  };

  return (
    <label htmlFor={id} className={clsx(classes.root, className)}>
      <input
        className={classes.input}
        id={id}
        disabled={disabled}
        accept="image/*"
        type="file"
        onChange={(e) => handleUploadFile(e)}
        onBlur={onBlur}
      />
      <Box className={clsx(classes.preview, classes[size], { [classes.error]: error })}>
        {filePreviewUrl ? (
          <img className={classes.previewImg} src={filePreviewUrl} alt="uploaded" />
        ) : (
          <PlusIcon />
        )}
      </Box>
      <Typography className={clsx('s mintLight', classes.label)}>{label}</Typography>
    </label>
  );
};
