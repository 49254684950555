import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';

export const useStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);

  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    disclaimerModal: {
      padding: '100px',
    },
    icon: {
      ...flexHelper(),
      flexDirection: 'column',
    },
    bigIcon: {
      '& > svg': {
        [theme.breakpoints.down('xs')]: {
          width: 100,
          height: 100,
        },
      },
    },
    desc: {
      maxWidth: '80%',
      textAlign: 'center',
      fontSize: 24,
      lineHeight: '29px',
      padding: theme.spacing(4, 0, 2, 0),
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
    copyable: {
      width: '80%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    address: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    button: {
      padding: theme.spacing(2, 4),
      width: '80%',
      [formatMedia.BREAKPOINT_TABLET]: {
        width: '163px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  });
});
