/* eslint-disable max-len */
import {
  select, put, takeLatest, call, delay,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { contractsConfig, ContractsNames } from 'config';
import { DELAY_BEFORE_UPDATE, isMainnet } from 'config/constants';
import { getTokenAmount } from 'utils';
import actionTypes from 'store/stakes/actionTypes';
import userSelector from 'store/user/selectors';
import setNotification from 'utils/setNotification';
import { stake } from '../actions';
import { approveSaga } from './approveSaga';
import { getMctPoolsDataSaga } from './getMctPools';
import { updatePoolSaga } from './updatePool';

function* stakeSaga({
  type, payload: {
    amount,
    web3Provider,
    stakingContractAddress,
    stakingToken0Address,
    id,
  },
}: ReturnType<typeof stake>) {
  yield put(apiActions.request(type));
  const {
    abi: stakingAbi,
  } = contractsConfig.contracts[ContractsNames.staking][isMainnet ? 'mainnet' : 'testnet'];

  const myAddress = yield select(userSelector.getProp('address'));

  const amountWithDecimals = getTokenAmount(amount);
  try {
    yield call(approveSaga, {
      type: actionTypes.APPROVE,
      payload: {
        web3Provider,
        amount,
        spender: stakingContractAddress,
        approveTokenAddress: stakingToken0Address,
      },
    });

    const stakingContract = yield (new web3Provider.eth.Contract(stakingAbi, stakingContractAddress));

    yield call(stakingContract.methods.stake(amountWithDecimals).send, {
      from: myAddress,
    });

    yield call(updatePoolSaga, {
      type: actionTypes.UPDATE_POOl,
      payload: { id },
    });

    yield delay(DELAY_BEFORE_UPDATE);

    yield call(getMctPoolsDataSaga, {
      type: actionTypes.GET_MCT_POOLS,
      payload: {
        web3Provider,
      },
    });

    setNotification({ type: 'success', message: 'Successfully staked' });

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.STAKE, stakeSaga);
}
