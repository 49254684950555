import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

import { getFormatMedia } from 'theme/utils';
import { flexHelper } from 'utils';
import {
  COLOR_MINT_400, COLOR_MINT_600, COLOR_MINT_700,
} from 'theme/colors';
import { FontWeights } from 'theme/Typography';

export const useBaseStyles = makeStyles((theme: Theme) => {
  const formatMedia = getFormatMedia(theme);
  return createStyles({
    root: {
      [formatMedia.BREAKPOINT_TABLET]: {
      },
    },
    radioGroup: {
      borderBottom: `1px solid ${COLOR_MINT_600}`,
      paddingBottom: theme.spacing(1.5),
      marginBottom: theme.spacing(3.5),
    },
    radio: {
      '&:first-child': {
        marginRight: theme.spacing(7),
      },
    },
    info: {
      border: `1px solid ${COLOR_MINT_600}`,
      maxWidth: '420px',
      borderRadius: '13px',
      padding: theme.spacing(1, 2.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },
    subInfo: {
      border: `1px solid ${COLOR_MINT_600}`,
      borderRadius: '13px',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    subInfoTitle: {
      color: COLOR_MINT_400,
      fontSize: 12,
      lineHeight: '20px',
      paddingBottom: theme.spacing(1.2),
    },
    textField: {
      paddingBottom: theme.spacing(2),
    },
    textFieldToken: {
      marginRight: theme.spacing(1.5),
      zIndex: 1,
      fontWeight: FontWeights.Medium,
      '& :not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    textFieldTokenWithoutMax: {
      marginRight: theme.spacing(0),
    },
    textFieldTokenWithBg: {
      padding: theme.spacing(0.75, 1),
      background: COLOR_MINT_700,
      borderRadius: theme.spacing(1),
      letterSpacing: '0.1em',
    },
    textFieldsDivider: {
      textAlign: 'center',
      color: COLOR_MINT_600,
      lineHeight: '16px',
      fontSize: 16,
      marginTop: -1,
      paddingBottom: 2,
    },
    status: {
      textTransform: 'capitalize',
    },
    infoItemContent: {
      ...flexHelper('flex-start', 'flex-start'),
      '& > :first-child': {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    title: {
      color: COLOR_MINT_400,
      paddingBottom: theme.spacing(1),
    },
    button: {
      padding: theme.spacing(2, 4),
      marginTop: theme.spacing(2),
      width: '100%',
    },
    maxBtn: {
      padding: theme.spacing(1, 2),
      fontWeight: FontWeights.SemiBold,
      letterSpacing: '0.1em',
      borderRadius: theme.spacing(1),
    },
    imgBox: {
      paddingBottom: theme.spacing(1),
    },
    entranceWindowTitle: {
      marginTop: theme.spacing(1),
      opacity: 0.5,
    },
  });
});
