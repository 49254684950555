import React, {
  VFC, ImgHTMLAttributes,
} from 'react';

import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { ImageIcon } from 'theme/icons';

import { useStyles } from './ImageContainer.styles';

type Size = 'm' | 'l';

export type ImageContainerProps = {
  className?: string;
  size?: Size;
} & Pick<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'>;

export const ImageContainer: VFC<ImageContainerProps> = ({
  className, src, alt, size = 'm',
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className, size)}>
      {
        src ? <img className={clsx(classes.img, size)} src={src} alt={alt} /> : <ImageIcon />
      }

    </Box>
  );
};
