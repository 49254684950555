import {
  select, put, takeLatest, call,
} from 'typed-redux-saga';
import apiActions from 'store/api/actions';
import { mainPoolAbi } from 'config/abi';
import BigNumber from 'bignumber.js';
import { getMainPoolSecondTokenAmount } from '../actions';
import userSelector from '../../user/selectors';
import actionTypes from '../actionTypes';
import { updateStakes } from '../reducer';

export function* getMainPoolSecondTokenAmountSaga({
  type,
  payload: {
    web3Provider,
    stakingToken0AmountToStake,
    stakingToken0Decimals,
    stakingToken1Decimals,
    poolAddress,
  },
}: ReturnType<typeof getMainPoolSecondTokenAmount>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));

  const mainPoolContract = yield new web3Provider.eth.Contract(
    mainPoolAbi,
    poolAddress,
  );

  try {
    const userAmounts = yield call(
      mainPoolContract.methods.getUserAmounts(myAddress).call,
    );

    const firstTokenAmountWillBeWithDecimals = new BigNumber(
      stakingToken0AmountToStake,
    )
      .multipliedBy(new BigNumber(10).pow(stakingToken0Decimals))
      .plus(new BigNumber(userAmounts[0]));

    const secondTokenAmountShouldBeWithDecimals = yield call(
      mainPoolContract.methods.calculateSecondTokenAmount(
        firstTokenAmountWillBeWithDecimals.toFixed(),
      ).call,
    );

    const mainPoolSecondTokenAmount = new BigNumber(
      secondTokenAmountShouldBeWithDecimals,
    )
      .minus(new BigNumber(userAmounts[1]))
      .dividedBy(new BigNumber(10).pow(stakingToken1Decimals))
      .toString();

    yield put(updateStakes({ mainPoolSecondTokenAmount }));
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(
    actionTypes.GET_MAIN_POOL_SECOND_TOKEN_AMOUNT,
    getMainPoolSecondTokenAmountSaga,
  );
}
